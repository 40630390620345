import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { get, isEmpty, orderBy } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Heading,
  Menu,
  ResponsiveContext,
  Text,
} from "grommet";
import {
  Edit,
  FormAdd,
  Logout,
  More,
  Share,
  StatusCritical,
  Trash,
  UserAdd,
  View,
} from "grommet-icons";

import {
  ConfirmationModal,
  FeedbackContext,
  InvestmentGroupLogo,
  ManageMembers,
  MenuItemLabel,
  ShareSettings,
} from "../index";
import { getFirebaseHost } from "../../utils";
import {
  PageChangeContext,
  QueryStringContext,
  TRTThemeContext,
  UserContext,
} from "../../index";
import md5 from "md5";

export const DeleteInvestmentGroupConfirmation = ({
  investmentGroup,
  onCancel,
  onConfirm,
}) => {
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to remove ${investmentGroup.name}?`}
      actionLabel="Remove"
    >
      <Box direction="row" justify="start" align="center">
        <InvestmentGroupLogo investmentGroup={investmentGroup} size="60px" />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            This action cannot be reversed. All members of this group will be
            removed, including other administrators.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const LeaveInvestmentGroupConfirmation = ({
  investmentGroup,
  onCancel,
  onConfirm,
}) => {
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to leave ${investmentGroup.name}?`}
      actionLabel="Leave"
    >
      <Box direction="row" justify="start" align="center">
        <InvestmentGroupLogo investmentGroup={investmentGroup} size="60px" />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            This action cannot be reversed. If you are the only administrator in
            this group, the group will be removed.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const InvestmentGroupMembers = ({ members }) => {
  const size = useContext(ResponsiveContext);
  const { currentTheme } = useContext(TRTThemeContext);
  let borderColor = currentTheme === "dark" ? "app-header" : "neutral-3";
  if (size === "small") {
    borderColor = currentTheme === "dark" ? "dark-1" : "light-1";
  }
  const activeMembers = useMemo(
    () => members.filter(({ pending }) => !pending),
    [members]
  );
  const displayableMembers = useMemo(
    () =>
      orderBy(
        activeMembers.filter(({ role }) => role !== "viewer"),
        ["role"]
      ).slice(0, 3),
    [activeMembers]
  );
  return (
    <Box direction="row" align="center">
      {displayableMembers.map((member) => (
        <Box
          key={member.id}
          width="28px"
          height="28px"
          round="full"
          border={{
            side: "all",
            size: "2px",
            color: borderColor,
          }}
          margin={{ left: "-9px" }}
          style={{
            overflow: "hidden",
          }}
        >
          <Avatar
            src={`//s.gravatar.com/avatar/${md5(get(member, "id"))}?s=80`}
          />
        </Box>
      ))}
      {activeMembers.length - displayableMembers.length > 0 && (
        <Box
          round="full"
          border={{
            side: "all",
            size: "2px",
            color: borderColor,
          }}
          margin={{ left: "-9px" }}
          style={{
            overflow: "hidden",
          }}
          background="light-4"
          width="28px"
          height="28px"
          align="center"
          justify="center"
        >
          <Text color="neutral-3" size="xsmall" weight="bold">
            +{activeMembers.length - displayableMembers.length}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export const InvestmentGroup = ({ investmentGroup, border }) => {
  const size = useContext(ResponsiveContext);
  const { query, setQuery } = useContext(QueryStringContext);
  const onPageChange = useContext(PageChangeContext);
  const [
    showDeleteInvestmentGroupConfirmation,
    setShowDeleteInvestmentGroupConfirmation,
  ] = useState(false);
  const [
    showLeaveInvestmentGroupConfirmation,
    setShowLeaveInvestmentGroupConfirmation,
  ] = useState(false);
  const [showManageAccess, setShowManageAccess] = useState(false);
  const [showShareSettings, setShowShareSettings] = useState(false);
  const {
    user: { data: user },
    updateUser,
  } = useContext(UserContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const { currentTheme } = useContext(TRTThemeContext);
  const investmentGroupRef = useRef(null);

  const onRequestToDeleteInvestmentGroup = useCallback(
    () => setShowDeleteInvestmentGroupConfirmation(true),
    [setShowDeleteInvestmentGroupConfirmation]
  );

  const onRequestToLeaveInvestmentGroup = useCallback(
    () => setShowLeaveInvestmentGroupConfirmation(true),
    [setShowLeaveInvestmentGroupConfirmation]
  );

  const onConfirmDeleteInstitution = useCallback(async () => {
    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/investment-groups/${user.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ investmentGroupId: investmentGroup.id }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    setShowDeleteInvestmentGroupConfirmation(false);

    onPageChange("dashboard");

    sendFeedback({
      message: (
        <span>
          Successfully deleted <b>{investmentGroup.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [updateUser, investmentGroup, user, sendFeedback, onPageChange]);

  const onConfirmLeaveInstitution = useCallback(async () => {
    const members = get(investmentGroup, "members", []);
    const memberIndex = members.findIndex(({ id }) => id === user.id);
    members.splice(memberIndex, 1);

    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/investment-groups/${user.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          investmentGroup: {
            ...investmentGroup,
            members,
          },
        }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    setShowLeaveInvestmentGroupConfirmation(false);

    onPageChange("dashboard");

    sendFeedback({
      message: (
        <span>
          Successfully left <b>{investmentGroup.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [updateUser, investmentGroup, user, sendFeedback, onPageChange]);

  const onCancelDeleteInvestmentGroup = useCallback(
    () => setShowDeleteInvestmentGroupConfirmation(false),
    [setShowDeleteInvestmentGroupConfirmation]
  );

  const onCancelLeaveInvestmentGroup = useCallback(
    () => setShowLeaveInvestmentGroupConfirmation(false),
    [setShowLeaveInvestmentGroupConfirmation]
  );

  const onAccessGroup = useCallback(
    (e) => {
      e.preventDefault();
      setQuery({
        ...query,
        investmentGroupId: investmentGroup.id,
        activeInvestmentGroup: undefined,
      });
      onPageChange("investmentGroup");
    },
    [onPageChange, query, setQuery, investmentGroup]
  );

  const onShowManageMembers = useCallback((e) => {
    e.preventDefault();
    setShowManageAccess(true);
  }, []);

  const onShowShareSettings = useCallback((e) => {
    e.preventDefault();
    setShowShareSettings(true);
  }, []);

  const onHideManageMembers = useCallback(() => setShowManageAccess(false), []);
  const onHideShareSettings = useCallback(
    () => setShowShareSettings(false),
    []
  );

  const actions = useMemo(
    () => [
      {
        label: (
          <MenuItemLabel
            icon={<View color="menu-icon" size="small" />}
            label="Access Group"
          />
        ),
        onClick: onAccessGroup,
      },
      {
        label: (
          <MenuItemLabel
            icon={<UserAdd color="menu-icon" size="small" />}
            label="Manage Members"
          />
        ),
        onClick: onShowManageMembers,
      },
      {
        label: (
          <MenuItemLabel
            icon={<Edit color="menu-icon" size="small" />}
            label="Edit Group"
          />
        ),
        onClick: onAccessGroup,
      },

      {
        label: (
          <MenuItemLabel
            icon={<Share color="menu-icon" size="small" />}
            label="Share settings"
          />
        ),
        onClick: onShowShareSettings,
      },
      {
        label: (
          <MenuItemLabel
            icon={<Logout color="menu-icon" size="small" />}
            label="Leave Group"
          />
        ),
        onClick: onRequestToLeaveInvestmentGroup,
      },
      {
        label: (
          <MenuItemLabel
            icon={<Trash color="menu-icon" size="small" />}
            label="Remove Group"
          />
        ),
        onClick: onRequestToDeleteInvestmentGroup,
      },
    ],
    [
      onRequestToDeleteInvestmentGroup,
      onRequestToLeaveInvestmentGroup,
      onAccessGroup,
      onShowManageMembers,
      onShowShareSettings,
    ]
  );

  useEffect(() => {
    // give a little time for the user to read things before scrolling to the active institution
    setTimeout(() => {
      if (
        query.activeInvestmentGroup &&
        investmentGroup.id === query.activeInvestmentGroup &&
        investmentGroupRef.current
      ) {
        investmentGroupRef.current.scrollIntoView();
      }
    }, 300);
  }, [query.activeInstitution, investmentGroup, query, setQuery]);

  return (
    <Box
      flex={false}
      border={size === "small" ? "all" : "top"}
      pad={{ bottom: "small" }}
      ref={investmentGroupRef}
    >
      <Box
        direction="row"
        align="center"
        justify="between"
        border={border}
        pad={{
          left: "small",
          right: size === "small" ? "small" : undefined,
          vertical: size === "small" ? "xsmall" : undefined,
        }}
        responsive={false}
      >
        <Button onClick={onAccessGroup}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="9px"
            pad={{ top: "small" }}
          >
            <InvestmentGroupLogo investmentGroup={investmentGroup} />
            <Box align="start">
              <Text
                size={size === "large" ? "large" : undefined}
                truncate
                style={{ maxWidth: "144px" }}
                title={investmentGroup.name}
              >
                {investmentGroup.name}
              </Text>
            </Box>
          </Box>
        </Button>
        <Box
          width={size === "small" ? "36px" : undefined}
          align="center"
          justify="center"
        >
          <Menu
            icon={<More />}
            dropAlign={{ right: "right", top: "bottom" }}
            dropBackground={{ color: "drop", dark: currentTheme === "dark" }}
            items={actions}
          />
        </Box>
      </Box>
      <Heading
        level={6}
        margin={{ top: "xsmall", bottom: "none", horizontal: "small" }}
        responsive={false}
      >
        MEMBERS
      </Heading>
      <Box
        pad={{ horizontal: "medium", vertical: "xsmall" }}
        responsive={false}
      >
        <InvestmentGroupMembers members={investmentGroup.members} />
      </Box>
      {showDeleteInvestmentGroupConfirmation && (
        <DeleteInvestmentGroupConfirmation
          investmentGroup={investmentGroup}
          onCancel={onCancelDeleteInvestmentGroup}
          onConfirm={onConfirmDeleteInstitution}
        />
      )}
      {showLeaveInvestmentGroupConfirmation && (
        <LeaveInvestmentGroupConfirmation
          investmentGroup={investmentGroup}
          onCancel={onCancelLeaveInvestmentGroup}
          onConfirm={onConfirmLeaveInstitution}
        />
      )}
      {showManageAccess && (
        <ManageMembers
          investmentGroup={investmentGroup}
          onClose={onHideManageMembers}
        />
      )}
      {showShareSettings && (
        <ShareSettings
          investmentGroup={investmentGroup}
          onClose={onHideShareSettings}
        />
      )}
    </Box>
  );
};

export const InvestmentGroups = ({ investmentGroups }) => {
  const onPageChange = useContext(PageChangeContext);
  const onAddInvestmentGroupClick = useCallback(
    (e) => {
      e.preventDefault();
      onPageChange("addInvestmentGroup");
    },
    [onPageChange]
  );
  if (isEmpty(investmentGroups))
    return (
      <Box
        pad={{ vertical: "medium" }}
        align="center"
        justify="center"
        fill
        gap="small"
      >
        <Text>No investment group added</Text>
        <Button
          size="small"
          primary
          onClick={onAddInvestmentGroupClick}
          label="Add new group"
        />
      </Box>
    );

  return (
    <Box>
      <Box
        direction="row"
        align="center"
        justify="between"
        pad={{ vertical: "small", horizontal: "small" }}
        responsive={false}
        border="top"
      >
        <Text color="text-weak">
          {investmentGroups.length > 1
            ? `${investmentGroups.length} groups`
            : "1 group"}
        </Text>
        <Button onClick={onAddInvestmentGroupClick}>
          <Box direction="row" align="center">
            <FormAdd />
            <Text size="small" weight="bold">
              Add new group
            </Text>
          </Box>
        </Button>
      </Box>
      {investmentGroups.map((investmentGroup) => (
        <InvestmentGroup
          key={investmentGroup.id}
          investmentGroup={investmentGroup}
        />
      ))}
    </Box>
  );
};
