import React, { useCallback, useContext } from "react";

import { Box } from "grommet";

import { Header, PageHeader, SettingsBody } from "../components";
import { PageChangeContext } from "../";

export const Settings = () => {
  const onPageChange = useContext(PageChangeContext);
  const onPageClose = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);
  return (
    <Box direction="row" fill>
      <Header />
      <Box flex>
        <PageHeader
          title="Settings"
          subTitle="Change profile picture, password, upgrade plans"
          onClose={onPageClose}
        />
        <SettingsBody />
      </Box>
    </Box>
  );
};
