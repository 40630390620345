import React from "react";
import { Box, Button, Heading, Layer } from "grommet";
import { Close } from "grommet-icons";

import { PaymentChooser } from "./";

export const AddPayment = ({ onPayCancel, onPayConfirm }) => {
  return (
    <Layer>
      <Box direction="row" align="center" justify="between" pad="medium">
        <Heading level={3} margin="none" responsive={false}>
          Add Payment
        </Heading>
        <Button icon={<Close />} onClick={onPayCancel} />
      </Box>
      <Box pad="large">
        <PaymentChooser
          frequency={localStorage.getItem("trt-plan-frequency") || "monthly"}
          onPay={onPayConfirm}
        />
      </Box>
    </Layer>
  );
};
