import React, { useCallback, useContext, useEffect, useState } from "react";
import { Anchor, Box, Paragraph, ResponsiveContext, Text } from "grommet";
import { PlanCardButton } from "./";

export const Subscription = ({
  plan = "basic",
  onCancel,
  onChange,
  onChangePayment,
  renewsIn,
}) => {
  const size = useContext(ResponsiveContext);
  const [currentPlan, setCurrentPlan] = useState(plan);

  const hasPaidPlan =
    localStorage.getItem("trt-plan") &&
    localStorage.getItem("trt-plan") !== "basic";

  const hasPlan = localStorage.getItem("trt-plan");

  const onPlanSelect = useCallback(
    (newPlan) => () => {
      if (onChange) {
        onChange(newPlan);
      }
      setCurrentPlan(newPlan);
    },
    [onChange]
  );

  const onChangePaymentClick = (e) => {
    e.preventDefault();
    if (onChangePayment) {
      onChangePayment();
    }
  };

  const onCancelSubscriptionClick = (e) => {
    e.preventDefault();
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    setCurrentPlan(plan);
  }, [plan]);

  const align = size === "small" ? "center" : "start";

  return (
    <Box style={{ flex: "1 0 auto" }} align="center" pad="large" gap="large">
      <Box flex align="start" gap="medium">
        <Box direction="row-responsive" align="start">
          <PlanCardButton
            planPrice="Free"
            planePriceDescription="Forever"
            planType="Basic"
            planBackground="dark-3"
            planFeatures={[
              {
                title: (
                  <Text>
                    Access to <Anchor href="#">Restimate®</Anchor>
                  </Text>
                ),
                description: "Know how much you need to save",
              },
              {
                title: "Unlimited manual institutions",
                description: "You have to manually update each account balance",
              },
              {
                title: (
                  <span>
                    Only <b>one</b> automated institution
                  </span>
                ),
                description: "All account balances will automatically update",
              },
            ]}
            selected={currentPlan === "basic"}
            onSelect={onPlanSelect("basic")}
          />
          <PlanCardButton
            planPrice={
              <span>
                $7.99<Text>/month</Text>
              </span>
            }
            planePriceDescription="or $79.99 per year"
            planType="Pro"
            planBackground="status-ok"
            planFeatures={[
              {
                title: (
                  <Text>
                    Access to <Anchor href="#">Restimate®</Anchor>
                  </Text>
                ),
                description: "Know how much you need to save",
              },
              {
                title: "Unlimited manual institutions",
                description: "You have to manually update each account balance",
              },
              {
                title: (
                  <span>
                    Up to <b>15</b> automated institutions
                  </span>
                ),
                description: "All account balances will automatically update",
              },
            ]}
            selected={currentPlan === "pro"}
            onSelect={onPlanSelect("pro")}
            renewsIn={renewsIn}
          />
        </Box>
        <Box
          gap="medium"
          alignSelf={align}
          align={align}
          margin={{ left: size !== "small" ? "small" : undefined }}
        >
          {hasPaidPlan && (
            <Anchor
              href="#"
              label="Change payment"
              onClick={onChangePaymentClick}
            />
          )}
          {hasPlan && (
            <Anchor
              href="#"
              label="Cancel subscription"
              color="status-critical"
              onClick={onCancelSubscriptionClick}
            />
          )}
        </Box>
      </Box>
      <Box alignSelf="start">
        <Text color="text-xweak">
          <i>
            All paid subscriptions will automatically renew after the trial
            period ends.
          </i>
        </Text>
        <Paragraph color="text-xweak">
          <i>
            All paid subscriptions are currently offered through a promotional
            price. This price will be locked for one year as an incentive to
            early subscribers.
          </i>
        </Paragraph>
      </Box>
    </Box>
  );
};
