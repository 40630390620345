import React, { useContext } from "react";
import { Box, ResponsiveContext } from "grommet";

export const CircleIconHeading = ({ icon, color, ...rest }) => {
  const size = useContext(ResponsiveContext);
  const Icon = icon;
  let boxSize = "150px";
  let iconSize = "xlarge";
  if (size === "small") {
    boxSize = "80px";
    iconSize = "large";
  } else if (size === "medium") {
    boxSize = "120px";
    iconSize = "72px";
  }

  return (
    <Box
      round="full"
      elevation="small"
      width={boxSize}
      height={boxSize}
      align="center"
      justify="center"
      background="white"
      {...rest}
    >
      <Icon size={iconSize} color={color} />
    </Box>
  );
};
