import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { render } from "react-dom";
import { get } from "lodash";
import useQueryString from "use-query-string";

import { Box, Grommet, ResponsiveContext } from "grommet";
import {
  AddAssets,
  Dashboard,
  Website,
  Settings,
  SignIn,
  Landing,
  EditInstitution,
  AddInstitution,
  SettingsMobile,
  NotificationsMobile,
  UserMenuMobile,
  InstitutionsMobile,
  NetWorthMobile,
  EditInstitutionMobile,
  AddAssetsMobile,
  AddInstitutionMobile,
  DashboardMobile,
  SignUp,
  Onboarding,
  ResetPassword,
  InvestmentGroupsMobile,
  AddInvestmentGroup,
  AddInvestmentGroupMobile,
  InvestmentGroup,
  InvestmentGroupMobile,
} from "./pages";
import { trtTheme } from "./themes/trt";
import { useUser } from "./hooks";
import { Feedback, NotFound, PaymentFailure } from "./components";

const PAGES_MAP = {
  onboarding: Onboarding,
  addAssets: AddAssets,
  addInstitution: AddInstitution,
  addInvestmentGroup: AddInvestmentGroup,
  dashboard: Dashboard,
  editInstitution: EditInstitution,
  website: Website,
  settings: Settings,
  signIn: SignIn,
  landing: Landing,
  signUp: SignUp,
  resetPassword: ResetPassword,
  investmentGroup: InvestmentGroup,
};

const MOBILE_PAGES_MAP = {
  website: Website,
  signIn: SignIn,
  landing: Landing,
  onboarding: Onboarding,
  notifications: NotificationsMobile,
  settings: SettingsMobile,
  userMenu: UserMenuMobile,
  institutions: InstitutionsMobile,
  netWorth: NetWorthMobile,
  editInstitution: EditInstitutionMobile,
  addInstitution: AddInstitutionMobile,
  addInvestmentGroup: AddInvestmentGroupMobile,
  addAssets: AddAssetsMobile,
  dashboard: DashboardMobile,
  investmentGroups: InvestmentGroupsMobile,
  signUp: SignUp,
  resetPassword: ResetPassword,
  investmentGroup: InvestmentGroupMobile,
};

export const UserContext = createContext({
  user: {
    data: undefined,
  },
});

export const QueryStringContext = createContext({});

export const PageChangeContext = createContext(() => {});

export const TRTThemeContext = createContext(() => {});

const TRTAppBody = ({ currentPage }) => {
  const paymentFailure = JSON.parse(
    localStorage.getItem("trt-payment-failure")
  );
  const [showPaymentFailure, setShowPaymentFailure] = useState(
    currentPage === "dashboard" && paymentFailure
  );
  const user = useUser();

  const size = useContext(ResponsiveContext);

  useEffect(
    () => setShowPaymentFailure(currentPage === "dashboard" && paymentFailure),
    [currentPage, paymentFailure]
  );

  const onFixPayment = useCallback(() => {
    localStorage.setItem("trt-payment-failure", "false");
    setShowPaymentFailure(false);
  }, []);

  if (!get(user, "user.data.id")) {
    return null;
  }

  let CurrentPage =
    size === "small"
      ? MOBILE_PAGES_MAP[currentPage] || PAGES_MAP[currentPage]
      : PAGES_MAP[currentPage];

  if (!CurrentPage) {
    CurrentPage =
      size === "small" ? MOBILE_PAGES_MAP.website : PAGES_MAP.website;
  }

  if (window.location.pathname === "/not-found") {
    return <NotFound />;
  }

  return (
    <UserContext.Provider value={user}>
      <Feedback>
        {showPaymentFailure && <PaymentFailure onFix={onFixPayment} />}
        <CurrentPage />
      </Feedback>
    </UserContext.Provider>
  );
};

const TRTApp = () => {
  const [currentPage, setCurrentPage] = useState("dashboard");
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("trt-theme") || "light"
  );

  const [query, setQuery] = useQueryString(
    window.location,
    (path) => window.history.pushState(null, document.title, path),
    {
      parseNumbers: true,
      parseBooleans: true,
    }
  );

  const onPageChange = useCallback((nextPage) => setCurrentPage(nextPage), [
    setCurrentPage,
  ]);

  const onThemeChange = useCallback((theme) => setCurrentTheme(theme), [
    setCurrentTheme,
  ]);

  let appBackground = currentTheme === "dark" ? "dark-1" : "light-1";

  if (currentPage === "website") {
    if (currentTheme === "dark") {
      setCurrentTheme("light");
    }
    appBackground = "light-1";
  }
  return (
    <Box fill background={appBackground}>
      <QueryStringContext.Provider value={{ query, setQuery }}>
        <PageChangeContext.Provider value={onPageChange}>
          <TRTThemeContext.Provider value={{ currentTheme, onThemeChange }}>
            <TRTAppBody currentPage={currentPage} />
          </TRTThemeContext.Provider>
        </PageChangeContext.Provider>
      </QueryStringContext.Provider>
    </Box>
  );
};

const App = () => (
  <Grommet theme={trtTheme} full>
    <TRTApp />
  </Grommet>
);

function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", appHeight);
appHeight();

render(<App />, document.getElementById("root"), () => {
  document.querySelector("body").removeAttribute("class");
});
