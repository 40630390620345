import React, { useContext } from "react";

import { Box, RangeInput, ResponsiveContext, Text, TextInput } from "grommet";

export const RetirementEstimator = () => {
  const size = useContext(ResponsiveContext);
  return (
    <table>
      <tr>
        <td align="right">
          <Text
            weight="bold"
            style={{ "white-space": "nowrap" }}
            margin="none"
            size={size}
          >
            Retire in
          </Text>
        </td>
        <td>
          <Box width="small" margin={{ horizontal: "small" }}>
            <RangeInput min="5" max="30" margin="none" />
          </Box>
        </td>
        <td>
          <Box width="100px" pad={{ top: "small" }}>
            <TextInput value="10" type="number" />
            <Text>years</Text>
          </Box>
        </td>
      </tr>
      <tr>
        <td align="right">
          <Text
            weight="bold"
            style={{ "white-space": "nowrap" }}
            margin="none"
            size={size}
          >
            Retire With
          </Text>
        </td>
        <td>
          <Box width="small" margin={{ horizontal: "small" }}>
            <RangeInput min="5" max="30" margin="none" />
          </Box>
        </td>
        <td>
          <Box width="100px" pad={{ top: "small" }}>
            <TextInput value="15000" type="number" />
            <Text>per month</Text>
          </Box>
        </td>
      </tr>
      <tr>
        <td align="right">
          <Text
            weight="bold"
            style={{ "white-space": "nowrap" }}
            margin="none"
            size={size}
          >
            Saved
          </Text>
        </td>
        <td>
          <Box width="small" margin={{ horizontal: "small" }}>
            <RangeInput min="5" max="30" margin="none" color="neutral-3" />
          </Box>
        </td>
        <td>
          <Box width="100px" pad={{ top: "small" }}>
            <TextInput value="60000" type="number" />
            <Text>so far</Text>
          </Box>
        </td>
      </tr>
    </table>
  );
};
