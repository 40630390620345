import React from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile, SettingsBody } from "../../components";

export const SettingsMobile = () => {
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Settings
          </Text>
        }
      />
      <Box flex overflow="auto">
        <SettingsBody />
      </Box>
      <FooterMobile />
    </Box>
  );
};
