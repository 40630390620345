import React, { useContext } from "react";
import { Anchor, Box, ResponsiveContext, Text } from "grommet";

import { PageChangeContext, UserContext } from "../../index";

import { LogoFull, UserAvatar } from "../index";

export const OnboardingHeader = () => {
  const size = useContext(ResponsiveContext);
  const onPageChange = useContext(PageChangeContext);
  const { user } = useContext(UserContext);
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: "medium", vertical: "18px" }}
      border="bottom"
    >
      <LogoFull color="logo" size={size === "small" ? "small" : undefined} />
      <Box direction="row" align="center" justify="center" gap="small">
        <Text>Not You?</Text>
        <Anchor
          href="#"
          onClick={() => onPageChange("signIn")}
          label="Sign out"
        />
        <UserAvatar user={user} showName={false} />
      </Box>
    </Box>
  );
};
