import React, { useCallback, useContext } from "react";

import {
  Anchor,
  Box,
  Button,
  ResponsiveContext,
  Text,
  TextInput,
} from "grommet";

import { LogoFull } from "../components";
import { PageChangeContext } from "../";
import { Google } from "grommet-icons";

export const SignUp = () => {
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const onSignInClick = useCallback(
    (event) => {
      event.preventDefault();
      onPageChange("signIn");
    },
    [onPageChange]
  );
  const loginCard = (
    <Box align="center" gap="large">
      <Button onClick={() => onPageChange("website")}>
        <LogoFull color="plain" size="large" />
      </Button>
      <Box gap="small" width="medium">
        <TextInput size="large" placeholder="email" />
        <TextInput size="large" placeholder="password" />
        <Box direction="row" align="center" justify="center" gap="small">
          <Button
            size={size === "small" ? "small" : undefined}
            primary
            label="Sign up"
            onClick={() => onPageChange("onboarding")}
          />
          <Text color="text-xweak">or</Text>
          <Button
            icon={
              <Google
                color="plain"
                size={size === "small" ? "small" : undefined}
              />
            }
            secondary
            label="Sign up with Google"
            size="small"
          />
        </Box>
        <Text size="small" textAlign="center">
          By continuing to sign up you accept our{" "}
          <Anchor href="#">Terms of Service</Anchor> and{" "}
          <Anchor href="#">Privacy Policy</Anchor>.
        </Text>
        <Box margin={{ top: "medium" }} align="center">
          <Text>
            Already have an account?{" "}
            <Anchor href="#" onClick={onSignInClick} label="Sign in" />
          </Text>
        </Box>
      </Box>
    </Box>
  );
  return size === "small" ? (
    <Box fill align="center" justify="center">
      {loginCard}
    </Box>
  ) : (
    <Box
      fill
      align="center"
      justify="center"
      style={{
        background:
          "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(255,255,255,0.1)), url(/lake.jpg) no-repeat center center",
        backgroundSize: "cover",
      }}
    >
      <Box
        round="small"
        pad="large"
        elevation="large"
        align="center"
        background={{ color: "white", opacity: "strong" }}
      >
        {loginCard}
      </Box>
    </Box>
  );
};
