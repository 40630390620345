import React, { useCallback, useContext } from "react";

import { Box, Button, ResponsiveContext, TextInput } from "grommet";

import { FeedbackContext, LogoFull } from "../components";
import { PageChangeContext } from "../";

export const ResetPassword = () => {
  const { sendFeedback } = useContext(FeedbackContext);
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const onResetClick = useCallback(
    (event) => {
      event.preventDefault();
      onPageChange("signIn");
      sendFeedback({
        message: "Password has been successfully reset",
        type: "success",
      });
    },
    [onPageChange, sendFeedback]
  );
  const resetPasswordCard = (
    <Box align="center" gap="large">
      <Button onClick={() => onPageChange("website")}>
        <LogoFull color="plain" size="large" />
      </Button>
      <Box gap="medium" width="medium">
        <TextInput size="large" placeholder="new password" />
        <Box direction="row" align="center" justify="center" gap="small">
          <Button
            size={size === "small" ? "small" : undefined}
            primary
            label="Reset"
            onClick={onResetClick}
          />
        </Box>
      </Box>
    </Box>
  );
  return size === "small" ? (
    <Box fill align="center" justify="center">
      {resetPasswordCard}
    </Box>
  ) : (
    <Box
      fill
      align="center"
      justify="center"
      style={{
        background:
          "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(255,255,255,0.1)), url(/lake.jpg) no-repeat center center",
        backgroundSize: "cover",
      }}
    >
      <Box
        round="small"
        pad="large"
        elevation="large"
        align="center"
        background={{ color: "white", opacity: "strong" }}
      >
        {resetPasswordCard}
      </Box>
    </Box>
  );
};
