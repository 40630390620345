import React, { useContext } from "react";
import { Box, Heading, Text } from "grommet";
import { get } from "lodash";

import {
  FooterMobile,
  HeaderMobile,
  HeaderOverview,
  NetWorthDetails,
} from "../../components";

import { UserContext } from "../../";

export const NetWorthMobile = () => {
  const { user } = useContext(UserContext);
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Net Worth
          </Text>
        }
      />
      <Box flex overflow="auto">
        <HeaderOverview />
        <Heading level="4" margin="medium">
          Net Worth Details
        </Heading>
        <Box>
          <NetWorthDetails overview={get(user, "data.overview")} />
        </Box>
      </Box>
      <FooterMobile />
    </Box>
  );
};
