import React, { useCallback, useContext, useState } from "react";
import { Box, Button, Heading, ResponsiveContext, Text } from "grommet";

import { CallToAction, RestimateFullPlan, RetirementEstimator } from "./";

export const Restimate = () => {
  const [showFullPlan, setShowFullPlan] = useState(false);
  const size = useContext(ResponsiveContext);
  const fontSize = size === "small" ? "small" : undefined;
  const onCloseFullPlan = useCallback(() => setShowFullPlan(false), []);
  const onShowFullPlan = useCallback(() => setShowFullPlan(true), []);
  return (
    <Box align="center" gap="large">
      {showFullPlan && <RestimateFullPlan onClose={onCloseFullPlan} />}
      <RetirementEstimator />
      <Box margin={{ top: "small" }} direction="row" align="center">
        <Box pad={{ right: "medium" }}>
          <Heading
            textAlign="center"
            color="status-ok"
            level={size === "large" ? 1 : 2}
            margin="none"
          >
            $3M
          </Heading>
          <Text
            size={fontSize}
            color="text-weak"
            textAlign="center"
            margin={{ top: "xxsmall" }}
          >
            Total <b>Net Worth</b> in 10 years
          </Text>
        </Box>
        <Box border={{ side: "left" }} pad={{ horizontal: "medium" }}>
          <Heading
            textAlign="center"
            color="status-ok"
            level={size === "large" ? 1 : 2}
            margin="none"
          >
            $216k
          </Heading>
          <Text
            size={fontSize}
            color="text-weak"
            textAlign="center"
            margin={{ top: "xxsmall" }}
          >
            This <b>year</b> Net Worth Increase
          </Text>
        </Box>
        <Box border={{ side: "left" }} pad={{ left: "medium" }}>
          <Heading
            textAlign="center"
            color="status-ok"
            level={size === "large" ? 1 : 2}
            margin="none"
          >
            $4.5k
          </Heading>
          <Text
            size={fontSize}
            color="text-weak"
            textAlign="center"
            margin={{ top: "xxsmall" }}
          >
            This <b>week</b> Net Worth Increase
          </Text>
        </Box>
      </Box>
      <Button
        secondary
        label="See Full Plan"
        color="neutral-3"
        margin={{ vertical: "small" }}
        onClick={onShowFullPlan}
      />
      <CallToAction
        href="https://docs.google.com/document/d/1TdbTapVA7sbVjh7su9cgCGWKMWYthMr_qPwgWtti0no/edit#"
        text="Want learn more about Restimate®?"
        linkText="Read this post"
        target="_blank"
      />
    </Box>
  );
};
