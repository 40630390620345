import React, { useContext } from "react";
import { Box, Button, Heading, ResponsiveContext, Text } from "grommet";
import { Close } from "grommet-icons";

export const PageHeader = ({ title, subTitle, onClose }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: "medium" }}
      border="bottom"
      height="80px"
    >
      <Box gap="xsmall">
        <Heading
          level={3}
          size={size !== "large" ? "small" : undefined}
          margin="none"
        >
          {title}
        </Heading>
        <Text size={size !== "large" ? "small" : "medium"} color="text-xweak">
          {subTitle}
        </Text>
      </Box>
      <Button icon={<Close />} onClick={onClose} />
    </Box>
  );
};
