import React, { useCallback, useContext, useState } from "react";
import { Box, Tab, Tabs, Text } from "grommet";
import { get } from "lodash";

import { PageChangeContext, UserContext } from "../";

import {
  AddPayment,
  CancelSubscription,
  DowngradeSubscription,
  FeedbackContext,
  PersonalInfo,
  Restimate,
  Subscription,
  ChangePassword,
} from "./";

export const SettingsBody = () => {
  const { user } = useContext(UserContext);
  const onPageChange = useContext(PageChangeContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const currentPlan = localStorage.getItem("trt-plan");
  const [localPlan, setLocalPlan] = useState(currentPlan);
  const [showChoosePayment, setShowChoosePayment] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [showDowngradeSubscription, setShowDowngradeSubscription] = useState(
    false
  );

  const onShowChoosePayment = useCallback(() => setShowChoosePayment(true), []);

  const onSaveChanges = useCallback(
    (newPlan) => {
      if (newPlan !== currentPlan) {
        if (newPlan === "pro") {
          setShowChoosePayment(true);
        } else {
          const automatedInstitutions = get(
            user,
            "data.institutions",
            []
          ).filter(({ type }) => type === "automated");
          if (automatedInstitutions.length <= 1) {
            localStorage.setItem("trt-plan", "basic");
            localStorage.removeItem("trt-plan-frequency");
            sendFeedback({
              message: "Successfully changed plans",
              type: "success",
            });
          } else {
            setShowDowngradeSubscription(true);
          }
        }
      }
      setLocalPlan(newPlan);
    },
    [currentPlan, sendFeedback, user]
  );
  const onPayCancel = useCallback(() => {
    setShowChoosePayment(false);
    setShowDowngradeSubscription(false);
    setLocalPlan(localStorage.getItem("trt-plan"));
  }, []);
  const onCancelSubscriptionCancel = useCallback(() => {
    setShowCancelSubscription(false);
  }, []);
  const onPayConfirm = useCallback(
    (frequency) => {
      setShowChoosePayment(false);
      setShowDowngradeSubscription(false);
      if (frequency) {
        localStorage.setItem("trt-plan-frequency", frequency);
      } else {
        localStorage.removeItem("trt-plan-frequency");
      }
      sendFeedback({
        message: "Successfully changed plans",
        type: "success",
      });
      localStorage.setItem("trt-plan", localPlan);
    },
    [sendFeedback, localPlan]
  );
  const onCancelSubscription = useCallback(
    () => setShowCancelSubscription(true),
    []
  );
  const onCancelSubscriptionConfirm = useCallback(
    () => onPageChange("signUp"),
    [onPageChange]
  );

  let renewsIn;
  if (localPlan === "pro") {
    if (localStorage.getItem("trt-plan-frequency") === "monthly") {
      renewsIn = 30;
    } else {
      renewsIn = 365;
    }
  }

  return (
    <>
      {showChoosePayment && (
        <AddPayment onPayCancel={onPayCancel} onPayConfirm={onPayConfirm} />
      )}
      {showDowngradeSubscription && (
        <DowngradeSubscription
          onCancel={onPayCancel}
          onConfirm={onPayConfirm}
        />
      )}
      {showCancelSubscription && (
        <CancelSubscription
          onCancel={onCancelSubscriptionCancel}
          onConfirm={onCancelSubscriptionConfirm}
        />
      )}
      <Box margin={{ top: "large" }} flex overflow="auto">
        <Tabs flex overflow="auto">
          <Tab title="Personal Info">
            <Box pad="large">
              <PersonalInfo />
            </Box>
          </Tab>
          <Tab title="Password">
            <Box pad="large">
              <ChangePassword />
            </Box>
          </Tab>
          <Tab title="Restimate®">
            <Box align="center" pad="large" gap="large">
              <Text textAlign="center" color="text-weak">
                Set up your retirement goals and know how much you need to save
                this week, month, and years to come
              </Text>
              <Restimate />
            </Box>
          </Tab>
          <Tab title="Subscription">
            <Box fill>
              <Subscription
                plan={localPlan}
                onChange={onSaveChanges}
                renewsIn={renewsIn}
                onChangePayment={onShowChoosePayment}
                onCancel={onCancelSubscription}
              />
            </Box>
          </Tab>
        </Tabs>
      </Box>
    </>
  );
};
