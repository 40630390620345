import React, { useContext } from "react";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Layer,
  Paragraph,
  ResponsiveContext,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Tabs,
  Text,
} from "grommet";
import { Close } from "grommet-icons";

export const RestimateFullPlan = ({ onClose }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <Box fill style={{ height: size !== "small" ? "70vh" : undefined }}>
        <Box direction="row" align="center" justify="between" pad="medium">
          <Heading level={3} margin="none" responsive={false}>
            Restimate® Plan
          </Heading>
          <Box direction="row" align="center" gap="small">
            <Anchor size="small" href="#" label="Learn more" />
            <Button icon={<Close />} onClick={onClose} />
          </Box>
        </Box>
        <Box flex width="large" pad="medium" overflow="auto">
          <Tabs>
            <Tab title="Weekly">
              <Box
                flex={false}
                margin={{ vertical: "medium" }}
                direction={size === "small" ? "column" : "row"}
                gap="small"
                align="center"
                justify="center"
              >
                <Text color="text-weak">
                  <b>0.19%</b> Weekly Return Rate
                </Text>
                <Text color="text-weak">
                  <b>10.13%</b> Annualized Return Rate
                </Text>
              </Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Goal/Actual</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">1</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-xweak">$1,779.60</Text>
                        <Text size="small" color="status-ok" weight="bold">
                          $2,493.00
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-xweak">$1,779.60</Text>
                        <Text
                          size="small"
                          color="status-critical"
                          weight="bold"
                        >
                          -$493.00
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Tab>
            <Tab title="Monthly">
              <Box
                margin={{ vertical: "medium" }}
                direction={size === "small" ? "column" : "row"}
                gap="small"
                align="center"
                justify="center"
                flex={false}
              >
                <Text color="text-weak">
                  <b>0.88%</b> Monthly Return Rate
                </Text>
                <Text color="text-weak">
                  <b>10.13%</b> Annualized Return Rate
                </Text>
              </Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Goal/Actual</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">1</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Tab>
            <Tab title="Yearly">
              <Box
                flex={false}
                margin={{ vertical: "medium" }}
                direction={size === "small" ? "column" : "row"}
                align="center"
                gap="small"
                justify="center"
              >
                <Text color="text-weak">
                  <b>10.13%</b> Annualized Return Rate
                </Text>
              </Box>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Goal/Actual</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">1</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Text weight="bold">2</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$400,000,00</Text>
                    </TableCell>
                    <TableCell>
                      <Text color="text-weak">$401,779.60</Text>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Text color="text-weak">$1,779.60</Text>
                        <Text color="text-weak" size="small">
                          -
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Tab>
          </Tabs>
        </Box>
        <Paragraph
          color="text-xweak"
          margin={{ horizontal: "medium", bottom: "large" }}
        >
          <i>
            The numbers represent high level goals and are not to be followed to
            the exact amount. These numbers can be used as a north star during
            your retirement progress.
          </i>
        </Paragraph>
      </Box>
    </Layer>
  );
};
