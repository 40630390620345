import React, { useContext } from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile, Institutions } from "../../components";
import { get } from "lodash";
import { UserContext } from "../../";

export const InstitutionsMobile = () => {
  const { user } = useContext(UserContext);
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Institutions
          </Text>
        }
      />
      <Box flex overflow="auto">
        <Institutions institutions={get(user, "data.institutions")} />
      </Box>
      <FooterMobile />
    </Box>
  );
};
