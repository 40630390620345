import React from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile } from "../../components";
import { EditInstitutionBody } from "../edit-institution";

export const EditInstitutionMobile = () => {
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Institution
          </Text>
        }
      />
      <Box flex>
        <EditInstitutionBody />
      </Box>
      <FooterMobile />
    </Box>
  );
};
