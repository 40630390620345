import React, { useCallback, useContext } from "react";
import { Box } from "grommet";
import { HeaderButton } from "./header-button";
import { AppsRounded, Home, Organization, Group } from "grommet-icons";
import { UserAvatar } from "../";
import { PageChangeContext, UserContext } from "../../";

export const FooterMobile = () => {
  const { user } = useContext(UserContext);
  const onPageChange = useContext(PageChangeContext);
  const onInvestmentGroupClick = useCallback(
    () => onPageChange("investmentGroups"),
    [onPageChange]
  );
  const onUserAvatarClick = useCallback(() => onPageChange("userMenu"), [
    onPageChange,
  ]);
  const onHomeClick = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);
  const onNetWorthClick = useCallback(() => onPageChange("netWorth"), [
    onPageChange,
  ]);
  const onInstitutionsClick = useCallback(() => onPageChange("institutions"), [
    onPageChange,
  ]);
  return (
    <Box
      background="app-header"
      pad="large"
      elevation="footer"
      direction="row"
      justify="between"
      style={{ position: "relative" }}
    >
      <HeaderButton
        label="Net Worth"
        icon={<AppsRounded />}
        onClick={onNetWorthClick}
      />
      <HeaderButton
        label="Institutions"
        icon={<Organization />}
        onClick={onInstitutionsClick}
      />
      <HeaderButton label="Dashboard" icon={<Home />} onClick={onHomeClick} />
      <HeaderButton
        label="Investment Groups"
        icon={<Group />}
        onClick={onInvestmentGroupClick}
      />
      <UserAvatar user={user} onClick={onUserAvatarClick} />
    </Box>
  );
};
