import React from "react";
import { Box, Text } from "grommet";

import { InstitutionLogo } from "./";

export const InstitutionOption = ({ institution, state = {} }) => (
  <Box
    direction="row"
    align="center"
    pad="small"
    gap="small"
    background={!state.active && state.selected ? "tab-active" : undefined}
  >
    <InstitutionLogo institution={institution} />
    <Box>
      <Text truncate style={{ maxWidth: "280px" }} title={institution.name}>
        {institution.name}
      </Text>
    </Box>
  </Box>
);
