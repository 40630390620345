import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Paragraph, RadioButton, Text, TextInput } from "grommet";

export const PaymentChooser = ({
  frequency = "monthly",
  onPay,
  showDisclaimers = true,
}) => {
  const [planFrequency, setPlanFrequency] = useState(frequency);
  const onSelectPlan = useCallback(
    (newPlan) => () => setPlanFrequency(newPlan),
    []
  );

  useEffect(() => setPlanFrequency(frequency), [frequency]);
  return (
    <Box fill align="center" gap="large">
      <Box flex width="420px" gap="medium" responsive={false}>
        <Box gap="small" align="center" responsive={false}>
          <RadioButton
            onChange={onSelectPlan("monthly")}
            checked={planFrequency === "monthly"}
            name="plan"
            label={
              <Box direction="row" align="center" gap="xsmall">
                <Text size="large">
                  <b>$7.99</b>
                </Text>
                <Text color="text-weak">per month</Text>
              </Box>
            }
          />
          <RadioButton
            onChange={onSelectPlan("yearly")}
            checked={planFrequency === "yearly"}
            name="plan"
            label={
              <Box direction="row" align="center" gap="xsmall">
                <Text size="large">
                  <b>$79.99</b>
                </Text>
                <Text color="text-weak">per year (save $15.89)</Text>
              </Box>
            }
          />
        </Box>
        <Box>
          <TextInput placeholder="Stripe payment form goes here" />
          <Box direction="row" align="center" justify="end">
            <Text size="small" color="text-xweak">
              powered by
            </Text>
            <img alt="strip logo" src="/stripe.svg" width="48px" />
          </Box>
        </Box>
        <Box gap="small">
          <Button
            label="Pay now"
            primary
            onClick={() => onPay(planFrequency)}
          />
          {showDisclaimers && (
            <Box align="center" justify="end">
              <Text textAlign="center" color="text-xweak">
                You won't be charged today, you will have until Jan 29th, 2021
                to cancel your subscription without any costs.
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      {showDisclaimers && (
        <Paragraph alignSelf="start" color="text-xweak">
          <i>
            After the trial period, all subscriptions are non-refundable and
            will automatically renew. If you decide to cancel your subscription
            future charges won't apply and you will have access until your
            membership expires.
          </i>
        </Paragraph>
      )}
    </Box>
  );
};
