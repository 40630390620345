import React, { useCallback, useContext, useState } from "react";

import {
  Anchor,
  Box,
  Button,
  ResponsiveContext,
  Text,
  TextInput,
} from "grommet";

import { FeedbackContext, LogoFull } from "../components";
import { PageChangeContext } from "../";
import { Google } from "grommet-icons";

let fail = true;

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const { sendFeedback } = useContext(FeedbackContext);
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const onSignUpClick = useCallback(
    (event) => {
      event.preventDefault();
      onPageChange("signUp");
    },
    [onPageChange]
  );
  const onResetPasswordClick = useCallback(
    (event) => {
      event.preventDefault();
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        sendFeedback({
          message: "Provide a valid email to reset password",
          type: "error",
        });
      } else if (fail) {
        fail = false;
        sendFeedback({
          message: "User signed up with Google",
          type: "error",
        });
      } else {
        sendFeedback({
          message: "Check your email for the reset link",
          type: "success",
        });
        onPageChange("resetPassword");
      }
    },
    [email, sendFeedback, onPageChange]
  );
  const onEmailChange = useCallback(
    ({ target: { value } }) => setEmail(value),
    []
  );
  const loginCard = (
    <Box align="center" gap="large">
      <Button onClick={() => onPageChange("website")}>
        <LogoFull color="plain" size="large" />
      </Button>
      <Box gap="small" width="medium">
        <TextInput
          size="large"
          placeholder="email"
          value={email}
          onChange={onEmailChange}
        />
        <TextInput size="large" placeholder="password" />
        <Box direction="row" align="center" justify="center" gap="small">
          <Button
            size={size === "small" ? "small" : undefined}
            primary
            label="Sign in"
            onClick={() => onPageChange("dashboard")}
          />
          <Text color="text-xweak">or</Text>
          <Button
            icon={
              <Google
                color="plain"
                size={size === "small" ? "small" : undefined}
              />
            }
            secondary
            label="Sign in with Google"
            size="small"
          />
        </Box>
        <Box margin={{ top: "medium" }} align="center">
          <Text>
            Don't have an account?{" "}
            <Anchor href="#" onClick={onSignUpClick} label="Sign up" />
          </Text>
        </Box>
        <Box align="center">
          <Text>
            Don't remember your password?{" "}
            <Anchor href="#" onClick={onResetPasswordClick} label="Reset" />
          </Text>
        </Box>
      </Box>
    </Box>
  );
  return size === "small" ? (
    <Box fill align="center" justify="center">
      {loginCard}
    </Box>
  ) : (
    <Box
      fill
      align="center"
      justify="center"
      style={{
        background:
          "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(255,255,255,0.1)), url(/lake.jpg) no-repeat center center",
        backgroundSize: "cover",
      }}
    >
      <Box
        round="small"
        pad="large"
        elevation="large"
        align="center"
        background={{ color: "white", opacity: "strong" }}
      >
        {loginCard}
      </Box>
    </Box>
  );
};
