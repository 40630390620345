import React, { useCallback, useContext } from "react";
import { Box, Button, TextInput } from "grommet";
import { FeedbackContext } from "./feedback";

export const ChangePassword = () => {
  const { sendFeedback } = useContext(FeedbackContext);
  const onConfirm = useCallback(() => {
    sendFeedback({
      message: "Password has been successfully reset",
      type: "success",
    });
  }, [sendFeedback]);
  return (
    <Box width="medium" gap="medium" alignSelf="center" justify="center">
      <TextInput placeholder="existing password" />
      <TextInput placeholder="new password" />
      <Button primary label="Change Password" onClick={onConfirm} />
    </Box>
  );
};
