import React, { useCallback, useContext } from "react";
import {
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet";

export const AssetCard = ({
  icon,
  mode,
  type,
  description,
  remaining,
  onModeChange,
  onChoose,
}) => {
  const size = useContext(ResponsiveContext);
  const onModeClick = useCallback(
    (targetMode) => () =>
      type !== targetMode && onModeChange && onModeChange(targetMode),
    [type, onModeChange]
  );

  const automatedActive = mode.toLowerCase() === "automated";

  return (
    <Box
      border
      elevation="xsmall"
      round="large"
      width={size === "large" ? "medium" : "280px"}
      height={size === "large" ? "380px" : "280px"}
      align="center"
      justify="center"
      pad="medium"
      gap={size === "large" ? "small" : "xsmall"}
      margin="small"
    >
      <Box height={size === "large" ? "96px" : undefined} justify="center">
        {icon}
      </Box>
      <Box align="center" justify="center" gap="small">
        <Box align="center">
          <Heading
            level={3}
            size={size !== "large" ? "small" : undefined}
            margin="none"
          >
            <b>{type}</b>
          </Heading>
          <Box
            direction="row"
            align="center"
            round="small"
            pad={{ vertical: "small" }}
          >
            <Button onClick={onModeClick("automated")}>
              <Box
                width="80px"
                border={{
                  side: "all",
                  color: automatedActive ? "tab-active" : "border",
                }}
                background={automatedActive ? "tab-active" : undefined}
                round={{ corner: "left", size: "small" }}
                pad="xsmall"
                align="center"
                justify="center"
              >
                <Text size="small">AUTOMATED</Text>
              </Box>
            </Button>
            <Button onClick={onModeClick("manual")}>
              <Box
                width="80px"
                border={{
                  side: "all",
                  color: !automatedActive ? "tab-active" : "border",
                }}
                background={!automatedActive ? "tab-active" : undefined}
                pad="xsmall"
                round={{ corner: "right", size: "small" }}
                align="center"
                justify="center"
              >
                <Text size="small">MANUAL</Text>
              </Box>
            </Button>
          </Box>
        </Box>
        <Paragraph textAlign="center" color="text-weak" margin="none">
          {description}
        </Paragraph>
        <Paragraph color="text-xweak" margin="none">
          <i>
            {remaining >= 0 ? (
              <span>
                <b>{remaining}</b> remaining
              </span>
            ) : (
              "Unlimited"
            )}
          </i>
        </Paragraph>
      </Box>
      <Button
        primary
        label="Choose"
        disabled={remaining === 0}
        margin={{ top: "small" }}
        size={size !== "large" ? "small" : undefined}
        onClick={onChoose}
      />
    </Box>
  );
};
