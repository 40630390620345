import React, { createContext, useCallback, useState } from "react";
import { get } from "lodash";
import { Box, Button, Layer, Text } from "grommet";
import { FormClose, StatusCritical, StatusGood } from "grommet-icons";

export const FeedbackContext = createContext({});

let autoDismissTimer = undefined;

export const Feedback = ({ children }) => {
  const [activeFeedback, setActiveFeedback] = useState();

  const sendFeedback = useCallback(
    (feedback) => {
      clearTimeout(autoDismissTimer);
      setActiveFeedback(feedback);

      autoDismissTimer = setTimeout(
        () => setActiveFeedback(undefined),
        5000 // 5s
      );
    },
    [setActiveFeedback]
  );

  const onDismiss = useCallback(() => setActiveFeedback(undefined), [
    setActiveFeedback,
  ]);

  const isError = get(activeFeedback, "type") === "error";
  const Icon = isError ? StatusCritical : StatusGood;
  const color = isError ? "status-critical" : "status-ok";
  return (
    <FeedbackContext.Provider value={{ sendFeedback }}>
      {children}
      {activeFeedback && (
        <Layer modal={false} plain position="top-right" responsive={false}>
          <Box
            margin="medium"
            background={color}
            pad={{ left: "medium", right: "small" }}
            round="24px"
            direction="row"
            align="center"
            justify="between"
          >
            <Box
              direction="row"
              align="center"
              justify="center"
              gap="xsmall"
              margin={{ right: "small" }}
            >
              <Icon color="white" />
              <Text color="white" truncate style={{ maxWidth: "400px" }}>
                {activeFeedback.message}
              </Text>
            </Box>
            <Button onClick={onDismiss} icon={<FormClose color="white" />} />
          </Box>
        </Layer>
      )}
    </FeedbackContext.Provider>
  );
};
