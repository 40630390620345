import React, { useContext } from "react";
import numeral from "numeral";
import { Box, ResponsiveContext, Text } from "grommet";
import { CaretDownFill, CaretUpFill } from "grommet-icons";

export const PercentDisplay = ({
  percent,
  timeframe = "week",
  splitter = true,
}) => {
  const size = useContext(ResponsiveContext);
  let color = "status-ok";
  let Icon = CaretUpFill;
  if (percent < 0) {
    color = "status-critical";
    Icon = CaretDownFill;
  }
  return (
    <Box
      direction="row"
      align="center"
      border={splitter && "right"}
      gap="xsmall"
      pad={{ right: "small" }}
    >
      <Box direction="row" align="center">
        {size === "large" && <Icon color={color} />}
        <Text color={color} weight="bold" size="small">
          {numeral(percent).format("+0,0.00")}%
        </Text>
      </Box>
      {size === "large" ? (
        <Text color="text-xweak" size="small">
          this {timeframe}
        </Text>
      ) : (
        <Box
          round="12px"
          pad={{ horizontal: "xsmall" }}
          background="tab-active"
          responsive={false}
        >
          <Text size="xsmall">{timeframe.charAt(0).toUpperCase()}</Text>
        </Box>
      )}
    </Box>
  );
};
