import React, { useCallback, useContext } from "react";
import { Logo } from "../logo";
import { Box, Button } from "grommet";
import { PageChangeContext } from "../../";
import { Add, Notification } from "grommet-icons";
import { NotificationBadge } from "../notification-badge";

export const HeaderMobile = ({ center, right, ...rest }) => {
  const onPageChange = useContext(PageChangeContext);
  const onLogoClick = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);
  let rightNode = right;
  const onAddClick = useCallback(() => onPageChange("addAssets"), [
    onPageChange,
  ]);
  const onNotificationClick = useCallback(() => onPageChange("notifications"), [
    onPageChange,
  ]);
  if (!rightNode) {
    rightNode = (
      <Box width="82px" direction="row" align="center" gap="small">
        <Button onClick={onNotificationClick}>
          <NotificationBadge icon={<Notification />} count={3} />
        </Button>
        <Button icon={<Add />} onClick={onAddClick} />
      </Box>
    );
  }
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad="small"
      border="bottom"
      {...rest}
    >
      <Box width="82px">
        <Button icon={<Logo />} onClick={onLogoClick} />
      </Box>
      {center}
      <Box align="center" justify="center">
        {rightNode}
      </Box>
    </Box>
  );
};
