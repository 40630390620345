import React, { useCallback, useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { Organization } from "grommet-icons";

export const InstitutionPlaceholder = ({ size }) => {
  const boxSize = size === "large" ? "96px" : "72px";
  return (
    <Box
      flex={false}
      background="light-2"
      align="center"
      justify="center"
      width={boxSize}
      height={boxSize}
      round="full"
    >
      <Organization
        color="neutral-3"
        size={size === "large" ? "48px" : undefined}
      />
    </Box>
  );
};

export const InstitutionLogo = ({ institution, size, ...rest }) => {
  const [currentUrl, setCurrentUrl] = useState(
    `//logo.clearbit.com/${institution.url}`
  );
  const [showFallback, setShowFallback] = useState(false);
  const onShowFallback = useCallback(() => {
    if (currentUrl.includes("clearbit")) {
      setCurrentUrl(institution.url);
    } else {
      setShowFallback(true);
    }
  }, [institution.url, currentUrl]);

  useEffect(() => setCurrentUrl(`//logo.clearbit.com/${institution.url}`), [
    institution.url,
  ]);

  if (!institution || !institution.name)
    return <InstitutionPlaceholder size={size} />;

  const { logo, name } = institution;
  const boxSize = size === "large" ? "96px" : size || "36px";
  const fontSize = size === "large" ? "48px" : "24px";
  const fallbackBox = (
    <Box
      flex={false}
      background="light-2"
      align="center"
      justify={"center"}
      width={boxSize}
      height={boxSize}
      round="full"
      {...rest}
    >
      <Text size={fontSize} weight="bold" color="neutral-3">
        {name.charAt(0).toUpperCase()}
      </Text>
    </Box>
  );

  if (showFallback) {
    return fallbackBox;
  }

  if (logo) {
    return (
      <Box
        flex={false}
        width={boxSize}
        align="center"
        justify="center"
        {...rest}
        style={{ ...rest, overflow: "hidden" }}
      >
        <img
          width="100%"
          alt={`${name} logo`}
          src={`data:image/png;base64, ${logo}`}
          onError={onShowFallback}
        />
      </Box>
    );
  }

  if (currentUrl) {
    return (
      <Box
        flex={false}
        width={boxSize}
        height={boxSize}
        align="center"
        justify="center"
        {...rest}
        round="full"
        style={{ ...rest, overflow: "hidden" }}
      >
        <img
          width={boxSize}
          alt={`${name} logo`}
          src={currentUrl}
          onError={onShowFallback}
          style={{ objectPosition: "center" }}
        />
      </Box>
    );
  }

  return fallbackBox;
};

export const InstitutionHoldingsLogo = ({ institution, holding, size }) => {
  return (
    <Box direction="row" align="center">
      <InstitutionLogo
        institution={institution}
        size={size}
        border={{ side: "all", size: "2px", color: "card" }}
      />
      <InstitutionLogo
        institution={holding}
        size={size}
        margin={{ left: "-12px" }}
        border={{ side: "all", size: "2px", color: "card" }}
      />
    </Box>
  );
};
