import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  Anchor,
  Avatar,
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
} from "grommet";
import { Next, Previous } from "grommet-icons";
import { NotificationBadge } from "./notification-badge";

export const Notification = ({ avatarUrl, message, when }) => (
  <Box
    background="notification"
    round="48px"
    direction="row"
    elevation="xsmall"
    height="80px"
    pad="12px"
    align="center"
    gap="8px"
  >
    <Avatar src={avatarUrl} size="medium" />
    <Box flex pad="small" gap="xsmall">
      <Text color="text-weak">{message}</Text>
      <Box
        direction="row"
        align="center"
        justify="between"
        margin={{ top: "hair", right: "medium" }}
      >
        <Text color="text-xweak" size="small">
          {when}
        </Text>
        <Anchor size="small" label="dismiss" />
      </Box>
    </Box>
  </Box>
);

export const NotificationBody = () => (
  <Box gap="12px" pad={{ vertical: "medium" }}>
    <Notification
      avatarUrl="//s.gravatar.com/avatar/e6684969375a4dcc0aa99f0bfae544c3?s=80"
      when="just now"
      message={
        <span>
          Marlon had a <b>20%</b> total net worth increase this week.
        </span>
      }
    />
    <Notification
      avatarUrl="//s.gravatar.com/avatar/1f80adca55d9f5d97932ff97f631a4e8?s=80"
      when="just now"
      message={<span>Tales has reached his retirement goals</span>}
    />
    <Notification
      avatarUrl="//s.gravatar.com/avatar/b226da5c619b18b44eb95c30be393953?s=80"
      when="just now"
      message={<span>You have reached your goal this week</span>}
    />
  </Box>
);

export const NotificationsOpen = ({ onClose }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box border={size === "large" ? "left" : undefined} pad="small">
      <Button onClick={onClose}>
        <Box direction="row" align="center" gap="xsmall">
          <Next size="18px" />
          <Heading level={4} margin="none">
            Notifications
          </Heading>
        </Box>
      </Button>
      <Box flex overflow="auto">
        <Box
          animation={{
            type: "fadeIn",
            delay: 180,
            duration: 600,
          }}
        >
          <NotificationBody />
        </Box>
      </Box>
    </Box>
  );
};

export const NotificationsClosed = ({ onOpen }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box border="left" gap="small" align="center">
      <Button
        icon={<Previous size={size !== "large" ? "small" : undefined} />}
        onClick={onOpen}
      />
      <NotificationBadge
        icon={
          <Avatar
            src="//s.gravatar.com/avatar/e6684969375a4dcc0aa99f0bfae544c3?s=80"
            flex={false}
            size="avatar-small"
          />
        }
        count={1}
      />
      <NotificationBadge
        icon={
          <Avatar
            src="//s.gravatar.com/avatar/1f80adca55d9f5d97932ff97f631a4e8?s=80"
            flex={false}
            size="avatar-small"
          />
        }
        count={1}
      />
      <NotificationBadge
        icon={
          <Avatar
            src="//s.gravatar.com/avatar/b226da5c619b18b44eb95c30be393953?s=80"
            flex={false}
            size="avatar-small"
          />
        }
        count={1}
      />
    </Box>
  );
};

export const Notifications = ({ onOpen, onClose }) => {
  const size = useContext(ResponsiveContext);
  const [open, setOpen] = useState(false);
  const onNotificationOpen = useCallback(() => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, [onOpen, setOpen]);
  const onNotificationClose = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose, setOpen]);

  useEffect(() => size !== "large" && setOpen(false), [size]);

  return (
    <Box
      justify="center"
      overflow="hidden"
      direction="row"
      width={
        open && size !== "small" ? "notification-open" : "notification-closed"
      }
      style={{ transition: "width 0.2s ease-in-out" }}
    >
      {open ? (
        <NotificationsOpen onClose={onNotificationClose} />
      ) : (
        <NotificationsClosed onOpen={onNotificationOpen} />
      )}
    </Box>
  );
};
