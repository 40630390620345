import React, { useCallback } from "react";
import { get } from "lodash";
import { Box, Button, CheckBox, Select, Text, TextInput } from "grommet";
import { Trash } from "grommet-icons";
import { ACCOUNT_TYPE_DISPLAY_NAME, ACCOUNT_TYPE_OPTIONS } from "../utils";
import numeral from "numeral";

export const AccountCard = ({ account, onChange, onRemove, automated }) => {
  const onAccountFieldChange = useCallback(
    (key, bool) => ({ option, target: { checked, value } }) => {
      onChange(account.id, key, get(option, "value", bool ? checked : value));
    },
    [account, onChange]
  );
  const onRemoveAccount = useCallback(() => onRemove(account.id), [
    onRemove,
    account.id,
  ]);
  return (
    <Box
      width="400px"
      border="all"
      round="small"
      gap="small"
      direction="row"
      margin={{ top: "medium" }}
    >
      {automated ? (
        <Box flex gap="small" pad={{ vertical: "small" }} responsive={false}>
          <Box
            direction="row"
            align="center"
            justify="between"
            pad={{ horizontal: "small", bottom: "small" }}
            border="bottom"
            responsive={false}
          >
            <Box>
              <Text weight="bold">{account.name}</Text>
              <Text size="small" color="text-weak">
                {ACCOUNT_TYPE_DISPLAY_NAME[account.type] || [account.type]}
              </Text>
            </Box>
            <Text
              weight="bold"
              size="large"
              color={account.balance >= 0 ? "status-ok" : "status-critical"}
            >
              {numeral(account.balance).format("-$0.[00]a")}
            </Text>
          </Box>
          <Box
            direction="row"
            align="center"
            justify="between"
            gap="small"
            pad={{ horizontal: "small", vertical: "xsmall" }}
            responsive={false}
          >
            <Box gap="xsmall" flex>
              <Text size="small" weight="bold">
                ACCOUNT ALIAS
              </Text>
              <TextInput
                value={get(account, "alias", "")}
                onChange={onAccountFieldChange("alias")}
                size={"small"}
                placeholder="(e.g My Favorite Account)"
              />
            </Box>
            <Box gap="xsmall">
              <Text size="small" weight="bold">
                ACCOUNT VISIBILITY
              </Text>
              <Box height="36px" justify="center">
                <CheckBox
                  checked={get(account, "hidden", false)}
                  label="Hidden"
                  onChange={onAccountFieldChange("hidden", true)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box gap="small" pad={{ left: "medium", vertical: "medium" }}>
          <Box gap="xsmall">
            <Text size="small" weight="bold">
              ACCOUNT NAME
            </Text>
            <TextInput
              value={get(account, "name", "")}
              onChange={onAccountFieldChange("name")}
              size="small"
            />
          </Box>
          <Box direction="row" align="center" justify="between" gap="small">
            <Box gap="xsmall" basis="50%">
              <Text size="small" weight="bold">
                ACCOUNT BALANCE
              </Text>
              <TextInput
                step="10"
                type="number"
                value={get(account, "balance", "")}
                onChange={onAccountFieldChange("balance")}
                size={"small"}
              />
            </Box>

            <Box gap="xsmall">
              <Text size="small" weight="bold">
                ACCOUNT TYPE
              </Text>
              <Select
                size="small"
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
                options={ACCOUNT_TYPE_OPTIONS}
                value={get(account, "type")}
                onChange={onAccountFieldChange("type")}
              />
            </Box>
          </Box>
        </Box>
      )}
      {!automated && (
        <Box
          flex
          align="center"
          margin={{ vertical: "small", horizontal: "medium" }}
          responsive={false}
        >
          <Button onClick={onRemoveAccount}>
            <Trash color="status-critical" />
          </Button>
        </Box>
      )}
    </Box>
  );
};
