import React, { useCallback, useContext, useEffect, useState } from "react";
import { orderBy, remove } from "lodash";
import {
  AccountCard,
  AddAccountButton,
  FeedbackContext,
  Header,
  InstitutionLogo,
  InstitutionTypeBadge,
  PageHeader,
} from "../components";
import { Box, Button, Heading, ResponsiveContext } from "grommet";
import { getFirebaseHost } from "../utils";
import { PageChangeContext, QueryStringContext, UserContext } from "../";
import { v4 as uuidv4 } from "uuid";

const findUserInstitution = (user, institutionId) =>
  user.institutions.find(({ instanceId }) => instanceId === institutionId);

const generatePlaceholderAccount = (i) => ({
  id: uuidv4(),
  balance: 0,
  name: `Account ${i}`,
  type: "checking",
});

export const EditInstitutionBody = () => {
  const {
    user: { data: user },
    updateUser,
  } = useContext(UserContext);
  const onPageChange = useContext(PageChangeContext);
  const { query, setQuery } = useContext(QueryStringContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const size = useContext(ResponsiveContext);

  const userInstitution = findUserInstitution(user, query.institutionId);
  const [institution, setInstitution] = useState(userInstitution);

  if (!userInstitution) {
    onPageChange("dashboard");
  }

  useEffect(
    () => setInstitution(findUserInstitution(user, query.institutionId)),
    [query.institutionId, setInstitution, user]
  );

  const onAccountChange = useCallback(
    (id, key, value) => {
      const newInstitution = { ...institution };

      newInstitution.accounts = newInstitution.accounts.map((a) =>
        a.id === id ? { ...a, [key]: value } : a
      );

      setInstitution(newInstitution);
    },
    [institution]
  );

  const onInstitutionSave = useCallback(async () => {
    institution.accounts = institution.accounts.map((a) => ({
      ...a,
      balance: +a.balance,
    }));
    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/institutions/${user.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ institution }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    setQuery({ activePanel: 1, institutionId: undefined });

    if (size !== "small") {
      onPageChange("dashboard");
    } else {
      onPageChange("institutions");
    }

    sendFeedback({
      message: (
        <span>
          Successfully updated <b>{institution.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [
    updateUser,
    user.id,
    onPageChange,
    institution,
    setQuery,
    sendFeedback,
    size,
  ]);

  const onInstitutionCancel = useCallback(
    () =>
      size === "small"
        ? onPageChange("institutions")
        : onPageChange("dashboard"),
    [onPageChange, size]
  );

  const onRemoveAccount = useCallback(
    (idToBeRemoved) => {
      const newInstitution = { ...institution };
      newInstitution.accounts = [...institution.accounts];
      if (newInstitution.accounts.length - 1 > 0) {
        remove(newInstitution.accounts, ({ id }) => id === idToBeRemoved);

        setInstitution(newInstitution);
      } else {
        sendFeedback({
          message: "You need at least one account per institution",
          type: "error",
        });
      }
    },
    [setInstitution, institution, sendFeedback]
  );

  const onAddAccount = useCallback(() => {
    const newInstitution = { ...institution };
    newInstitution.accounts = [...institution.accounts];
    newInstitution.accounts.push(
      generatePlaceholderAccount(newInstitution.accounts.length + 1)
    );

    setInstitution(newInstitution);
  }, [setInstitution, institution]);
  return (
    <>
      <Box
        flex
        overflow="auto"
        align="start"
        pad={{ vertical: "small", bottom: "large", horizontal: "medium" }}
      >
        <Box flex={false} align="start">
          <Box
            direction="row"
            align="center"
            gap="medium"
            width="large"
            margin={{ top: "small" }}
          >
            <InstitutionLogo
              size={size !== "small" ? "large" : "72px"}
              institution={institution}
            />
            <Box align="start" gap="xsmall">
              <InstitutionTypeBadge type={institution.type} size="small" />
              <Heading level={2} margin="none">
                {institution.name}
              </Heading>
            </Box>
          </Box>
          <Box pad={{ horizontal: "medium" }}>
            <Heading level={3} margin={{ bottom: "none" }}>
              Accounts
            </Heading>
            <Box
              direction="row"
              align="start"
              gap="medium"
              wrap
              style={{ maxWidth: "1360px" }}
            >
              {(institution.type === "automated"
                ? orderBy(institution.accounts, ["balance"], ["desc"])
                : institution.accounts
              ).map((account) => (
                <AccountCard
                  key={account.id}
                  account={account}
                  onChange={onAccountChange}
                  onRemove={onRemoveAccount}
                  automated={institution.type === "automated"}
                />
              ))}
              {institution.type === "manual" && (
                <Box
                  width="medium"
                  margin={{
                    top: size !== "small" ? "medium" : undefined,
                    left: size === "large" ? "small" : undefined,
                  }}
                  align={size === "small" ? "center" : "start"}
                >
                  <AddAccountButton onClick={onAddAccount} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button secondary label="Cancel" onClick={onInstitutionCancel} />
        <Button label="Save" primary onClick={onInstitutionSave} />
      </Box>
    </>
  );
};

export const EditInstitution = () => {
  const onPageChange = useContext(PageChangeContext);

  const onPageClose = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);

  return (
    <Box direction="row" fill>
      <Header />
      <Box flex>
        <PageHeader
          title="Edit Institution"
          subTitle="Hide or create alias to specific accounts"
          onClose={onPageClose}
        />
        <EditInstitutionBody />
      </Box>
    </Box>
  );
};
