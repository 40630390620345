import React, { useCallback, useContext } from "react";
import { OnboardingHeading, OnboardingLayout, PersonalInfo } from "../";
import { StepChangeContext } from "../../pages";

export const CompleteProfile = () => {
  const { onStepChange } = useContext(StepChangeContext);

  const onNextStep = useCallback(() => onStepChange("restimate"), [
    onStepChange,
  ]);
  return (
    <OnboardingLayout
      onNextStep={onNextStep}
      gap="large"
      align="center"
      step="1 of 3 steps"
    >
      <OnboardingHeading
        heading="Complete Profile"
        description="Tell more about you so that we can customize your application"
      />
      <PersonalInfo />
    </OnboardingLayout>
  );
};
