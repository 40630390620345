import { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "lodash";

export const useFetch = (url, options) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const doFetch = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      const res = await fetch(url, options);
      const json = await res.json();
      setData(json);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [url, options]);

  const reset = useCallback(() => {
    setLoading(false);
    setError(null);
    setData(null);
  }, []);

  useEffect(() => {
    if (!get(options, "lazy")) {
      doFetch().catch((e) => {
        console.error("error fetching data", url, options, e);
        // TODO: add proper error handling
        throw e;
      });
    }
  }, [options, url, doFetch]);

  return useMemo(() => ({ data, error, loading, doFetch, reset }), [
    data,
    error,
    loading,
    doFetch,
    reset,
  ]);
};
