import React, { useContext } from "react";
import { Avatar, Box, Button, ResponsiveContext, Text } from "grommet";
import md5 from "md5";
import { get } from "lodash";

export const UserAvatar = ({ user, showName = true, size, ...rest }) => {
  const responsiveSize = useContext(ResponsiveContext);
  let currentSize = size;
  if (!currentSize && responsiveSize === "small") {
    currentSize = "24px";
  }
  return (
    <Button {...rest}>
      <Box
        direction={responsiveSize === "small" ? "column" : "row"}
        align="center"
        justify="center"
        gap="small"
      >
        <Avatar
          size={currentSize}
          src={`//s.gravatar.com/avatar/${md5(get(user, "data.id"))}?s=80`}
        />
        {showName && (
          <Text size={responsiveSize === "small" ? "small" : undefined}>
            {get(user, "data.name")}
          </Text>
        )}
      </Box>
    </Button>
  );
};
