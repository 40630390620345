import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { isEmpty, orderBy } from "lodash";
import {
  Box,
  Button,
  Heading,
  Menu,
  ResponsiveContext,
  Text,
  Tip,
} from "grommet";
import {
  Edit,
  Link,
  More,
  StatusCritical,
  StatusWarning,
  Trash,
  Update,
  CircleAlert,
} from "grommet-icons";
import numeral from "numeral";

import {
  ConfirmationModal,
  FeedbackContext,
  InstitutionLogo,
  InstitutionTypeBadge,
  Loading,
  MenuItemLabel,
} from ".";
import { ACCOUNT_TYPE_DISPLAY_NAME, getFirebaseHost } from "../utils";
import {
  PageChangeContext,
  QueryStringContext,
  TRTThemeContext,
  UserContext,
} from "../";

export const Account = ({ account }) => (
  <Box
    direction="row"
    align="center"
    justify="between"
    responsive={false}
    margin={{ left: "medium", right: "small" }}
  >
    <Box>
      <Text>{account.alias || account.name}</Text>
      <Text size="small" color="text-weak">
        {ACCOUNT_TYPE_DISPLAY_NAME[account.type] || [account.type]}
      </Text>
    </Box>
    <Text weight="bold">{numeral(account.balance).format("-$0.[00]a")}</Text>
  </Box>
);

export const DeleteInstitutionConfirmation = ({
  institution,
  onCancel,
  onConfirm,
}) => {
  const size = useContext(ResponsiveContext);
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to remove ${institution.name}?`}
      actionLabel="Remove"
    >
      <Box direction="row" justify="start" align="center">
        <InstitutionLogo
          institution={institution}
          size={size === "small" ? "72px" : "86px"}
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your net worth will be{" "}
            {institution.netWorth >= 0 ? "decreased" : "increased"} by{" "}
            <b>{numeral(Math.abs(institution.netWorth)).format("-$0.[00]a")}</b>
            .
          </Text>
          <Text color="text-weak">
            Your past transactions and performance will not change, but your
            today's performance will be affected by removing this institution.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const AutomateInstitutionConfirmation = ({
  institution,
  onCancel,
  onConfirm,
}) => {
  const size = useContext(ResponsiveContext);
  return (
    <ConfirmationModal
      color="dark-2"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusWarning color="text" size="large" />}
      title={`Are you sure you want to automate ${institution.name}?`}
      actionLabel="Automate"
      footer={<Text color="text-xweak">1 remaining</Text>}
    >
      <Box direction="row" justify="start" align="center">
        <InstitutionLogo
          institution={institution}
          size={size === "small" ? "72px" : "86px"}
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your accounts will automatically
            update, no need to manually update the transactions anymore.
          </Text>
          <Text color="text-weak">
            All existing manual accounts will be replaced by the automated
            accounts.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const ManualInstitutionConfirmation = ({
  institution,
  onCancel,
  onConfirm,
}) => {
  const size = useContext(ResponsiveContext);
  return (
    <ConfirmationModal
      color="dark-2"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusWarning color="text" size="large" />}
      title={`Are you sure about converting ${institution.name} to manual?`}
      actionLabel="Convert"
      footer={<Text color="text-xweak">Unlimited</Text>}
    >
      <Box direction="row" justify="start" align="center">
        <InstitutionLogo
          institution={institution}
          size={size === "small" ? "72px" : "86px"}
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your accounts will stop syncing
            daily. You will have to manually update in the future.
          </Text>
          <Text color="text-weak">
            Any hidden accounts will be removed. Also, you will loose tracking
            of your investment holdings, if any.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const Institution = ({ institution, border }) => {
  const size = useContext(ResponsiveContext);
  const [
    showDeleteInstitutionConfirmation,
    setShowDeleteInstitutionConfirmation,
  ] = useState(false);
  const [
    showAutomateInstitutionConfirmation,
    setShowAutomateInstitutionConfirmation,
  ] = useState(false);
  const [
    showManualInstitutionConfirmation,
    setShowManualInstitutionConfirmation,
  ] = useState(false);
  const institutionRef = useRef(null);
  const {
    user: { data: user },
    updateUser,
    plaid: { open, ready },
  } = useContext(UserContext);
  const { query, setQuery } = useContext(QueryStringContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const onPageChange = useContext(PageChangeContext);
  const { currentTheme } = useContext(TRTThemeContext);

  useEffect(() => {
    // give a little time for the user to read things before scrolling to the active institution
    setTimeout(() => {
      if (
        query.activeInstitution &&
        institution.id === query.activeInstitution &&
        institutionRef.current
      ) {
        institutionRef.current.scrollIntoView();

        if (!institution.processing) {
          setQuery({
            ...query,
            activeInstitution: undefined,
          });
        }
      }
    }, 300);
  }, [query.activeInstitution, institution, query, setQuery]);

  const onRequestToDeleteInstitution = useCallback(
    () => setShowDeleteInstitutionConfirmation(true),
    [setShowDeleteInstitutionConfirmation]
  );

  const onRequestToAutomateInstitution = useCallback(
    () => setShowAutomateInstitutionConfirmation(true),
    [setShowAutomateInstitutionConfirmation]
  );

  const onRequestToManualInstitution = useCallback(
    () => setShowManualInstitutionConfirmation(true),
    [setShowManualInstitutionConfirmation]
  );

  const onConfirmDeleteInstitution = useCallback(async () => {
    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/institutions/${user.id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ institutionId: institution.instanceId }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    setShowDeleteInstitutionConfirmation(false);

    sendFeedback({
      message: (
        <span>
          Successfully deleted <b>{institution.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [updateUser, institution, user, sendFeedback]);

  const onConfirmAutomateInstitution = useCallback(() => {
    if (ready) {
      setShowAutomateInstitutionConfirmation(false);
      open(institution.instanceId);
    } else {
      console.error("Could not initialize plaid");
      // TODO: show error
    }
  }, [open, ready, institution.instanceId]);

  const onConfirmManualInstitution = useCallback(async () => {
    setShowManualInstitutionConfirmation(false);
    const newInstitution = { ...institution };
    delete newInstitution.accessToken;
    newInstitution.accounts = newInstitution.accounts.filter(
      ({ hidden }) => !hidden
    );
    newInstitution.type = "manual";
    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/institutions/${user.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ institution: newInstitution }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    setQuery({ activePanel: 1, institutionId: institution.instanceId });

    onPageChange("dashboard");

    sendFeedback({
      message: (
        <span>
          Successfully updated <b>{institution.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [updateUser, user.id, onPageChange, institution, setQuery, sendFeedback]);

  const onCancelDeleteInstitution = useCallback(
    () => setShowDeleteInstitutionConfirmation(false),
    [setShowDeleteInstitutionConfirmation]
  );

  const onCancelAutomateInstitution = useCallback(
    () => setShowAutomateInstitutionConfirmation(false),
    [setShowAutomateInstitutionConfirmation]
  );

  const onCancelManualInstitution = useCallback(
    () => setShowManualInstitutionConfirmation(false),
    [setShowManualInstitutionConfirmation]
  );

  const onEditInstitution = useCallback(() => {
    setQuery({ ...query, institutionId: institution.instanceId });
    onPageChange("editInstitution");
  }, [onPageChange, query, setQuery, institution]);

  const commonInstitutionActions = [
    {
      label: (
        <MenuItemLabel
          icon={<Edit color="menu-icon" size="small" />}
          label="Edit"
        />
      ),
      onClick: onEditInstitution,
    },
    {
      label: (
        <MenuItemLabel
          icon={<Trash color="menu-icon" size="small" />}
          label="Remove"
        />
      ),
      onClick: onRequestToDeleteInstitution,
    },
  ];
  const manualInstitutionActions = [
    ...commonInstitutionActions,
    {
      label: (
        <MenuItemLabel
          icon={<Link color="menu-icon" size="small" />}
          label="Convert to Automated"
        />
      ),
      onClick: onRequestToAutomateInstitution,
    },
  ];
  const automatedInstitutionActions = [
    ...commonInstitutionActions,
    {
      label: (
        <MenuItemLabel
          icon={<Update color="menu-icon" size="small" />}
          label="Convert to Manual"
        />
      ),
      onClick: onRequestToManualInstitution,
    },
  ];

  let institutionActions = (
    <Menu
      icon={<More />}
      dropAlign={{ right: "right", top: "bottom" }}
      dropBackground={{ color: "drop", dark: currentTheme === "dark" }}
      items={
        institution.type === "manual"
          ? manualInstitutionActions
          : automatedInstitutionActions
      }
    />
  );
  if (institution.processing) {
    institutionActions = (
      <Box pad={{ horizontal: "small" }}>
        <Loading color={size !== "small" ? "white" : undefined} />
      </Box>
    );
  } else if (institution.communicationIssue) {
    institutionActions = (
      <Tip
        plain
        dropProps={
          size === "small"
            ? { align: { right: "left", bottom: "top" } }
            : { align: { left: "right", top: "top" } }
        }
        content={
          <Box pad="small" round="small" background="light-3">
            <Text size="small">
              There was an issue communicating with the institution. Click to
              fix...
            </Text>
          </Box>
        }
      >
        <Button
          onClick={async () => {
            const userResponse = await fetch(
              `//${getFirebaseHost()}/api/institutions/${user.id}`,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  institution: {
                    ...institution,
                    communicationIssue: false,
                  },
                }),
              }
            );

            const newUser = await userResponse.json();

            updateUser(newUser);
          }}
          icon={<CircleAlert color="liabilities" />}
        />
      </Tip>
    );
  }

  return (
    <Box flex={false} ref={institutionRef}>
      <Box
        direction="row"
        align="center"
        justify="between"
        border={border}
        pad={{
          left: "small",
          right: size === "small" ? "small" : undefined,
          vertical: size === "small" ? "xsmall" : undefined,
        }}
        responsive={false}
      >
        <Box
          direction="row"
          align="center"
          justify="center"
          gap="12px"
          pad={{ top: "small" }}
        >
          <InstitutionLogo institution={institution} />
          <Box align="start">
            <Box direction="row" align="center" justify="center" gap="small">
              <Text
                size={size === "large" ? "large" : undefined}
                truncate
                style={{ maxWidth: "144px" }}
                title={institution.name}
              >
                {institution.name}
              </Text>
              <InstitutionTypeBadge type={institution.type} />
            </Box>
            <Box direction="row" align="center" gap="xsmall">
              <Text weight="bold">
                {numeral(institution.netWorth).format("-$0.[00]a")}
              </Text>
              <Text color="text-weak">in net worth</Text>
            </Box>
          </Box>
        </Box>
        <Box
          width={size === "small" ? "36px" : undefined}
          align="center"
          justify="center"
        >
          {institutionActions}
        </Box>
      </Box>
      <Heading
        level={5}
        margin={{ top: "small", bottom: "none", left: "12px" }}
      >
        Accounts ({institution.accounts.length})
      </Heading>
      <Box gap="small" pad={{ top: "small", bottom: "medium" }}>
        {orderBy(
          institution.accounts.filter(({ hidden }) => !hidden),
          ["balance"],
          ["desc"]
        ).map((account) => (
          <Account key={`${institution.id}_${account.id}`} account={account} />
        ))}
      </Box>
      {showDeleteInstitutionConfirmation && (
        <DeleteInstitutionConfirmation
          institution={institution}
          onCancel={onCancelDeleteInstitution}
          onConfirm={onConfirmDeleteInstitution}
        />
      )}
      {showAutomateInstitutionConfirmation && (
        <AutomateInstitutionConfirmation
          institution={institution}
          onCancel={onCancelAutomateInstitution}
          onConfirm={onConfirmAutomateInstitution}
        />
      )}
      {showManualInstitutionConfirmation && (
        <ManualInstitutionConfirmation
          institution={institution}
          onCancel={onCancelManualInstitution}
          onConfirm={onConfirmManualInstitution}
        />
      )}
    </Box>
  );
};

export const Institutions = ({ institutions, border }) => {
  if (isEmpty(institutions))
    return (
      <Box pad="medium" align="center" justify="center" fill>
        <Text>No institution added</Text>
      </Box>
    );

  return orderBy(institutions, ["netWorth"], ["desc"]).map((institution) => (
    <Institution
      border={border}
      key={institution.id}
      institution={institution}
    />
  ));
};
