import React, { useContext } from "react";
import numeral from "numeral";
import { get } from "lodash";
import { Box, ResponsiveContext, Text } from "grommet";
import { Atm, CreditCard, Currency, Optimize } from "grommet-icons";

export const NetWorthCard = ({ icon, netWorth, type }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      flex={false}
      align="center"
      justify="center"
      pad="12px"
      border="all"
      round="small"
      width={size === "medium" ? "100px" : "120px"}
      gap="xsmall"
      margin="8px"
    >
      {icon}
      <Text weight="bold" size={size === "large" ? "xlarge" : "large"}>
        {numeral(netWorth).format("$0.[00]a")}
      </Text>
      <Text size={size === "large" ? "medium" : "small"}>in {type}</Text>
    </Box>
  );
};

export const NetWorthDetails = ({
  overview = { cash: 0, investments: 0, creditCards: 0, loans: 0 },
}) => {
  return (
    <Box
      direction="row"
      pad={{ horizontal: "small", top: "small", bottom: "large" }}
      align="center"
      justify="center"
      wrap
    >
      <NetWorthCard
        type="cash"
        netWorth={get(overview, "cash")}
        icon={<Currency size="large" color="assets" />}
      />
      <NetWorthCard
        type="investments"
        netWorth={get(overview, "investments")}
        icon={<Optimize size="large" color="assets" />}
      />
      <NetWorthCard
        type="credit cards"
        netWorth={get(overview, "creditCards")}
        icon={<CreditCard size="large" color="liabilities" />}
      />
      <NetWorthCard
        type="loans"
        netWorth={get(overview, "loans")}
        icon={<Atm size="large" color="liabilities" />}
      />
    </Box>
  );
};
