import React, { useContext } from "react";
import { Box, ResponsiveContext } from "grommet";

export const LeftRightHeading = ({ background, left, right }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      background={background}
      direction={size === "large" ? "row" : "column"}
      pad="large"
      justify="center"
      gap={size === "large" ? "xlarge" : undefined}
      align={size === "large" ? undefined : "center"}
    >
      {left}
      {right}
    </Box>
  );
};
