import React, { useCallback, useContext } from "react";
import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Heading,
  Layer,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet";
import { Close, Copy } from "grommet-icons";

import { FeedbackContext } from "../feedback";
import { getFirebaseHost } from "../../utils";
import { TRTThemeContext, UserContext } from "../../index";

export const ShareSettings = ({ onClose, investmentGroup }) => {
  const { currentTheme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const {
    user: { data: user },
    updateUser,
  } = useContext(UserContext);

  const appBackground = currentTheme === "dark" ? "dark-1" : "light-1";

  const onSaveShareSettings = useCallback(async () => {
    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/investment-groups/${user.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          investmentGroup: {
            ...investmentGroup,
          },
        }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    sendFeedback({
      message: (
        <span>
          Successfully saved share settings for <b>{investmentGroup.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [sendFeedback, investmentGroup, updateUser, user.id]);

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} margin="large">
      <Box
        width="xlarge"
        height={size === "small" ? "full" : "xlarge"}
        background={{ color: appBackground, dark: currentTheme === "dark" }}
        round="small"
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          pad="medium"
          border="bottom"
        >
          <Box gap="xsmall">
            <Heading level={3} responsive={false} margin="none">
              Share Settings
            </Heading>
            <Text color="text-xweak">
              Change share settings for <b>{investmentGroup.name}</b>
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex overflow="auto" pad="medium" gap="large">
          <Box gap="xsmall">
            <CheckBox
              toggle
              onChange={() => {}}
              checked={investmentGroup.shareSettings.public}
              label="Publicly available?"
              reverse
            />
            <Text color="text-xweak">
              If this investment group should be available for any viewer to
              join using the public URL below
            </Text>
          </Box>
          <Box gap="small" flex>
            <Box
              round="small"
              pad="small"
              direction="row"
              align="center"
              border="all"
              alignSelf="start"
              gap="small"
            >
              <Text color="text-xweak" style={{ maxWidth: "400px" }} truncate>
                {investmentGroup.shareSettings.url}
              </Text>
              <Copy />
            </Box>
            <Anchor href="#" label="Generate new url" />
          </Box>
          <Paragraph color="text-xweak">
            <i>
              Users joining using this URL will be added as <b>viewers</b> and
              administrators should manually upgrade them to players if
              necessary
            </i>
          </Paragraph>
        </Box>
        <Box
          tag="footer"
          justify="end"
          direction="row"
          pad="medium"
          border="top"
          gap="small"
          flex={false}
          responsive={false}
        >
          <Button secondary label="Cancel" onClick={onClose} />
          <Button label="Save" primary onClick={onSaveShareSettings} />
        </Box>
      </Box>
    </Layer>
  );
};
