import React from "react";
import { Box, Text } from "grommet";

import { OnboardingFooter } from "./";

export const OnboardingLayout = ({
  children,
  onPreviousStep,
  onNextStep,
  step,
}) => {
  return (
    <Box fill>
      <Box align="end" pad={{ top: "small", horizontal: "medium" }}>
        <Text color="text-xweak">{step}</Text>
      </Box>
      <Box flex overflow="auto">
        <Box
          style={{ flex: "1 0 auto" }}
          pad={{ horizontal: "large", vertical: "medium" }}
          align="center"
          gap="large"
        >
          {children}
        </Box>
      </Box>
      {(onPreviousStep || onNextStep) && (
        <OnboardingFooter
          onPreviousStep={onPreviousStep}
          onNextStep={onNextStep}
        />
      )}
    </Box>
  );
};
