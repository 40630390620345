import React from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile } from "../../components";
import { AddInvestmentGroupBody } from "../add-investment-group";

export const AddInvestmentGroupMobile = () => {
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Investment Group
          </Text>
        }
      />
      <Box flex>
        <AddInvestmentGroupBody />
      </Box>
      <FooterMobile />
    </Box>
  );
};
