import React, { useCallback } from "react";
import { Anchor, Box, Text } from "grommet";

export const CallToAction = ({ text, linkText, onClick, ...rest }) => {
  const onCTAClick = useCallback(
    (e) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );
  return (
    <Box
      direction="row"
      align="center"
      justify="center"
      gap="small"
      responsive={false}
    >
      <Text color="text-weak">{text}</Text>
      <Anchor
        href="#"
        label={linkText}
        onClick={onClick ? onCTAClick : undefined}
        {...rest}
      />
    </Box>
  );
};
