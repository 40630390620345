import React, { useContext } from "react";
import {
  Box,
  CheckBox,
  Heading,
  ResponsiveContext,
  Text,
  TextInput,
} from "grommet";
import { Camera } from "grommet-icons";

import { ThemeChooser } from "./";

export const PersonalInfo = () => {
  const size = useContext(ResponsiveContext);
  let circleSize = "small";
  if (size !== "large") {
    circleSize = "96px";
  }
  return (
    <Box align="center" gap="large">
      <Box
        direction="row-responsive"
        align="center"
        justify="center"
        gap="medium"
      >
        <Box gap="small" align="center">
          <Box
            flex={false}
            width={circleSize}
            height={circleSize}
            background="light-3"
            round="full"
            align="center"
            justify="center"
          >
            <Camera size={size === "small" ? "medium" : "large"} />
          </Box>
          <Text
            size="small"
            color="text-xweak"
            textAlign="center"
            style={{ width: size === "small" ? "180px" : "200px" }}
          >
            Click anywhere on the image to change your avatar
          </Text>
        </Box>
        <Box
          align="center"
          width="240px"
          margin={size !== "small" ? { top: "-42px" } : { top: "small" }}
        >
          <TextInput size={size} placeholder="Your Name" />
        </Box>
      </Box>
      <ThemeChooser />
      <Box align="center" gap="medium">
        <Heading level={2} margin="none">
          Recommendations
        </Heading>
        <CheckBox
          label={
            <Text textAlign="center">
              Receive product recommendations to help with retirement plans
            </Text>
          }
        />
      </Box>
    </Box>
  );
};
