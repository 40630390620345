import React, { useContext } from "react";
import { get } from "lodash";
import { ResponsiveContext, ThemeContext } from "grommet";
import { normalizeColor } from "grommet/utils";

export const ChartXAxisLabel = ({
  visibleTicksCount,
  verticalAnchor,
  payload,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const size = useContext(ResponsiveContext);
  return (
    <text
      {...rest}
      fill={normalizeColor("text-weak", theme)}
      fontSize={size !== "large" ? theme.text.small.size : undefined}
    >
      {get(payload, "value")}
    </text>
  );
};
