import React, { createContext, useCallback, useContext, useState } from "react";
import { Box, ResponsiveContext } from "grommet";

import {
  CompleteProfile,
  OnboardingHeader,
  ValidateEmail,
  OnboardingRestimate,
  ChoosePlan,
  OnboardingAddInstitution,
  OnboardingAddPayment,
} from "../components";

const ONBOARDING_STEPS = {
  validateEmail: ValidateEmail,
  completeProfile: CompleteProfile,
  restimate: OnboardingRestimate,
  choosePlan: ChoosePlan,
  addInstitution: OnboardingAddInstitution,
  addPayment: OnboardingAddPayment,
};

export const StepChangeContext = createContext(() => {});

export const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState("validateEmail");
  const size = useContext(ResponsiveContext);
  let boxSize = "100%";
  if (size === "large") {
    boxSize = "xlarge";
  }
  const Component = ONBOARDING_STEPS[currentStep];

  const onStepChange = useCallback((nextStep) => {
    setCurrentStep(nextStep);
  }, []);

  return (
    <StepChangeContext.Provider value={{ currentStep, onStepChange }}>
      <Box fill align="center" justify="start" background="onboarding-fill">
        <Box
          width={boxSize}
          fill="vertical"
          style={{ maxWidth: "1200px" }}
          background="onboarding-background"
          elevation="small"
        >
          <OnboardingHeader />
          <Component />
        </Box>
      </Box>
    </StepChangeContext.Provider>
  );
};
