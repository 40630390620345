import React, { useContext } from "react";

import { Box, Button, Heading, Paragraph, TextInput } from "grommet";
import { LogoFull } from "../components";
import { PageChangeContext } from "../";

export const Landing = () => {
  const onPageChange = useContext(PageChangeContext);
  return (
    <Box fill align="center" justify="center">
      <Box width="medium" align="center" gap="small">
        <LogoFull color="plain" size="large" />
        <Heading
          textAlign="center"
          level={2}
          margin={{ top: "small", bottom: "none" }}
        >
          <b>Access Code Required</b>
        </Heading>
        <Paragraph textAlign="center" color="text-weak" margin="none">
          Our application isn't ready for the public yet. To have access now you
          need to provide us with your access code.
        </Paragraph>
        <Box margin={{ top: "small" }} alignSelf="stretch" gap="medium">
          <TextInput size="xlarge" placeholder="access code" />
          <Button
            size="large"
            label="Submit"
            primary
            onClick={() => onPageChange("website")}
            alignSelf="stretch"
          />
        </Box>
      </Box>
    </Box>
  );
};
