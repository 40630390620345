import React from "react";
import { Box, Text } from "grommet";
import { get } from "lodash";

export const UserPlan = ({ user }) => (
  <Box
    background="status-ok"
    pad="xsmall"
    round="small"
    width="48px"
    align="center"
  >
    <Text size="small" weight="bold">
      {get(user, "data.settings.plan")}
    </Text>
  </Box>
);
