import React, { useContext } from "react";
import styled from "styled-components";
import {
  Anchor,
  Avatar,
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text,
  TextInput,
} from "grommet";
import {
  BarChart,
  Currency,
  FacebookOption,
  Home,
  Google,
  LinkedinOption,
  Twitter,
} from "grommet-icons";

import {
  CircleIconHeading,
  GoalCheckmark,
  LeftRightHeading,
  LogoFull,
  LogoStack,
  RetirementEstimator,
} from "../components";
import { PageChangeContext } from "../";

const Timeline = styled(Box)`
  position: relative;
  margin: 0 auto;

  &::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: ${({ theme }) => theme.global.colors["neutral-3"]};
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
  }
`;

const TimelineRow = styled(Box)`
  position: relative;
  background-color: inherit;

  &::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border: 3px solid ${({ theme }) => theme.global.colors["neutral-3"]};
    top: 40%;
    border-radius: 50%;
    z-index: 1;
  }
`;

const RetirementTrackerHeader = styled(Box)`
  position: relative;
`;

export const Website = () => {
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const textSize = size !== "small" ? "large" : undefined;

  return (
    <Box flex={false}>
      <RetirementTrackerHeader
        tag="header"
        direction="row"
        pad={{ bottom: "xlarge" }}
        justify="center"
      >
        <LogoStack size={size} />
        <Box pad={{ top: "xlarge", horizontal: "large" }} justify="center">
          <Heading
            textAlign="center"
            margin={
              size === "small"
                ? { top: "xlarge", bottom: "small" }
                : { bottom: "small" }
            }
          >
            Hello, are you on track for retirement?
          </Heading>
          <Text
            alignSelf="center"
            textAlign="center"
            color="text-xweak"
            size={textSize}
            style={size === "small" ? { maxWidth: "300px" } : undefined}
          >
            The retirement tracker will help you to know it at anytime,
            anywhere.
          </Text>
          <Box
            direction="row-responsive"
            justify="between"
            pad={{ top: "large" }}
            align="center"
          >
            <Box
              align="center"
              pad={size === "small" ? { bottom: "large" } : { right: "large" }}
              gap="medium"
              margin={size === "small" ? { left: "large" } : undefined}
            >
              <GoalCheckmark>Set your retirements goals</GoalCheckmark>
              <GoalCheckmark>
                Stay on track by measuring your daily progress
              </GoalCheckmark>
              <GoalCheckmark>
                Know if you are outperforming S&P 500
              </GoalCheckmark>
              <GoalCheckmark>
                Share your performance with your friends and family
              </GoalCheckmark>
            </Box>
            <Box
              align="center"
              border={{ side: size === "small" ? "top" : "left" }}
              pad={size === "small" ? { top: "large" } : { left: "large" }}
              gap={size === "small" ? "medium" : undefined}
            >
              <Heading level="3" margin={{ top: "none", bottom: "small" }}>
                Get started today, it's free!
              </Heading>
              <Box
                gap={size === "small" ? "medium" : "small"}
                width="medium"
                align="center"
              >
                <TextInput
                  size={size === "small" ? "small" : "large"}
                  placeholder="email"
                />
                <TextInput
                  size={size === "small" ? "small" : "large"}
                  placeholder="password"
                />
                <Box direction="row" align="center" gap="small">
                  <Button
                    size={size === "small" ? "small" : undefined}
                    primary
                    label="Sign up"
                    onClick={() => onPageChange("onboarding")}
                  />
                  <Text color="text-xweak">or</Text>
                  <Button
                    icon={
                      <Google
                        color="plain"
                        size={size === "small" ? "small" : undefined}
                      />
                    }
                    secondary
                    label="Sign up with Google"
                    size="small"
                  />
                </Box>
                <Text size="small" textAlign="center">
                  By continuing to sign up you accept our{" "}
                  <Anchor href="#">Terms of Service</Anchor> and{" "}
                  <Anchor href="#">Privacy Policy</Anchor>.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box pad="medium" style={{ position: "absolute", right: 0 }}>
          <Anchor
            href="#"
            label="Sign in"
            color="neutral-3"
            size={size !== "small" ? "large" : undefined}
            onClick={(event) => {
              event.preventDefault();
              onPageChange("signIn");
            }}
          />
        </Box>
      </RetirementTrackerHeader>
      <LeftRightHeading
        background="light-2"
        left={
          <Box>
            <Heading textAlign="center" level={2} margin={{ bottom: "small" }}>
              Have a goal to stay in the know
            </Heading>
            <Paragraph textAlign="center" margin="none">
              How much money do you need to retire years from now? Most people
              don't know the answer to this question. Our proprietary{" "}
              <Anchor href="#">Restimate®</Anchor> calculator will provide all
              the numbers you need to stay on track every day, week, and years
              to come.
            </Paragraph>
            <Paragraph textAlign="center">
              Curious to know the answer? <Anchor href="#">Sign up</Anchor>
            </Paragraph>
          </Box>
        }
        right={
          <Box margin={{ vertical: "medium" }}>
            <RetirementEstimator />
          </Box>
        }
      />
      <LeftRightHeading
        left={
          <Box margin={{ vertical: "medium" }} align="center">
            <CircleIconHeading icon={Currency} color="neutral-3" />

            <Box
              direction="row"
              margin={{ top: size === "large" ? "-36px" : "-24px" }}
            >
              <CircleIconHeading
                icon={BarChart}
                color="accent-4"
                margin={{ right: size === "large" ? "-20px" : "-12px" }}
                style={{ position: "relative" }}
              />
              <CircleIconHeading icon={Home} color="accent-2" />
            </Box>
          </Box>
        }
        right={
          <Box align="center" gap="large" pad={{ bottom: "medium" }}>
            <Box>
              <Heading
                textAlign="center"
                level={2}
                margin={{ bottom: "small" }}
              >
                All your stuff in one place
              </Heading>
              <Paragraph textAlign="center" margin="none">
                Connect all your accounts once, and stay connected. Your assets
                and liablities will be organized in a way that is easy for you
                to consume. No budgeting and/or transactions. We want to stay
                focused on getting you on your retirement path.
              </Paragraph>
            </Box>

            <Button label="Watch demo" />
          </Box>
        }
      />
      <LeftRightHeading
        background="light-2"
        left={
          <Box margin={size === "large" ? { top: "medium" } : undefined}>
            <Heading textAlign="center" level={2} margin={{ bottom: "small" }}>
              Stay on track
            </Heading>
            <Paragraph textAlign="center" margin="none">
              The Retirement Tracker will update your records frequently and
              track your performance against the projected retirement goals. We
              will keep you informed and help you stay on track for retirement.
              We will compare you with the <Anchor href="#">S&P 500</Anchor>{" "}
              index so that you have an idea how you are doing vs how the market
              is doing. You can always adjust your plans in case you
              over-estimated or under-estimated.
            </Paragraph>
          </Box>
        }
        right={
          <Box margin={{ vertical: "large" }}>
            <img
              src="on-track.svg"
              width={size === "large" ? "360px" : "240px"}
              alt="chart"
            />
          </Box>
        }
      />
      <Box align="center" pad={{ top: "large", bottom: "xlarge" }}>
        <Heading level={2} margin={{ bottom: "small" }}>
          Socialize 🎉
        </Heading>
        <Text
          margin={{ top: "none", bottom: "large" }}
          textAlign="center"
          color="text-weak"
          style={{ maxWidth: "300px" }}
        >
          Let your friends and family know how you are doing. Compare with them,
          get inspired by them, follow them.
        </Text>
        {size === "small" ? (
          <Box gap="large" alignSelf="stretch" pad={{ horizontal: "xlarge" }}>
            <Box
              alignSelf="start"
              background="light-2"
              round="36px"
              width="270px"
              direction="row"
              align="center"
              gap="medium"
              margin={{ left: "-8px" }}
              elevation="small"
            >
              <Avatar
                src="//s.gravatar.com/avatar/e6684969375a4dcc0aa99f0bfae544c3?s=80"
                size="large"
                flex={false}
                elevation="medium"
              />
              <Text color="text-weak">
                Marlon had a <b>20%</b> total net worth increase this week.
              </Text>
            </Box>
            <Box
              alignSelf="end"
              background="light-2"
              round="36px"
              direction="row"
              align="center"
              gap="medium"
              margin={{ right: "-8px" }}
              elevation="small"
              width="270px"
            >
              <Avatar
                src="//s.gravatar.com/avatar/1f80adca55d9f5d97932ff97f631a4e8?s=80"
                size="large"
                flex={false}
                elevation="medium"
              />
              <Text color="text-weak">
                Tales has reached his retirement goals.
              </Text>
            </Box>
          </Box>
        ) : (
          <Timeline fill pad={{ vertical: "large" }}>
            <TimelineRow direction="row" justify="center">
              <Box
                background="light-2"
                round="large"
                width="300px"
                direction="row"
                align="center"
                gap="small"
                margin={{ vertical: "medium", left: "-360px" }}
                elevation="small"
              >
                <Avatar
                  src="//s.gravatar.com/avatar/e6684969375a4dcc0aa99f0bfae544c3?s=80"
                  size="large"
                  flex={false}
                  elevation="medium"
                />
                <Text color="text-weak">
                  Marlon had a <b>20%</b> total net worth increase this week.
                </Text>
              </Box>
            </TimelineRow>
            <TimelineRow direction="row" justify="center">
              <Box
                background="light-2"
                round="large"
                direction="row"
                align="center"
                gap="small"
                margin={{ vertical: "medium", left: "360px" }}
                elevation="small"
                width="300px"
              >
                <Avatar
                  src="//s.gravatar.com/avatar/1f80adca55d9f5d97932ff97f631a4e8?s=80"
                  size="large"
                  flex={false}
                  elevation="medium"
                />
                <Text color="text-weak">
                  Tales has reached his retirement goals.
                </Text>
              </Box>
            </TimelineRow>
          </Timeline>
        )}
      </Box>
      <LeftRightHeading
        background="light-2"
        left={
          <Box margin={size === "large" ? { top: "medium" } : undefined}>
            <Heading textAlign="center" level={2} margin={{ bottom: "small" }}>
              Ad-free and secure
            </Heading>
            <Paragraph textAlign="center" margin="none">
              The Retirement Tracker will never sell your information or offer
              you services that you haven't requested for. Your sensitive data
              is encrypted and not even our CEO has access to your personal
              numbers. Your privacy is really important to us, after all it is
              your money and you have worked hard for it.
            </Paragraph>
          </Box>
        }
        right={
          <Box margin={{ vertical: "large" }}>
            <img
              src="secure.svg"
              width={size === "large" ? "360px" : "240px"}
              alt="security"
            />
          </Box>
        }
      />

      <Box align="center" pad="large">
        <Heading level={2} margin={{ bottom: "small" }}>
          Our Plans
        </Heading>
        <Paragraph
          margin={{ top: "none", bottom: "large" }}
          textAlign="center"
          color="text-weak"
        >
          All our plans, including the free one, have no advertisement. Choose
          between a variety of features, upgrade anytime, cancel anytime.
        </Paragraph>
        <table
          style={
            size !== "small"
              ? { marginLeft: "-10%", borderSpacing: "8px" }
              : { borderSpacing: "4px" }
          }
        >
          <tr>
            {size !== "small" && <td></td>}
            <td align="center">
              <Box background="light-2" pad="small" round="small" width="80px">
                <Text weight="bold">BASIC</Text>
              </Box>
            </td>
            <td align="center">
              <Box background="light-4" pad="small" round="small" width="80px">
                <Text weight="bold">ENTRY</Text>
              </Box>
            </td>
            <td align="center">
              <Box background="dark-6" pad="small" round="small" width="80px">
                <Text weight="bold">PRO</Text>
              </Box>
            </td>
          </tr>
          <tr>
            {size !== "small" ? (
              <td align="end">
                <Box>
                  <Text size={size} weight="normal">
                    PRICE
                  </Text>
                  <Text color="text-xweak">Cancel Anytime</Text>
                </Box>
              </td>
            ) : undefined}
            <td>
              <Box direction="row" align="center" justify="center">
                <Text
                  size={size === "small" ? "medium" : "xlarge"}
                  weight="300"
                  margin={{ top: "4px" }}
                >
                  $
                </Text>
                <Text size="28px" textAlign="end" weight="300">
                  0.00
                </Text>
                <Text
                  size={size === "small" ? "medium" : "large"}
                  weight="300"
                  margin={{ top: "4px" }}
                >
                  /month
                </Text>
              </Box>
            </td>
            <td>
              <Box direction="row" align="center" justify="center">
                <Text
                  size={size === "small" ? "medium" : "xlarge"}
                  weight="300"
                  margin={{ top: "4px" }}
                >
                  $
                </Text>
                <Text size="28px" textAlign="end" weight="300">
                  5.99
                </Text>
                <Text
                  size={size === "small" ? "medium" : "large"}
                  weight="300"
                  margin={{ top: "4px" }}
                >
                  /month
                </Text>
              </Box>
            </td>
            <td>
              <Box direction="row" align="center" justify="center">
                <Text
                  size={size === "small" ? "medium" : "xlarge"}
                  weight="300"
                  margin={{ top: "4px" }}
                >
                  $
                </Text>
                <Text size="28px" textAlign="end" weight="300">
                  9.99
                </Text>
                <Text
                  size={size === "small" ? "medium" : "large"}
                  weight="300"
                  margin={{ top: "4px" }}
                >
                  /month
                </Text>
              </Box>
            </td>
          </tr>
          <tr height="60px">
            {size !== "small" ? (
              <td align="end">
                <Box>
                  <Text size={size} weight="normal">
                    MANUAL INSTITUTIONS
                  </Text>
                  <Text color="text-xweak">
                    Update account changes manually
                  </Text>
                </Box>
              </td>
            ) : undefined}
            <td align="center">
              <Text
                color="text-weak"
                size={size === "large" ? "large" : undefined}
              >
                {size === "small" ? "Unlimited manual" : "Unlimited"}
              </Text>
            </td>
            <td align="center">
              <Text
                color="text-weak"
                size={size === "large" ? "large" : undefined}
              >
                {size === "small" ? "Unlimited manual" : "Unlimited"}
              </Text>
            </td>
            <td align="center">
              <Text
                color="text-weak"
                size={size === "large" ? "large" : undefined}
              >
                {size === "small" ? "Unlimited manual" : "Unlimited"}
              </Text>
            </td>
          </tr>
          <tr height="60px">
            {size !== "small" ? (
              <td align="end">
                <Box>
                  <Text size={size} weight="normal">
                    AUTOMATED INSTITUTIONS
                  </Text>
                  <Text color="text-xweak">
                    Update account changes automatically
                  </Text>
                </Box>
              </td>
            ) : undefined}

            <td align="center">
              <Text
                color="text-weak"
                size={size === "large" ? "large" : undefined}
              >
                {size === "small" ? "1 automated institution" : "1 institution"}
              </Text>
            </td>
            <td align="center">
              <Text
                color="text-weak"
                size={size === "large" ? "large" : undefined}
              >
                {size === "small"
                  ? "5 automated institutions"
                  : "5 institutions"}
              </Text>
            </td>
            <td align="center">
              <Text
                color="text-weak"
                size={size === "large" ? "large" : undefined}
              >
                {size === "small"
                  ? "10 automated institutions"
                  : "10 institutions"}
              </Text>
            </td>
          </tr>
          <tr height="72px">
            {size !== "small" && <td />}
            <td colSpan="3" align="center">
              <Text textAlign="center" color="text-xweak">
                <i>Try any plan 7 days free.</i>
              </Text>
            </td>
          </tr>
        </table>
      </Box>
      <Box background="neutral-3" pad={size === "small" ? "large" : "medium"}>
        <Box
          direction="row-responsive"
          align="start"
          justify="between"
          gap="medium"
        >
          <Box margin={{ top: "small" }}>
            <Box>
              <LogoFull />
              <Box pad="small" gap="medium" margin={{ top: "small" }}>
                <Anchor href="#" color="white" size="large">
                  Contact Us
                </Anchor>
                <Anchor href="#" color="white" size="large">
                  Careers
                </Anchor>
                <Anchor href="#" color="white" size="large">
                  Legal
                </Anchor>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box align="start" pad={{ top: "small" }} gap="small">
              <Text>
                Sign up to receive news about us, including future offers.
              </Text>
              <Box
                direction="row"
                align="center"
                justify="end"
                gap="small"
                pad={{ vertical: "small" }}
                fill={size !== "small"}
                style={{ minWidth: "300px" }}
              >
                <TextInput size="small" placeholder="email" />
                <Button size="small" secondary label="Subscribe"></Button>
              </Box>
            </Box>
            <Box
              direction="row"
              justify={size === "small" ? "start" : "end"}
              margin={{ top: "medium" }}
              gap="small"
            >
              <Text>Follow us on</Text>
              <Box direction="row" align="center" gap="medium">
                <FacebookOption />
                <Twitter />
                <LinkedinOption />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box margin={{ vertical: "medium" }} gap="small">
          <Paragraph margin="none">
            © 2020 The Retirement Tracker, Inc.
          </Paragraph>
          <Paragraph margin="none">
            Restimate® is a registered trademark of The Retirement Tracker, Inc.
          </Paragraph>
        </Box>
      </Box>
    </Box>
  );
};
