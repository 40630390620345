import React, { useCallback, useContext, useMemo } from "react";
import { get } from "lodash";
import { format } from "timeago.js";
import numeral from "numeral";

import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Menu,
  Meter,
  ResponsiveContext,
  Text,
} from "grommet";
import { FormUp, Logout, SettingsOption, ShareRounded } from "grommet-icons";

import {
  Institutions,
  LogoFull,
  NetWorthDetails,
  UserAvatar,
  UserPlan,
  InvestmentGroups,
} from ".";
import {
  PageChangeContext,
  QueryStringContext,
  TRTThemeContext,
  UserContext,
} from "..";
import { getFirebaseHost } from "../utils";

export const HeaderOverview = () => {
  const {
    user: { data: user },
  } = useContext(UserContext);
  const size = useContext(ResponsiveContext);
  const hasInstitutionsProcessing = get(
    user,
    "institutions",
    []
  ).some(({ processing }) => Boolean(processing));
  const assets = numeral(get(user, "overview.assets")).format("$0.[00]a");
  const liabilities = numeral(get(user, "overview.liabilities")).format(
    "-$0.[00]a"
  );
  const totalNetWorth =
    get(user, "overview.assets") + get(user, "overview.liabilities");
  const meterMax =
    get(user, "overview.assets") - get(user, "overview.liabilities");

  return (
    <Box flex={false}>
      <Box
        direction="row"
        align="center"
        justify="between"
        pad={size === "small" ? "medium" : "small"}
        flex={false}
      >
        <Heading level="4" margin="none">
          Overview
        </Heading>
        {hasInstitutionsProcessing ? (
          <Box direction="row" align="center" gap="xsmall">
            <img alt="processing spinner" src="/loading.svg" width="21px" />
            <Text color="text-weak">Updating...</Text>
          </Box>
        ) : (
          <Text color="text-weak">
            Last update {format(get(user, "updatedAt"))}
          </Text>
        )}
      </Box>
      <Box
        flex={false}
        direction="row"
        pad={{
          bottom: size === "small" ? "large" : "medium",
          horizontal: "small",
        }}
        border="bottom"
      >
        <Box
          align="center"
          justify="center"
          border="right"
          pad={{ right: "small" }}
          width="160px"
        >
          <Heading level={size === "small" ? 2 : 3} margin="none">
            <b>{numeral(totalNetWorth).format("$0.[00]a")}</b>
          </Heading>
          <Text size="small" textAlign="center">
            Total Net Worth
          </Text>
        </Box>
        <Box
          margin={{ left: "small" }}
          gap="xsmall"
          pad={size === "small" ? { horizontal: "medium" } : undefined}
        >
          <Box>
            <Box direction="row" align="center" justify="between">
              <Text size="small">Assets</Text>
              <Text weight="bold">{assets}</Text>
            </Box>
            <Meter
              background="meter-background"
              round
              thickness="small"
              max={meterMax}
              values={[
                {
                  value: get(user, "overview.assets"),
                  color: "assets",
                },
              ]}
            />
          </Box>
          <Box>
            <Box direction="row" align="center" justify="between">
              <Text size="small">Liabilities</Text>
              <Text weight="bold">{liabilities}</Text>
            </Box>
            <Meter
              background="meter-background"
              round
              thickness="small"
              max={meterMax}
              values={[
                {
                  value: -get(user, "overview.liabilities"),
                  color: "liabilities",
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const HeaderBody = () => {
  const { query, setQuery } = useContext(QueryStringContext);
  const {
    user: { data: user },
  } = useContext(UserContext);

  const onActivePanelChange = useCallback(
    (newActivePanels) => {
      const newActivePanel = newActivePanels ? newActivePanels[0] : undefined;
      setQuery({
        activePanel: newActivePanel,
      });
    },
    [setQuery]
  );

  return (
    <Box flex={false}>
      <HeaderOverview />
      <Accordion
        activeIndex={get(query, "activePanel")}
        onActive={onActivePanelChange}
        animate={false}
      >
        <AccordionPanel
          label={
            <Box pad={{ horizontal: "small", vertical: "medium" }}>
              <Text weight="bold">NET WORTH DETAILS</Text>
            </Box>
          }
        >
          <NetWorthDetails overview={get(user, "overview")} />
        </AccordionPanel>

        <AccordionPanel
          label={
            <Box pad={{ horizontal: "small", vertical: "medium" }}>
              <Text weight="bold">INSTITUTIONS</Text>
            </Box>
          }
        >
          <Institutions institutions={get(user, "institutions")} border="top" />
        </AccordionPanel>
        <AccordionPanel
          label={
            <Box
              pad={{
                horizontal: "small",
                vertical: "medium",
              }}
            >
              <Text weight="bold">INVESTMENT GROUPS</Text>
            </Box>
          }
        >
          <InvestmentGroups investmentGroups={get(user, "investmentGroups")} />
        </AccordionPanel>
      </Accordion>
    </Box>
  );
};

export const Header = () => {
  const { user, updateUser } = useContext(UserContext);
  const onPageChange = useContext(PageChangeContext);
  const { currentTheme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);

  const automatedInstitutions = useMemo(
    () =>
      (get(user, "data.institutions") || []).filter(
        ({ type }) => type === "automated"
      ),
    [user]
  );

  const onAddAccounts = useCallback(() => onPageChange("addAssets"), [
    onPageChange,
  ]);
  let content;
  if (!get(user, "data")) {
    content = (
      <Box pad="small" align="center">
        Loading...
      </Box>
    );
  } else if (get(user, "error")) {
    content = (
      <Box pad="small" align="center">
        Not found try again...
      </Box>
    );
  } else if (get(user, "data")) {
    content = (
      <>
        <Box flex overflow="auto" style={{ scrollBehavior: "smooth" }}>
          <HeaderBody />
        </Box>
        <Box pad={{ vertical: "small", horizontal: "medium" }}>
          <Button
            secondary
            label="Add Assets & Liabilities"
            onClick={onAddAccounts}
          />
        </Box>
        <Box
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: "small" }}
        >
          <Menu
            dropProps={{ elevation: "medium" }}
            dropAlign={{ bottom: "top" }}
            dropBackground={{ color: "drop", dark: currentTheme === "dark" }}
            items={[
              {
                onClick: () => onPageChange("settings"),
                label: (
                  <Box direction="row" align="center" gap="xsmall">
                    <SettingsOption size="small" color="menu-icon" />
                    <Text>Settings</Text>
                  </Box>
                ),
              },
              {
                onClick: async () => {
                  if (automatedInstitutions && automatedInstitutions.length) {
                    const userResponse = await fetch(
                      `//${getFirebaseHost()}/api/institutions/${user.data.id}`,
                      {
                        method: "PUT",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          institution: {
                            ...automatedInstitutions[0],
                            communicationIssue: true,
                          },
                        }),
                      }
                    );

                    const newUser = await userResponse.json();

                    updateUser(newUser);
                  }
                },
                label: (
                  <Box direction="row" align="center" gap="xsmall">
                    <ShareRounded size="small" color="menu-icon" />
                    <Text>Simulate Error</Text>
                  </Box>
                ),
              },
              {
                onClick: () => onPageChange("signIn"),
                label: (
                  <Box direction="row" align="center" gap="xsmall">
                    <Logout size="small" color="menu-icon" />
                    <Text>Sign out</Text>
                  </Box>
                ),
              },
            ]}
          >
            <Box
              margin={{ vertical: "small" }}
              direction="row"
              align="center"
              gap="xsmall"
            >
              <UserAvatar user={user} />
              <FormUp />
            </Box>
          </Menu>
          <UserPlan user={user} />
        </Box>
      </>
    );
  }

  return (
    <Box
      background="app-header"
      width={size === "large" ? "medium" : "280px"}
      elevation="medium"
      style={{ position: "relative" }}
    >
      <Box
        align="start"
        border="bottom"
        height="80px"
        pad={{ left: "small" }}
        justify="center"
      >
        <Button onClick={() => onPageChange("dashboard")}>
          <LogoFull />
        </Button>
      </Box>
      {content}
    </Box>
  );
};
