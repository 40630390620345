import React, { useCallback, useMemo, useContext, useState } from "react";
import { orderBy } from "lodash";
import {
  Anchor,
  Avatar,
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Tab,
  Tabs,
  Text,
  ThemeContext,
} from "grommet";
import { normalizeColor } from "grommet/utils";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import {
  Header,
  InvestmentGroupLogo,
  ManageMembers,
  RecentActivityMessage,
} from "../components";
import {
  PageChangeContext,
  QueryStringContext,
  TRTThemeContext,
  UserContext,
} from "../";
import { CaretDownFill, CaretUpFill, Close, Link, Mail } from "grommet-icons";
import md5 from "md5";
import numeral from "numeral";

const findUserInvestmentGroup = (user, investmentGroupId) =>
  user.investmentGroups.find(({ id }) => id === investmentGroupId);

const UserLeaderboardLabel = (props) => {
  const size = useContext(ResponsiveContext);
  const { x, y, value: user, width, height } = props;
  const isPositive = user.performance >= 0;
  return (
    <g>
      <foreignObject
        x={isPositive ? x + width - 12 : x - Math.abs(width) - 188}
        y={y - height}
        width={200}
        height={100}
      >
        <Box
          direction={isPositive ? "row" : "row-reverse"}
          align="center"
          gap="xsmall"
        >
          <Avatar
            elevation="small"
            size="36px"
            src={`//s.gravatar.com/avatar/${md5(user.id)}?s=80`}
          />
          <Box>
            <Text
              color="text-weak"
              size={size === "small" ? "small" : undefined}
              truncate
              style={{ maxWidth: "144px" }}
            >
              <b>#{user.position}</b> {user.name}
            </Text>
            <Box
              direction="row"
              align="center"
              justify={isPositive ? "start" : "end"}
              margin={isPositive ? { left: "-6px", top: "-3px" } : undefined}
            >
              {isPositive ? (
                <CaretUpFill color="assets" />
              ) : (
                <CaretDownFill color="liabilities" />
              )}
              <Text weight="bold" color={isPositive ? "assets" : "liabilities"}>
                {numeral(user.performance / 100).format("-0.[00]%")}
              </Text>
            </Box>
          </Box>
        </Box>
      </foreignObject>
    </g>
  );
};

export const InvestmentGroupBody = () => {
  const {
    user: { data: user },
  } = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const { currentTheme } = useContext(TRTThemeContext);
  const { query } = useContext(QueryStringContext);
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const investmentGroup = findUserInvestmentGroup(
    user,
    query.activeInvestmentGroup || query.investmentGroupId
  );
  const [showManageMembers, setShowManageMembers] = useState(false);

  const members = useMemo(
    () => (investmentGroup ? investmentGroup.members || [] : []),
    [investmentGroup]
  );

  const { administrators, players, viewers } = useMemo(
    () =>
      members.reduce(
        (groupedMembers, member) => {
          if (!member.pending) {
            if (member.role === "administrator") {
              groupedMembers.administrators.push(member);
            } else if (member.role === "player") {
              groupedMembers.players.push(member);
            } else {
              groupedMembers.viewers.push(member);
            }
          }
          return groupedMembers;
        },
        {
          administrators: [],
          players: [],
          viewers: [],
        }
      ),
    [members]
  );

  const fakeLeaderboardData = useMemo(
    () =>
      orderBy(
        [...administrators, ...players].map((a) => ({
          ...a,
          performance: Math.random() * (20 - -10) - 10,
        })),
        "performance",
        "desc"
      )
        .slice(0, 3)
        .map((a, index) => ({ ...a, position: index + 1 })),
    [administrators, players]
  );

  const onHideManageMembers = useCallback(
    () => setShowManageMembers(false),
    []
  );
  const onShowManageMembers = useCallback(() => setShowManageMembers(true), []);
  const onPageClose = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);

  const totalPlayers = administrators.length + players.length;
  const totalViewers = viewers.length;
  const investmentGroupIconSize = size === "small" ? "52px" : "72px";

  if (!investmentGroup) {
    onPageChange("dashboard");
    return null;
  }

  const fakeInstance = (
    <>
      <Heading level={3}>Leaderboard</Heading>
      <Box height="180px" style={{ maxWidth: "800px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={fakeLeaderboardData}
            layout="vertical"
            barCategoryGap={16}
            margin={{ top: 12, right: 160, left: 160, bottom: 12 }}
          >
            <ReferenceLine
              x={0}
              stroke={currentTheme === "dark" ? "#d3d3d3" : "#333333"}
              strokeDasharray="3 3"
            />
            <YAxis type="category" dataKey="user" hide />
            <XAxis type="number" hide />
            <Bar
              dataKey="performance"
              label={<UserLeaderboardLabel />}
              minPointSize={20}
              barSize={14}
            >
              {fakeLeaderboardData.map((user) => {
                const { id, performance } = user;
                return (
                  <Cell
                    key={`cell-${id}`}
                    fill={
                      performance >= 0
                        ? normalizeColor("assets", theme)
                        : normalizeColor("liabilities", theme)
                    }
                    value={user}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box>
        <Heading level={3}>Recent Activity</Heading>
        <RecentActivityMessage
          member={members[0]}
          activity={{
            type: "open",
            date: "Yesterday",
            holding: {
              name: "Tesla",
              url: "https://logos.m1finance.com/TSLA?size=144",
            },
            description: (
              <span>
                {members[0].name} has bought <b>TSLA</b> stock
              </span>
            ),
          }}
        />
        <RecentActivityMessage
          member={members[0]}
          activity={{
            type: "open",
            date: "Few days ago",
            holding: {
              name: "AMC",
              url: "https://logos.m1finance.com/AMC?size=144",
            },
            description: (
              <span>
                {members[0].name} has sold <b>AMC</b> 70 call option expiring
                Jan 20, 2023
              </span>
            ),
          }}
        />
        <RecentActivityMessage
          member={members[0]}
          activity={{
            type: "close",
            date: "Few days ago",
            holding: {
              name: "HOOD",
              url: "https://logos.m1finance.com/HOOD?size=144",
            },
            description: (
              <span>
                {members[0].name} has sold <b>HOOD</b> stock
              </span>
            ),
          }}
        />
      </Box>
    </>
  );
  const usePlaceholder =
    administrators.length === 1 && !players.length && !viewers.length;
  return (
    <Box pad="medium" flex overflow="auto">
      <Box flex={false}>
        <Box direction="row" align="start" justify="between">
          <Box direction="row" flex>
            <Box direction="row" align="center" gap="small" responsive={false}>
              <InvestmentGroupLogo
                investmentGroup={investmentGroup}
                size={investmentGroupIconSize}
              />
              <Box>
                <Heading level={2} margin="none" style={{ maxWidth: "144px" }}>
                  {investmentGroup.name}
                </Heading>
                <Text color="text-xweak">
                  {`${totalPlayers} players • ${totalViewers} viewers`}
                </Text>
              </Box>
            </Box>
            <Box
              direction="row"
              align="start"
              flex
              justify={size === "small" ? "end" : undefined}
              margin={{ top: "xsmall", left: "small" }}
            >
              {investmentGroup.links.website && (
                <Anchor
                  href={investmentGroup.links.website}
                  target="_blank"
                  icon={<Link size="18px" />}
                />
              )}
              {investmentGroup.links.email && (
                <Anchor
                  href={`mailto: ${investmentGroup.links.email}`}
                  target="_blank"
                  icon={<Mail size="18px" />}
                />
              )}
              {investmentGroup.links.slack && (
                <Anchor
                  href={investmentGroup.links.slack}
                  target="_blank"
                  icon={<img alt="slack logo" src="/slack.svg" width="18px" />}
                />
              )}
              {investmentGroup.links.discord && (
                <Anchor
                  href={investmentGroup.links.discord}
                  target="_blank"
                  icon={
                    <img alt="discord logo" src="/discord.svg" width="18px" />
                  }
                />
              )}
            </Box>
          </Box>
          {size !== "small" && (
            <Button icon={<Close />} onClick={onPageClose} />
          )}
        </Box>
        {investmentGroup.description && (
          <Paragraph
            color="text-xweak"
            size="large"
            margin={{ bottom: "none", top: "small" }}
          >
            {investmentGroup.description}
          </Paragraph>
        )}
        {usePlaceholder ? (
          <Box pad={{ horizontal: "small" }} align="start">
            <Heading level={2}>Say hello to your new group</Heading>
            <Paragraph color="text-xweak" margin="none">
              Let's invite a few friends to join your group now
            </Paragraph>
            <Button
              primary
              label="Invite Members"
              margin={{ top: "medium" }}
              onClick={onShowManageMembers}
            />
          </Box>
        ) : (
          <Tabs alignControls="start" margin={{ top: "medium" }}>
            <Tab title="Weekly">{fakeInstance}</Tab>
            <Tab title="Monthly">{fakeInstance}</Tab>
            <Tab title="Yearly">{fakeInstance}</Tab>
          </Tabs>
        )}
      </Box>
      {showManageMembers && (
        <ManageMembers
          investmentGroup={investmentGroup}
          onClose={onHideManageMembers}
        />
      )}
    </Box>
  );
};

export const InvestmentGroup = () => (
  <Box direction="row" fill>
    <Header />
    <Box flex>
      <InvestmentGroupBody />
    </Box>
  </Box>
);
