import React, { useContext } from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile, InvestmentGroups } from "../../components";
import { UserContext } from "../../index";
import { get } from "lodash";

export const InvestmentGroupsMobile = () => {
  const { user } = useContext(UserContext);
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Investment Groups
          </Text>
        }
      />
      <Box flex overflow="auto">
        <InvestmentGroups
          investmentGroups={get(user, "data.investmentGroups")}
        />
      </Box>
      <FooterMobile />
    </Box>
  );
};
