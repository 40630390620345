import React, { useCallback, useContext } from "react";
import { get } from "lodash";
import { Avatar, Box, Button, Heading, Text } from "grommet";
import { Logout, SettingsOption, ShareRounded } from "grommet-icons";

import { FooterMobile, HeaderMobile, UserPlan } from "../../components";
import { PageChangeContext, UserContext } from "../../";
import md5 from "md5";

export const UserMenuMobile = () => {
  const { user } = useContext(UserContext);
  const onPageChange = useContext(PageChangeContext);
  const onSettingsClick = useCallback(() => onPageChange("settings"), [
    onPageChange,
  ]);
  const onLogoutClick = useCallback(() => onPageChange("signIn"), [
    onPageChange,
  ]);
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            User Menu
          </Text>
        }
      />
      <Box
        flex
        overflow="auto"
        pad={{ horizontal: "medium", vertical: "small" }}
        responsive={false}
      >
        <Box flex pad={{ horizontal: "large" }}>
          <Button onClick={onSettingsClick}>
            <Box
              direction="row"
              align="center"
              justify="center"
              gap="medium"
              border="bottom"
              pad="large"
            >
              <SettingsOption color="menu-icon" />
              <Text size="large">Settings</Text>
            </Box>
          </Button>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            border="bottom"
            pad="large"
          >
            <ShareRounded color="menu-icon" />
            <Text size="large">View Profile</Text>
          </Box>
          <Button onClick={onLogoutClick}>
            <Box
              direction="row"
              align="center"
              justify="center"
              gap="medium"
              border="bottom"
              pad="large"
            >
              <Logout color="menu-icon" />
              <Text size="large">Sign out</Text>
            </Box>
          </Button>
        </Box>
        <Box direction="row" align="center" justify="between" pad="small">
          <Box direction="row" align="center" gap="medium">
            <Avatar
              size="36px"
              src={`//s.gravatar.com/avatar/${md5(get(user, "data.id"))}?s=80`}
            />
            <Heading level={3}>{get(user, "data.name", "")}</Heading>
          </Box>
          <UserPlan user={user} />
        </Box>
      </Box>
      <FooterMobile />
    </Box>
  );
};
