import React from "react";
import { Box, Stack, Text } from "grommet";

export const NotificationBadge = ({ icon, count = 0 }) => (
  <Stack anchor="top-right">
    <Box width="32px" align="center" justify="center">
      {icon}
    </Box>
    <Box
      align="center"
      background="tab-active"
      round
      justify="center"
      pad={{ horizontal: "4px" }}
    >
      <Text size="small" weight="bold">
        {count}
      </Text>
    </Box>
  </Stack>
);
