import React, { useContext } from "react";
import { TRTThemeContext } from "../";

export const Loading = ({ color }) => {
  const { currentTheme } = useContext(TRTThemeContext);
  return (
    <svg
      width="24px"
      height="24px"
      stroke={color || currentTheme === "dark" ? "#fff" : "#000"}
      viewBox="0 0 38 38"
    >
      <g
        transform="translate(1 1)"
        stroke-width="2"
        fill="none"
        fill-rule="evenodd"
      >
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  );
};
