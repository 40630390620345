import React, { useContext } from "react";
import { UserActivityLogo } from "./user-activity-logo";
import { Box, ResponsiveContext, Text } from "grommet";
import { TRTThemeContext } from "../../index";

export const RecentActivityMessage = ({ member, activity = {} }) => {
  const { currentTheme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const appBackground = currentTheme === "dark" ? "dark-1" : "light-1";
  return (
    <Box
      margin="small"
      responsive={false}
      direction="row"
      align="center"
      gap="small"
    >
      <UserActivityLogo
        user={member}
        holding={activity.holding}
        background={appBackground}
      />
      <Box align="start">
        <Box
          background={
            activity.type === "open" ? "status-ok" : "status-critical"
          }
          round="8px"
          pad={{ horizontal: "xsmall" }}
        >
          <Text color="white" weight="bold" size="small">
            {activity.type}
          </Text>
        </Box>
        <Text size={size !== "small" ? "large" : undefined}>
          {activity.description}
        </Text>
        <Text color="text-xweak" size={size === "small" ? "small" : undefined}>
          {activity.date}
        </Text>
      </Box>
    </Box>
  );
};
