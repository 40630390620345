import React from "react";
import { Box, Button } from "grommet";

export const OnboardingFooter = ({ onPreviousStep, onNextStep }) => {
  return (
    <Box
      tag="footer"
      align="center"
      pad="medium"
      border="top"
      responsive={false}
      justify="between"
      direction="row"
    >
      {onPreviousStep ? (
        <Button label="Back" secondary onClick={onPreviousStep} />
      ) : (
        <div />
      )}
      {onNextStep && <Button label="Next" primary onClick={onNextStep} />}
    </Box>
  );
};
