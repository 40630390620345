import React from "react";
import { Box, Button, Heading, Text } from "grommet";
import { Next } from "grommet-icons";

export const PlanCardButton = ({
  selected,
  planBackground,
  planType,
  planPrice,
  planFeatures = [],
  planePriceDescription,
  onSelect,
  renewsIn,
}) => {
  return (
    <Box margin="small" responsive={false} flex={false}>
      <Box
        round="8px"
        border={{ side: "all", color: planBackground }}
        width="medium"
        responsive={false}
      >
        <Box
          gap="small"
          background={planBackground}
          round={{ size: "6px", corner: "top" }}
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: "medium", vertical: "small" }}
          responsive={false}
        >
          <Heading level={3} margin="none" responsive={false}>
            <b>{planType}</b>
          </Heading>
          <Box align="end">
            <Text size="large" weight="bold">
              {planPrice}
            </Text>
            <Text size="small">{planePriceDescription}</Text>
          </Box>
        </Box>
        <Box flex pad={{ vertical: "medium" }}>
          <Heading alignSelf="center" level={4} margin="none">
            Features
          </Heading>
          <Box
            flex
            align="start"
            pad={{ vertical: "small", horizontal: "medium" }}
            gap="medium"
            responsive={false}
          >
            {(planFeatures || []).map(({ title, description }) => (
              <Box
                key={title}
                direction="row"
                align="center"
                justify="center"
                gap="xsmall"
              >
                <Next size="small" color="selected" />
                <Box align="start">
                  <Text textAlign="center">{title}</Text>
                  <Text size="small" color="text-xweak">
                    {description}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          border="top"
          align="center"
          pad="small"
          justify="between"
          direction="row"
          responsive={false}
          height="48px"
        >
          {renewsIn ? (
            <Text>
              <i>Renews in {renewsIn} days</i>
            </Text>
          ) : (
            <Text>
              <i>{selected ? "Selected" : ""}</i>
            </Text>
          )}
          {!selected && (
            <Button onClick={onSelect} secondary size="small" label="Choose" />
          )}
        </Box>
      </Box>
    </Box>
  );
};
