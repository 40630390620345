import { useCallback, useContext, useMemo, useRef } from "react";
import { usePlaidLink } from "react-plaid-link";
import { PageChangeContext, QueryStringContext } from "..";
import { getFirebaseHost } from "../utils";
import { usePlaidToken } from ".";
import { ResponsiveContext } from "grommet";

export const useAddPlaidInstitution = ({ user = {}, updateUser } = {}) => {
  const replaceInstitutionId = useRef(undefined);
  const size = useContext(ResponsiveContext);
  const { setQuery } = useContext(QueryStringContext);
  const onPageChange = useContext(PageChangeContext);
  const token = usePlaidToken(user) || "";

  const onSuccess = useCallback(
    async (public_token, metadata) => {
      const userResponse = await fetch(
        `//${getFirebaseHost()}/api/institutions/${user.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            metadata,
            replaceInstitutionId: replaceInstitutionId.current,
          }),
        }
      );

      const newUser = await userResponse.json();

      updateUser(newUser);

      setQuery({
        activePanel: 1,
        activeInstitution: metadata.institution.institution_id,
      });

      if (size === "small") {
        onPageChange("institutions");
      } else {
        onPageChange("dashboard");
      }

      replaceInstitutionId.current = undefined;
    },
    [updateUser, user.id, setQuery, onPageChange, size]
  );

  const { open, ready } = usePlaidLink({
    onSuccess,
    token,
  });

  const onOpenHandler = useCallback(
    (institutionId) => {
      replaceInstitutionId.current = institutionId;
      open();
    },
    [open]
  );

  return useMemo(() => ({ open: onOpenHandler, ready }), [
    onOpenHandler,
    ready,
  ]);
};
