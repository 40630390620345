import React, { useCallback, useContext, useState } from "react";
import Confetti from "react-confetti";
import { Box, Button, Heading, ResponsiveContext } from "grommet";

import { PageChangeContext, UserContext } from "../../";

import { OnboardingLayout, OnboardingHeading } from "./";

export const OnboardingAddInstitution = () => {
  const {
    plaid: { open, ready },
  } = useContext(UserContext);
  const [showConfetti, setShowConfetti] = useState(true);
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);

  const onGoToDashboard = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);

  const onRemoveConfetti = useCallback(() => setShowConfetti(false), []);

  const onAddInstitution = useCallback(() => {
    if (ready) {
      open();
    } else {
      console.error("Could not initialize plaid");
      // TODO: show error
    }
  }, [open, ready]);

  return (
    <>
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={1000}
          gravity={0.2}
          onConfettiComplete={onRemoveConfetti}
        />
      )}
      <OnboardingLayout>
        <OnboardingHeading
          heading="Congratulations"
          description="You have made it! Would like to add your first financial institution now?"
        />
        <Box
          margin={{ vertical: "medium" }}
          gap="medium"
          responsive={false}
          align="center"
        >
          <Button
            size={size !== "small" ? "large" : undefined}
            primary
            label="Sure, add one now"
            onClick={onAddInstitution}
          />
          <Heading level={3} margin="none">
            or
          </Heading>
          <Button
            size={size !== "small" ? "large" : undefined}
            label="No, I will add later"
            onClick={onGoToDashboard}
          />
        </Box>
      </OnboardingLayout>
    </>
  );
};
