import React, { useContext } from "react";
import { Checkmark } from "grommet-icons";
import { Box, ResponsiveContext, Text } from "grommet";

export const GoalCheckmark = ({ children }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      width={size === "large" ? undefined : "280px"}
    >
      <Checkmark
        color="neutral-3"
        size={size === "small" ? "small" : undefined}
      />
      <Text size={size} color="text-xweak" weight="bold">
        {children}
      </Text>
    </Box>
  );
};
