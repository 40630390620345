import React from "react";
import { Box, Text, TextInput } from "grommet";
import { StatusCritical } from "grommet-icons";

import { ConfirmationModal } from "./";

export const CancelSubscription = ({ onCancel, onConfirm }) => {
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to cancel?`}
      actionLabel="Confirm"
    >
      <Box gap="medium" responsive={false}>
        <Text color="text-weak">
          You cannot revert this action and your data will be lost, no refund
          will be processed.
        </Text>
        <Text color="text-weak">To confirm, type your email:</Text>
        <TextInput placeholder="Your email" />
      </Box>
    </ConfirmationModal>
  );
};
