import React, { useContext, useRef, useState, useCallback } from "react";
import { get } from "lodash";
import { LineChart, Line, XAxis, Tooltip } from "recharts";
import { Box, ResponsiveContext, Text } from "grommet";

import { ChartLabelPerformance, ChartTooltip, ChartXAxisLabel } from "./";

export const TrtChart = ({ data: { data, summary, label, number } }) => {
  const size = useContext(ResponsiveContext);
  const [activeDataIndex, setActiveDataIndex] = useState(null);
  const containerRef = useRef(null);

  const activeEntry =
    activeDataIndex !== null ? data[activeDataIndex] : summary;

  const onActiveDot = useCallback(
    ({ index }) =>
      index !== activeDataIndex &&
      setTimeout(() => setActiveDataIndex(index), 0),
    [activeDataIndex, setActiveDataIndex]
  );

  const onResetActiveDataIndex = useCallback(() => setActiveDataIndex(null), [
    setActiveDataIndex,
  ]);

  let chartWidth = 420;
  if (size === "large") {
    chartWidth = 800;
  } else if (size === "small") {
    chartWidth = 375;
  }
  const fontSize = size !== "large" ? "small" : undefined;

  return (
    <Box fill align="center" ref={containerRef}>
      <Box align="center">
        <Box direction="row" pad={{ top: "medium" }} gap="small">
          <Box direction="row" align="center" gap="xsmall">
            <Box
              round="large"
              background="graph-2"
              width="legend-circle"
              height="legend-circle"
            />
            <Text size={fontSize}>Your Net Worth</Text>
          </Box>
          <Box direction="row" align="center" gap="xsmall">
            <Box
              round="large"
              background="graph-4"
              width="legend-circle"
              height="legend-circle"
            />
            <Text size={fontSize}>Your Investments</Text>
          </Box>
          <Box direction="row" align="center" gap="xsmall">
            <Box
              round="large"
              background="graph-1"
              width="legend-circle"
              height="legend-circle"
            />
            <Text size={fontSize}>S&P 500</Text>
          </Box>
        </Box>
      </Box>
      <Box
        width={`${chartWidth}px`}
        pad={{ horizontal: "medium", vertical: "small" }}
        direction="row"
        justify="between"
        align="center"
        responsive={false}
      >
        <Box gap="xsmall">
          <Text size={size !== "large" ? "small" : undefined} weight="bold">
            {activeEntry.name || (
              <span>
                {label.toUpperCase()} {number}
              </span>
            )}
          </Text>
          <Box direction="row" align="center" gap="xsmall">
            <ChartLabelPerformance
              value={get(activeEntry, "netWorth.value")}
              percent={get(activeEntry, "netWorth.percent")}
              background="graph-2"
            />
            <ChartLabelPerformance
              value={get(activeEntry, "investments.value")}
              percent={get(activeEntry, "investments.percent")}
              background="graph-4"
            />
            <ChartLabelPerformance
              value={get(activeEntry, "sp500.value")}
              percent={get(activeEntry, "sp500.percent")}
              background="graph-1"
            />
          </Box>
        </Box>
      </Box>
      <LineChart
        width={chartWidth}
        height={chartWidth / 2.8}
        data={data}
        margin={{ top: 5, right: 15, bottom: 5, left: 5 }}
        onMouseLeave={onResetActiveDataIndex}
      >
        <XAxis
          dataKey="name"
          interval={0}
          axisLine={false}
          tickLine={false}
          padding={{ left: 25, right: 25 }}
          tick={<ChartXAxisLabel />}
          height={22}
        />
        <Tooltip
          isAnimationActive={false}
          content={<ChartTooltip />}
          offset={4}
          position={{ y: 0 }}
        />
        <Line
          type="monotone"
          dataKey="netWorth.percent"
          stroke="#006d90"
          strokeWidth={2}
          animationDuration={900}
          dot={false}
          activeDot={onActiveDot}
        />
        <Line
          type="monotone"
          dataKey="investments.percent"
          stroke="#e67f83"
          strokeWidth={2}
          animationDuration={800}
          dot={false}
          activeDot={false}
        />
        <Line
          type="monotone"
          dataKey="sp500.percent"
          stroke="#ffa600"
          strokeWidth={2}
          animationDuration={700}
          dot={false}
          activeDot={false}
        />
      </LineChart>
    </Box>
  );
};
