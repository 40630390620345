import React, { useCallback, useContext, useState } from "react";
import { get, set } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  ResponsiveContext,
  TextInput,
  Text,
  TextArea,
  Heading,
} from "grommet";
import { Group, Link, Mail } from "grommet-icons";

import {
  FeedbackContext,
  Header,
  InvestmentGroupLogo,
  PageHeader,
} from "../components";
import { getFirebaseHost } from "../utils";
import { PageChangeContext, QueryStringContext, UserContext } from "../";

const defaultInvestmentGroup = {
  id: uuidv4(),
  name: "",
  description: "",
  shareSettings: {
    public: false,
    url: `https://app.theretirementtracker.com/join/${uuidv4()}`,
  },
  links: {
    discord: "",
    slack: "",
    email: "",
    website: "",
  },
};

const validateInvestmentGroup = (investmentGroup) => {
  if (!investmentGroup.name) {
    return "You need to enter a group name";
  }
  return undefined;
};

export const InvestmentGroupLogoPlaceholder = () => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      flex={false}
      background="light-2"
      align="center"
      justify="center"
      width={size === "small" ? "68px" : "96px"}
      height={size === "small" ? "68px" : "96px"}
      round="full"
    >
      <Group color="neutral-3" size={size === "small" ? "48px" : "52px"} />
    </Box>
  );
};

export const AddInvestmentGroupBody = () => {
  const [investmentGroup, setInvestmentGroup] = useState(
    defaultInvestmentGroup
  );
  const {
    user: { data: user },
    updateUser,
  } = useContext(UserContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const { query, setQuery } = useContext(QueryStringContext);

  const onUpdateInvestmentGroup = useCallback(
    (field) => ({ target: { value } }) => {
      const newInvestmentGroup = { ...investmentGroup };
      set(newInvestmentGroup, field, value);
      setInvestmentGroup(newInvestmentGroup);
    },
    [investmentGroup]
  );

  const onInvestmentGroupSave = useCallback(async () => {
    const error = validateInvestmentGroup(investmentGroup);

    if (error) {
      sendFeedback({ message: error, type: "error" });
      return;
    }

    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/investment-groups/${user.id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          investmentGroup: {
            ...investmentGroup,
            members: [
              {
                id: user.id,
                name: user.name,
                email: user.email,
                role: "administrator",
                pending: false,
              },
            ],
          },
        }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    setQuery({
      ...query,
      activeInvestmentGroup: investmentGroup.id,
      investmentGroupId: undefined,
    });
    onPageChange("investmentGroup");
  }, [
    investmentGroup,
    updateUser,
    user,
    onPageChange,
    sendFeedback,
    setQuery,
    query,
  ]);

  const linkIconSize = size === "small" ? "24px" : "36px";

  return (
    <>
      <Box flex overflow="auto" align="start" pad="medium" responsive={false}>
        <Box flex={false} fill>
          <Box direction="row" gap="medium" responsive={false} width="large">
            <Box
              align="center"
              gap="small"
              width={size === "small" ? "120px" : "180px"}
            >
              {investmentGroup.name ? (
                <InvestmentGroupLogo
                  investmentGroup={investmentGroup}
                  size={size === "small" ? "68px" : "96px"}
                />
              ) : (
                <InvestmentGroupLogoPlaceholder />
              )}
              <Text size="small" color="text-xweak" textAlign="center">
                Click anywhere on the image to change your avatar
              </Text>
            </Box>
            <Box fill="horizontal" height="150px" gap="small">
              <TextInput
                placeholder="Group name*"
                value={get(investmentGroup, "name")}
                onChange={onUpdateInvestmentGroup("name")}
              />
              <TextArea
                placeholder="Group description"
                resize={false}
                fill
                value={get(investmentGroup, "description")}
                onChange={onUpdateInvestmentGroup("description")}
              />
            </Box>
          </Box>
          <Heading level={3} margin={{ left: "medium", vertical: "medium" }}>
            Links
          </Heading>
          <Box pad={{ left: "large", top: "small" }} width="large" gap="24px">
            <Box direction="row" align="center" gap="medium">
              <Link size={linkIconSize} />
              <TextInput
                placeholder="group website url"
                value={get(investmentGroup, "links.website")}
                onChange={onUpdateInvestmentGroup("links.website")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <Mail size={linkIconSize} />
              <TextInput
                placeholder="group email distribution"
                value={get(investmentGroup, "links.email")}
                onChange={onUpdateInvestmentGroup("links.email")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <img alt="slack logo" src="/slack.svg" width={linkIconSize} />
              <TextInput
                placeholder="group slack url"
                value={get(investmentGroup, "links.slack")}
                onChange={onUpdateInvestmentGroup("links.slack")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <img alt="slack logo" src="/discord.svg" width={linkIconSize} />
              <TextInput
                placeholder="group discord url"
                value={get(investmentGroup, "links.discord")}
                onChange={onUpdateInvestmentGroup("links.discord")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button
          secondary
          label="Cancel"
          onClick={() => onPageChange("dashboard")}
        />
        <Button label="Save" primary onClick={onInvestmentGroupSave} />
      </Box>
    </>
  );
};

export const AddInvestmentGroup = () => {
  const onPageChange = useContext(PageChangeContext);

  const onPageClose = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);

  return (
    <Box direction="row" fill>
      <Header />
      <Box flex>
        <PageHeader
          title="Add Investment Group"
          subTitle="Create a new investment group and add new members"
          onClose={onPageClose}
        />
        <AddInvestmentGroupBody />
      </Box>
    </Box>
  );
};
