import React, { useContext } from "react";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";

export const OnboardingHeading = ({ heading, description }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box align="center" gap="small">
      <Heading level={2} margin="none">
        <b>{heading}</b>
      </Heading>
      <Paragraph
        size={size !== "small" ? "large" : undefined}
        textAlign="center"
        color="text-weak"
        margin="none"
      >
        {description}
      </Paragraph>
    </Box>
  );
};
