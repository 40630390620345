import { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { useFetch } from ".";
import { getFirebaseHost } from "../utils";
import { useAddPlaidInstitution } from "./useAddPlaidInstitution";

export const useUser = () => {
  const [user, setUser] = useState({});

  const fetchedUser = useFetch(
    `//${getFirebaseHost()}/api/users/${
      process.env.REACT_APP_USER || "alansouzati@gmail.com"
    }`
  );

  useEffect(() => setUser(fetchedUser), [fetchedUser, setUser]);

  const updateUser = useCallback(
    (newUser) => setUser({ ...user, data: newUser }),
    [setUser, user]
  );

  const { open, ready } = useAddPlaidInstitution({
    user: user.data || {},
    updateUser,
  });

  const hasInstitutionsProcessing = get(
    user,
    "data.institutions",
    []
  ).some(({ processing }) => Boolean(processing));

  useEffect(() => {
    let fetchUserInterval = null;
    if (hasInstitutionsProcessing) {
      fetchUserInterval = setInterval(() => fetchedUser.doFetch(), 1000);
    }
    return () => clearInterval(fetchUserInterval);
  }, [hasInstitutionsProcessing, fetchedUser]);

  return useMemo(
    () => ({
      user,
      updateUser,
      plaid: {
        open,
        ready,
      },
    }),
    [user, updateUser, open, ready]
  );
};
