import React, { useCallback, useState } from "react";
import { Box, Text } from "grommet";

export const InvestmentGroupLogo = ({ investmentGroup, size = "36px" }) => {
  const [showFallback, setShowFallback] = useState(false);
  const onShowFallback = useCallback(() => setShowFallback(true), []);
  if (showFallback || !investmentGroup.url) {
    return (
      <Box
        width={size}
        height={size}
        round="full"
        background="light-2"
        align="center"
        justify="center"
        flex={false}
      >
        <Text color="neutral-3" size="large">
          {investmentGroup.name.charAt(0).toUpperCase()}
        </Text>
      </Box>
    );
  }
  return (
    <Box
      flex={false}
      width={size}
      height={size}
      align="center"
      justify="center"
      round="full"
      style={{ overflow: "hidden" }}
    >
      <img
        width={size}
        alt={`${investmentGroup.name} logo`}
        src={investmentGroup.url}
        onError={onShowFallback}
        style={{ objectPosition: "center" }}
      />
    </Box>
  );
};
