import React, { useCallback, useContext } from "react";
import { Box, Heading, Paragraph, RadioButton } from "grommet";

import { TRTThemeContext } from "../";

export const ThemeChooser = () => {
  const { currentTheme, onThemeChange: changeTheme } = useContext(
    TRTThemeContext
  );
  const onThemeChange = useCallback(
    (newTheme) => () => {
      changeTheme(newTheme);
      // auto-persist theme during onboarding...
      localStorage.setItem("trt-theme", newTheme);
    },
    [changeTheme]
  );
  return (
    <Box align="center">
      <Heading level={2} margin="none">
        Theme
      </Heading>
      <Paragraph
        textAlign="center"
        color="text-weak"
        margin={{ top: "xsmall" }}
      >
        Light or dark? We have got you covered
      </Paragraph>
      <Box
        margin={{ top: "small" }}
        direction="row"
        align="center"
        gap="medium"
      >
        <RadioButton
          checked={currentTheme === "light"}
          label="Light"
          onChange={onThemeChange("light")}
        />

        <RadioButton
          checked={currentTheme === "dark"}
          label="Dark"
          onChange={onThemeChange("dark")}
        />
      </Box>
    </Box>
  );
};
