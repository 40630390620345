import React, { useCallback, useContext, useEffect, useState } from "react";

import { StepChangeContext } from "../../pages";

import { Subscription } from "../";

import { OnboardingHeading, OnboardingLayout } from "./";

export const ChoosePlan = () => {
  const { onStepChange } = useContext(StepChangeContext);
  const [currentPlan, setCurrentPlan] = useState("basic");
  const onPreviousStep = useCallback(() => onStepChange("restimate"), [
    onStepChange,
  ]);
  const onNextStep = useCallback(() => {
    localStorage.setItem("trt-plan", currentPlan);
    if (currentPlan === "basic") {
      onStepChange("addInstitution");
    } else {
      onStepChange("addPayment");
    }
  }, [onStepChange, currentPlan]);

  useEffect(() => {
    localStorage.removeItem("trt-plan");
    localStorage.removeItem("trt-plan-frequency");
    localStorage.removeItem("trt-theme");
  }, []);

  const onPlanSelect = useCallback((newPlan) => setCurrentPlan(newPlan), [
    setCurrentPlan,
  ]);
  return (
    <OnboardingLayout
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      step="3 of 3 steps"
    >
      <OnboardingHeading
        heading="Choose a Plan"
        description="Try any plan free for 7 days, cancel anytime"
      />
      <Subscription onChange={onPlanSelect} />
    </OnboardingLayout>
  );
};
