import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import numeral from "numeral";
import {
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Text,
} from "grommet";

import {
  DashboardCard,
  Header,
  InstitutionHoldingsLogo,
  InstitutionLogo,
  Notifications,
  PercentDisplay,
  RestimateFullPlan,
  TrtChart,
  UserAvatar,
} from "../components";
import { PageChangeContext, UserContext } from "../index";
import { get } from "lodash";
import {
  Atm,
  CreditCard,
  Currency,
  Dislike,
  Favorite,
  LinkUp,
  Optimize,
} from "grommet-icons";

const chartData = {
  week: {
    label: "Week",
    title: "Week 5",
    number: 5,
    total: 520,
    goal: 4500,
    current: 5000,
    summary: {
      netWorth: {
        percent: 1.35,
        value: 5000,
      },
      investments: {
        percent: 1.0,
        value: 3000,
      },
      sp500: {
        percent: 1.1,
        value: 340,
      },
    },
    data: [
      {
        name: "Mon",
        netWorth: {
          percent: 1.5,
          value: 400.17,
        },
        investments: {
          percent: 2.0,
          value: 500.45,
        },
        sp500: {
          percent: 1.4,
          value: 330,
        },
        target: {
          percent: 1.004,
        },
      },
      {
        name: "Tue",
        netWorth: {
          percent: -1.1,
          value: -600,
        },
        investments: {
          percent: -2.19,
          value: -600,
        },
        sp500: {
          percent: 0.4,
          value: 332,
        },
        target: {
          percent: 1.004,
        },
      },
      {
        name: "Wed",
        netWorth: {
          percent: 2.0,
          value: 1000,
        },
        investments: {
          percent: 10.1,
          value: 4000,
        },
        sp500: {
          percent: -1.15,
          value: 331.44,
        },
        target: {
          percent: 1.004,
        },
      },
      {
        name: "Thrus",
        netWorth: {
          percent: 1.0,
          value: 500,
        },
        investments: {
          percent: 0.6,
          value: 200,
        },
        sp500: {
          percent: 0.8,
          value: 334,
        },
        target: {
          percent: 1.004,
        },
      },
      {
        name: "Fri",
        netWorth: {
          percent: 0.1,
          value: 200,
        },
        investments: {
          percent: -1.19,
          value: -600,
        },
        sp500: {
          percent: 1.8,
          value: 340,
        },
        target: {
          percent: 1.004,
        },
      },
    ],
  },
  month: {
    title: "Nov",
    label: "Month",
    number: 2,
    total: 120,
    goal: 54000,
    current: 21534,
    summary: {
      netWorth: {
        percent: -1.35,
        value: 5000,
      },
      investments: {
        percent: 10,
        value: 3000,
      },
      sp500: {
        percent: 7.88,
        value: 340,
      },
    },
    data: [
      {
        name: "Nov 1st",
        netWorth: {
          percent: 0.5,
          value: 200.17,
        },
        investments: {
          percent: 1.43,
          value: 400.45,
        },
        sp500: {
          percent: -0.4,
          value: 315,
        },
        target: {
          percent: 1.005,
        },
      },
      {
        name: "Nov 7th",
        netWorth: {
          percent: -3.1,
          value: -9000,
        },
        investments: {
          percent: -5.19,
          value: -2000,
        },
        sp500: {
          percent: -4.43,
          value: 298.54,
        },
        target: {
          percent: 1.005,
        },
      },
      {
        name: "Nov 15th",
        netWorth: {
          percent: 2.0,
          value: 1000,
        },
        investments: {
          percent: 10.1,
          value: 4000,
        },
        sp500: {
          percent: 15.15,
          value: 339.44,
        },
        target: {
          percent: 1.005,
        },
      },
      {
        name: "Nov 21st",
        netWorth: undefined,
        investments: undefined,
        sp500: undefined,
        target: {
          percent: 1.005,
        },
      },
      {
        name: "Nov 30th",
        netWorth: undefined,
        investments: undefined,
        sp500: undefined,
        target: {
          percent: 1.005,
        },
      },
    ],
  },
};

const spyComparisonByDay = {
  monday: {
    label: "Monday",
    spy: 1.59,
    you: 2.33,
  },
  tuesday: {
    label: "Tuesday",
    spy: 4.59,
    you: 6.13,
  },
  wednesday: {
    label: "Wednesday",
    spy: 1.59,
    you: -0.13,
  },
  thursday: {
    label: "Thursday",
    spy: -2.59,
    you: -1.13,
  },
  friday: {
    label: "Friday",
    spy: 1.19,
    you: 2.11,
  },
};

const SPYComparisonCard = () => {
  const activePeriodTimeoutRef = useRef();
  const [activePeriod, setActivePeriod] = useState();

  const { user } = useContext(UserContext);
  const onResetActivePeriod = useCallback(() => {
    activePeriodTimeoutRef.current = setTimeout(
      () => setActivePeriod(undefined),
      300
    );
  }, []);
  const onActivePeriod = useCallback(
    (day) => () => {
      clearTimeout(activePeriodTimeoutRef.current);
      setActivePeriod(spyComparisonByDay[day]);
    },
    []
  );
  let spyActivePeriodColor = undefined;
  let youActivePeriodColor = undefined;
  if (activePeriod) {
    if (activePeriod.spy >= 0) {
      spyActivePeriodColor = "status-ok";
    } else {
      spyActivePeriodColor = "status-critical";
    }
    if (activePeriod.you >= 0) {
      youActivePeriodColor = "status-ok";
    } else {
      youActivePeriodColor = "status-critical";
    }
  }
  return (
    <DashboardCard
      title="SPY Comparison"
      description="How you are doing comparing to the market"
      highlight={
        <Box align="end" margin={{ right: "small" }} responsive={false}>
          <Text color="status-critical">
            -4.35%{" "}
            <b>
              <Text color="text-weak" size="small" margin={{ left: "xsmall" }}>
                SPY
              </Text>
            </b>
          </Text>
          <Text color="status-critical">
            -1.35%{" "}
            <b>
              <Text color="text-weak" size="small" margin={{ left: "xsmall" }}>
                YOU
              </Text>
            </b>
          </Text>
        </Box>
      }
    >
      <Box align="center" justify="center" height="72px" border="bottom">
        <Box direction="row" gap="large">
          <Box direction="row" align="center" justify="center" gap="small">
            <InstitutionLogo
              size="32px"
              institution={{
                name: "SPY",
                url: "https://logos.m1finance.com/SPY?size=144",
              }}
            />
            <Heading margin="none" level={3} responsive={false}>
              <b>SPY</b>
            </Heading>
          </Box>

          <Box
            direction="row"
            gap="xsmall"
            align="center"
            justify="center"
            width="80px"
          >
            <Heading
              margin="none"
              level={activePeriod ? 4 : 3}
              color={spyActivePeriodColor || "text-weak"}
              responsive={false}
            >
              {activePeriod
                ? `${numeral(activePeriod.spy).format("+0,0.00")}%`
                : 1}
            </Heading>
            <Heading
              margin="none"
              level={4}
              color="text-weak"
              responsive={false}
            >
              x
            </Heading>
            <Heading
              margin="none"
              level={activePeriod ? 4 : 3}
              color={youActivePeriodColor || "text-weak"}
              responsive={false}
            >
              {activePeriod
                ? `${numeral(activePeriod.you).format("+0,0.00")}%`
                : 4}
            </Heading>
          </Box>
          <Box direction="row" align="center" justify="center" gap="small">
            <Heading margin="none" level={3} responsive={false}>
              <b>YOU</b>
            </Heading>
            <UserAvatar user={user} showName={false} size="32px" />
          </Box>
        </Box>
        {activePeriod ? (
          <Text color="text-weak" size="small" weight="bold">
            {activePeriod.label}
          </Text>
        ) : (
          <Text color="text-xweak" size="small">
            <b>days</b> this week
          </Text>
        )}
      </Box>
      <Box direction="row" align="center" justify="center" flex gap="small">
        <Box
          elevation="xsmall"
          background="status-ok"
          round="full"
          width="36px"
          height="36px"
          align="center"
          justify="center"
          onMouseEnter={onActivePeriod("monday")}
          onMouseLeave={onResetActivePeriod}
        >
          <Text color="white">
            <b>M</b>
          </Text>
        </Box>
        <Box
          elevation="xsmall"
          background="status-ok"
          round="full"
          width="36px"
          height="36px"
          align="center"
          justify="center"
          onMouseEnter={onActivePeriod("tuesday")}
          onMouseLeave={onResetActivePeriod}
        >
          <Text color="white">
            <b>T</b>
          </Text>
        </Box>
        <Box
          elevation="xsmall"
          background="status-critical"
          round="full"
          width="36px"
          height="36px"
          align="center"
          justify="center"
          onMouseEnter={onActivePeriod("wednesday")}
          onMouseLeave={onResetActivePeriod}
        >
          <Text color="white">
            <b>W</b>
          </Text>
        </Box>
        <Box
          elevation="xsmall"
          background="status-ok"
          round="full"
          width="36px"
          height="36px"
          align="center"
          justify="center"
          onMouseEnter={onActivePeriod("thursday")}
          onMouseLeave={onResetActivePeriod}
        >
          <Text color="white">
            <b>T</b>
          </Text>
        </Box>
        <Box
          elevation="xsmall"
          background="status-ok"
          round="full"
          width="36px"
          height="36px"
          align="center"
          justify="center"
          onMouseEnter={onActivePeriod("friday")}
          onMouseLeave={onResetActivePeriod}
        >
          <Text color="white">
            <b>F</b>
          </Text>
        </Box>
      </Box>
      <Box
        border="top"
        pad="medium"
        direction="row"
        align="center"
        justify="between"
        height="70px"
        responsive={false}
      >
        <Box gap="xsmall">
          <Box direction="row" align="center" gap="small">
            <Box
              width="12px"
              height="12px"
              round="full"
              background="status-ok"
            />
            <Box
              width="12px"
              height="12px"
              round="full"
              background="status-ok"
            />
            <Box
              width="12px"
              height="12px"
              round="full"
              background="status-ok"
            />
            <Box
              width="12px"
              height="12px"
              round="full"
              background="status-ok"
            />
            <Box
              width="12px"
              height="12px"
              round="full"
              background="status-critical"
            />
          </Box>
          <Text size="small">Last weeks</Text>
        </Box>
      </Box>
    </DashboardCard>
  );
};

const OverviewTab = () => {
  const size = useContext(ResponsiveContext);
  const [showFullPlan, setShowFullPlan] = useState(false);
  const onCloseFullPlan = useCallback(() => setShowFullPlan(false), []);
  const onShowFullPlan = useCallback(() => setShowFullPlan(true), []);
  return (
    <>
      {showFullPlan && <RestimateFullPlan onClose={onCloseFullPlan} />}
      <Box
        direction="row"
        wrap
        flex
        align="center"
        justify="center"
        responsive={false}
        pad={{ top: "small", bottom: "medium" }}
      >
        <DashboardCard
          title="Net Worth"
          description="How your net worth changed this week"
          highlight={
            <Box align="end" margin={{ right: "small" }} responsive={false}>
              <Text color="text-weak" weight="bold">
                $600,953.00
              </Text>
              <Text
                color="status-ok"
                size={size !== "large" ? "small" : undefined}
              >
                $6,059.00 (+1.35%)
              </Text>
            </Box>
          }
        >
          <Box flex>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      From
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      To
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      Change
                    </Heading>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Box align="center">
                      <Currency size="24px" color="assets" />
                      <Text size="small">Cash</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$5.04k</Text>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$5.04k</Text>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Text color="text-weak">$0.00</Text>
                      <Text color="text-weak" size="small">
                        0.00%
                      </Text>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Box align="center">
                      <Optimize size="24px" color="assets" />
                      <Text size="small">Investments</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$400k</Text>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$350k</Text>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Text color="text-weak">-$50k</Text>
                      <Text size="small" color="status-critical">
                        -12.00%
                      </Text>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Box align="center">
                      <CreditCard size="24px" color="liabilities" />
                      <Text size="small">Credit Cards</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$1.1k</Text>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$0.00</Text>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Text color="text-weak">$1.1k</Text>
                      <Text size="small" color="status-ok">
                        -100.00%
                      </Text>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Box align="center">
                      <Atm size="24px" color="liabilities" />
                      <Text size="small">Loans</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$1M</Text>
                  </TableCell>
                  <TableCell>
                    <Text color="text-weak">$950k</Text>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Text color="text-weak">$50k</Text>
                      <Text size="small" color="status-ok">
                        -1.50%
                      </Text>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DashboardCard>
        <DashboardCard
          title="Restimate"
          description="Weekly performance compared to your goals"
          highlight={
            <Box align="end" margin={{ right: "small" }} responsive={false}>
              <Text weight="bold" color="status-ok">
                $6,059.00
              </Text>
              <Text
                color="text-weak"
                size={size !== "large" ? "small" : undefined}
              >
                <b>$3,059.00</b> goal
              </Text>
            </Box>
          }
        >
          <Box flex justify="center" direction="row" wrap>
            <Box
              align="center"
              justify="center"
              basis="40%"
              gap="xsmall"
              responsive={false}
            >
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                <Box
                  background="status-ok"
                  round="large"
                  pad="small"
                  responsive={false}
                >
                  <LinkUp size="small" color="white" />
                </Box>
                <Heading
                  level={3}
                  margin="none"
                  color="status-ok"
                  responsive={false}
                >
                  <b>$6k</b>
                </Heading>
              </Box>
              <Text color="text-xweak" size="small">
                this <b>week</b>
              </Text>
            </Box>
            <Box
              align="center"
              justify="center"
              basis="40%"
              gap="xsmall"
              responsive={false}
            >
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                <Box
                  direction="row"
                  align="center"
                  gap="xsmall"
                  responsive={false}
                >
                  <Box
                    background="status-ok"
                    round="large"
                    pad="small"
                    responsive={false}
                  >
                    <LinkUp size="small" color="white" />
                  </Box>
                  <Heading
                    level={3}
                    margin="none"
                    color="status-ok"
                    responsive={false}
                  >
                    <b>$3.5k</b>
                  </Heading>
                </Box>
              </Box>
              <Text color="text-weak" size="small">
                <b>weekly</b> avg
              </Text>
            </Box>
            <Box
              align="center"
              justify="center"
              basis="40%"
              gap="xsmall"
              responsive={false}
            >
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                <Box
                  direction="row"
                  align="center"
                  gap="xsmall"
                  responsive={false}
                >
                  <Box
                    background="status-ok"
                    round="large"
                    pad="small"
                    responsive={false}
                  >
                    <Favorite size="small" color="white" />
                  </Box>
                  <Heading
                    level={3}
                    margin="none"
                    color="status-ok"
                    responsive={false}
                  >
                    <b>$6k</b>
                  </Heading>
                </Box>
              </Box>
              <Text color="text-weak" size="small">
                best <b>week</b>
              </Text>
            </Box>
            <Box
              align="center"
              justify="center"
              basis="40%"
              gap="xsmall"
              responsive={false}
            >
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                <Box
                  direction="row"
                  align="center"
                  gap="xsmall"
                  responsive={false}
                >
                  <Box
                    background="status-critical"
                    round="large"
                    pad="small"
                    responsive={false}
                  >
                    <Dislike size="small" color="white" />
                  </Box>
                  <Heading
                    level={3}
                    margin="none"
                    color="status-critical"
                    responsive={false}
                  >
                    <b>-$1k</b>
                  </Heading>
                </Box>
              </Box>
              <Text color="text-weak" size="small">
                worst <b>week</b>
              </Text>
            </Box>
          </Box>
          <Box
            border="top"
            pad="medium"
            direction="row"
            align="center"
            justify="between"
            height="70px"
            responsive={false}
          >
            <Box gap="xsmall">
              <Box direction="row" align="center" gap="small">
                <Box
                  width="12px"
                  height="12px"
                  round="full"
                  background="status-ok"
                />
                <Box
                  width="12px"
                  height="12px"
                  round="full"
                  background="status-ok"
                />
                <Box
                  width="12px"
                  height="12px"
                  round="full"
                  background="status-critical"
                />
                <Box
                  width="12px"
                  height="12px"
                  round="full"
                  background="status-ok"
                />
                <Box
                  width="12px"
                  height="12px"
                  round="full"
                  background="status-critical"
                />
              </Box>
              <Text size="small">Last weeks</Text>
            </Box>
            <Button
              primary
              label="See Full Plan"
              size="small"
              onClick={onShowFullPlan}
            />
          </Box>
        </DashboardCard>
      </Box>
    </>
  );
};

const InvestmentsTab = () => {
  const size = useContext(ResponsiveContext);
  const { user } = useContext(UserContext);
  return (
    <Box
      direction="row"
      wrap
      flex
      align="center"
      justify="center"
      responsive={false}
      pad={{ top: "small", bottom: "medium" }}
      style={{ maxWidth: "1600px" }}
    >
      <DashboardCard
        title="Balance"
        description="How your accounts performed this week"
        highlight={
          <Box align="end" margin={{ right: "small" }} responsive={false}>
            <Text color="text-weak" weight="bold">
              $320,144.33
            </Text>
            <Text
              color="status-critical"
              size={size !== "large" ? "small" : undefined}
            >
              -$1,059.00 (-1.35%)
            </Text>
          </Box>
        }
      >
        <Box flex overflow="auto">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    From
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    To
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    Change
                  </Heading>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <InstitutionLogo
                      size="24px"
                      institution={get(user, "data.institutions", [])[0]}
                    />
                    <Text
                      size="small"
                      style={{ maxWidth: "72px" }}
                      truncate
                      title="Plaid 401k"
                    >
                      Plaid 401k
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">$200k</Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">$180k</Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak">-$20k</Text>
                    <Text color="status-critical">-10.00%</Text>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <InstitutionLogo
                      size="24px"
                      institution={get(user, "data.institutions", [])[0]}
                    />
                    <Text
                      size="small"
                      style={{ maxWidth: "72px" }}
                      truncate
                      title="Plaid 401k"
                    >
                      Plaid IRA
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">$300k</Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">$320k</Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak">$20k</Text>
                    <Text color="status-ok">9.20%</Text>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <InstitutionLogo
                      size="24px"
                      institution={get(user, "data.institutions", [])[0]}
                    />
                    <Text
                      size="small"
                      style={{ maxWidth: "72px" }}
                      truncate
                      title="Plaid 401k"
                    >
                      Plaid IRA
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">$300k</Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">$320k</Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak">$20k</Text>
                    <Text color="status-ok">9.20%</Text>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
      <DashboardCard
        title="Holdings"
        description="Your active holdings this week"
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  Total
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  Week
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  Today
                </Heading>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Box
                  direction="row"
                  align="center"
                  gap="small"
                  responsive={false}
                  flex={false}
                >
                  <InstitutionHoldingsLogo
                    size={size !== "large" ? "24px" : "32px"}
                    institution={get(user, "data.institutions", [])[0]}
                    holding={{
                      name: "Tesla",
                      url: "https://logos.m1finance.com/TSLA?size=144",
                    }}
                  />
                  <Box>
                    <Text size="small" color="text-xweak">
                      Fidelity
                    </Text>
                    <Text
                      style={{ maxWidth: size !== "large" ? "80px" : "90px" }}
                      title="Tesla Inc"
                      truncate
                      size="small"
                    >
                      $TSLA - Tesla Inc
                    </Text>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">$80k</Text>
                  <Text color="status-ok">1.2k%</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">-$1.2k</Text>
                  <Text color="status-critical">-3.00%</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">-$500.33</Text>
                  <Text color="status-critical">-1.00%</Text>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Box
                  direction="row"
                  align="center"
                  gap="small"
                  responsive={false}
                  flex={false}
                >
                  <InstitutionHoldingsLogo
                    size={size !== "large" ? "24px" : "32px"}
                    institution={get(user, "data.institutions", [])[0]}
                    holding={{
                      name: "VIOV",
                      url: "https://logos.m1finance.com/VIOV?size=144",
                    }}
                  />
                  <Box>
                    <Text size="small" color="text-xweak">
                      Fidelity
                    </Text>
                    <Text
                      style={{ maxWidth: size !== "large" ? "80px" : "90px" }}
                      title="VANGUARD ADMIRAL FDS INC"
                      truncate
                      size="small"
                    >
                      $VIOV - VANGUARD ADMIRAL FDS INC
                    </Text>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">$40k</Text>
                  <Text color="status-ok">20.00%</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">$797.99</Text>
                  <Text color="status-ok">2.00%</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">-$500.33</Text>
                  <Text color="status-critical">-1.00%</Text>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Box
                  direction="row"
                  align="center"
                  gap="small"
                  responsive={false}
                  flex={false}
                >
                  <InstitutionHoldingsLogo
                    size={size !== "large" ? "24px" : "32px"}
                    institution={get(user, "data.institutions", [])[0]}
                    holding={{
                      name: "Disney",
                      url: "https://logos.m1finance.com/DIS?size=144",
                    }}
                  />
                  <Box>
                    <Text size="small" color="text-xweak">
                      Fidelity
                    </Text>
                    <Text
                      style={{ maxWidth: size !== "large" ? "90px" : "100px" }}
                      title="Walt Disney Company (THE)"
                      truncate
                      size="small"
                    >
                      $DIS - Walt Disney Company (THE)
                    </Text>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">$80k</Text>
                  <Text color="status-ok">1.2k%</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">-$1.2k</Text>
                  <Text color="status-critical">-3.00%</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Box>
                  <Text color="text-weak">-$500.33</Text>
                  <Text color="status-critical">-1.00%</Text>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DashboardCard>
      <DashboardCard
        title="Deposits"
        description="Money in your investment accounts this week"
        highlight={
          <Box align="end" margin={{ right: "small" }} responsive={false}>
            <Text weight="bold" color="status-ok">
              $2,059.00
            </Text>
            <Text color="text-xweak" size="small">
              <b>total</b> cash
            </Text>
          </Box>
        }
      >
        <Box flex overflow="auto">
          <Box
            align="center"
            justify="center"
            height="72px"
            direction="row"
            gap="large"
            responsive={false}
            border="bottom"
          >
            <Box align="center" justify="center" responsive={false}>
              <Heading level={3} margin="none" responsive={false}>
                <b>$2k</b>
              </Heading>
              <Text color="text-weak" size="small">
                <b>deposits</b> made
              </Text>
            </Box>
            <Box align="center" justify="center" responsive={false}>
              <Heading level={3} margin="none" responsive={false}>
                <b>$59.00</b>
              </Heading>
              <Text color="text-weak" size="small">
                <b>dividends</b> received
              </Text>
            </Box>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    WHAT
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    WHEN
                  </Heading>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    flex={false}
                    pad={{ left: "small" }}
                  >
                    <Box width="52px" align="center">
                      <InstitutionLogo
                        size="32px"
                        institution={get(user, "data.institutions", [])[0]}
                      />
                    </Box>
                    <Box>
                      <Text size="small" color="text-xweak">
                        Fidelity
                      </Text>
                      <Text
                        style={{ maxWidth: "122px" }}
                        title="Walt Disney Company (THE)"
                        truncate
                        size="small"
                      >
                        Plaid IRA
                      </Text>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak" weight="bold">
                      $2k
                    </Text>
                    <Text color="text-xweak" size="small">
                      deposit
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak" size="small">
                    Today
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    flex={false}
                    pad={{ left: "small" }}
                  >
                    <Box width="52px" align="center">
                      <InstitutionHoldingsLogo
                        size={size !== "large" ? "24px" : "32px"}
                        institution={get(user, "data.institutions", [])[0]}
                        holding={{
                          name: "Disney",
                          url: "https://logos.m1finance.com/DIS?size=144",
                        }}
                      />
                    </Box>
                    <Box>
                      <Text size="small" color="text-xweak">
                        Fidelity
                      </Text>
                      <Text
                        style={{ maxWidth: "122px" }}
                        title="Walt Disney Company (THE)"
                        truncate
                        size="small"
                      >
                        $DIS - Walt Disney Company (THE)
                      </Text>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak" weight="bold">
                      $59.00
                    </Text>
                    <Text size="small" color="text-xweak">
                      dividends
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak" size="small">
                    Yesterday
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
      <SPYComparisonCard />
      <DashboardCard
        title="Statistics"
        description="Key weekly performance indicators"
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Box
                  align="center"
                  direction="row"
                  justify="center"
                  gap="xsmall"
                >
                  <InstitutionLogo
                    size="24px"
                    institution={{
                      name: "SPY",
                      url: "https://logos.m1finance.com/SPY?size=144",
                    }}
                  />
                  <Heading level={5} margin="none">
                    SPY
                  </Heading>
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  align="center"
                  direction="row"
                  justify="center"
                  gap="xsmall"
                >
                  <UserAvatar user={user} showName={false} size="24px" />
                  <Heading level={5} margin="none">
                    YOU
                  </Heading>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <Text color="text-weak">
                  Avg <b>weekly</b> change
                </Text>
              </TableCell>
              <TableCell align="center">
                <Box pad="small">
                  <Text color="status-ok" weight="bold">
                    $500.55
                  </Text>
                </Box>
              </TableCell>
              <TableCell align="center">
                <Box pad="small">
                  <Text color="status-ok" weight="bold">
                    $900.55
                  </Text>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <Text color="text-weak">
                  Avg <b>weekly</b> change (%)
                </Text>
              </TableCell>
              <TableCell align="center">
                <Box pad="small">
                  <Text color="status-ok" weight="bold">
                    0.25%
                  </Text>
                </Box>
              </TableCell>
              <TableCell align="center">
                <Box pad="small">
                  <Text color="status-ok" weight="bold">
                    1.04%
                  </Text>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DashboardCard>
      <DashboardCard
        title="Activity"
        description="What have you bought and sold this week"
        highlight={
          <Box align="end" margin={{ right: "small" }} responsive={false}>
            <Text>
              <b>10</b> trades
            </Text>
            <Text color="text-weak" size="small">
              <b>3</b> day trades
            </Text>
          </Box>
        }
      >
        <Box flex overflow="auto">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    TYPE
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    DETAIL
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    WHEN
                  </Heading>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    flex={false}
                  >
                    <InstitutionHoldingsLogo
                      size={size !== "large" ? "24px" : "32px"}
                      institution={get(user, "data.institutions", [])[0]}
                      holding={{
                        name: "Disney",
                        url: "https://logos.m1finance.com/DIS?size=144",
                      }}
                    />
                    <Box>
                      <Text size="small" color="text-xweak">
                        Fidelity
                      </Text>
                      <Text
                        style={{
                          maxWidth: size !== "large" ? "90px" : "100px",
                        }}
                        title="Walt Disney Company (THE)"
                        truncate
                        size="small"
                      >
                        $DIS - Walt Disney Company (THE)
                      </Text>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    width="48px"
                    round="medium"
                    background="status-ok"
                    align="center"
                  >
                    <Text size="small" color="white" weight="bold">
                      OPEN
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak" size="small">
                      <b>10</b> shares
                    </Text>
                    <Text size="small" color="text-xweak">
                      <b>$190.44</b> price
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak" size="small">
                    Yesterday
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    flex={false}
                  >
                    <InstitutionHoldingsLogo
                      size={size !== "large" ? "24px" : "32px"}
                      institution={get(user, "data.institutions", [])[0]}
                      holding={{
                        name: "Disney",
                        url: "https://logos.m1finance.com/NFLX?size=144",
                      }}
                    />
                    <Box>
                      <Text size="small" color="text-xweak">
                        Fidelity
                      </Text>
                      <Text
                        style={{
                          maxWidth: size !== "large" ? "90px" : "100px",
                        }}
                        title="Walt Disney Company (THE)"
                        truncate
                        size="small"
                      >
                        $NFLX - Netflix
                      </Text>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    width="48px"
                    round="medium"
                    background="status-ok"
                    align="center"
                  >
                    <Text size="small" color="white" weight="bold">
                      OPEN
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak" size="small">
                      <b>2</b> contracts
                    </Text>
                    <Text size="small" color="text-xweak">
                      <b>$490.44</b> credit
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak" size="small">
                    Monday
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    flex={false}
                  >
                    <InstitutionHoldingsLogo
                      size={size !== "large" ? "24px" : "32px"}
                      institution={get(user, "data.institutions", [])[0]}
                      holding={{
                        name: "Disney",
                        url: "https://logos.m1finance.com/TDOC?size=144",
                      }}
                    />
                    <Box>
                      <Text size="small" color="text-xweak">
                        Fidelity
                      </Text>
                      <Text
                        style={{
                          maxWidth: size !== "large" ? "90px" : "100px",
                        }}
                        title="TELADOC HEALTH INC"
                        truncate
                        size="small"
                      >
                        $TDOC - TELADOC HEALTH INC
                      </Text>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    width="48px"
                    round="medium"
                    background="status-critical"
                    align="center"
                  >
                    <Text size="small" color="white" weight="bold">
                      CLOSE
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak" size="small">
                      <b>100</b> shares
                    </Text>
                    <Text size="small" color="text-xweak">
                      <b>40%</b> profit
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak" size="small">
                    Monday
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box
                    direction="row"
                    align="center"
                    gap="small"
                    responsive={false}
                    flex={false}
                  >
                    <InstitutionHoldingsLogo
                      size={size !== "large" ? "24px" : "32px"}
                      institution={get(user, "data.institutions", [])[0]}
                      holding={{
                        name: "Disney",
                        url: "https://logos.m1finance.com/FB?size=144",
                      }}
                    />
                    <Box>
                      <Text size="small" color="text-xweak">
                        Fidelity
                      </Text>
                      <Text
                        style={{
                          maxWidth: size !== "large" ? "90px" : "100px",
                        }}
                        title="FACEBOOK INC"
                        truncate
                        size="small"
                      >
                        $FB - FACEBOOK INC
                      </Text>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    width="48px"
                    round="medium"
                    background="status-critical"
                    align="center"
                  >
                    <Text size="small" color="white" weight="bold">
                      CLOSE
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text color="text-weak" size="small">
                      <b>10</b> contracts
                    </Text>
                    <Text size="small" color="text-xweak">
                      <b>10%</b> loss
                    </Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak" size="small">
                    Monday
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
    </Box>
  );
};

export const DashboardBody = () => {
  const { user } = useContext(UserContext);
  const onPageChange = useContext(PageChangeContext);

  const [currentTime, setCurrentTime] = useState("week");
  const onUpdateCurrentTime = useCallback(
    (index) => {
      switch (index) {
        case 0:
          setCurrentTime("week");
          break;
        case 1:
          setCurrentTime("month");
          break;
        default:
          setCurrentTime("week");
      }
    },
    [setCurrentTime]
  );
  const chartInstance = <TrtChart data={chartData[currentTime]} />;
  const institutions = get(user, "data.institutions", []);

  const onAddAccounts = useCallback(() => onPageChange("addAssets"), [
    onPageChange,
  ]);

  if (!institutions.length) {
    return (
      <Box flex align="center" pad="large" gap="small">
        <Heading level={2} margin="none">
          Let's add some numbers
        </Heading>
        <Paragraph margin="none" color="text-weak" textAlign="center">
          In order to see your performance you need to add assets and
          liabilities
        </Paragraph>
        <Button
          label="Add Assets & Liabilities"
          primary
          margin={{ top: "medium" }}
          onClick={onAddAccounts}
        />
      </Box>
    );
  }
  return (
    <Box flex pad={{ top: "medium" }} overflow="auto" responsive={false}>
      <Tabs border="bottom" onActive={onUpdateCurrentTime}>
        <Tab title="Weekly">{chartInstance}</Tab>
        <Tab title="Monthly">{chartInstance}</Tab>
        <Tab title="Yearly">{chartInstance}</Tab>
      </Tabs>
      <Box
        direction="row"
        align="start"
        justify="center"
        pad="medium"
        gap="small"
        flex
        responsive={false}
      >
        <Tabs fill>
          <Tab title="Overview">
            <OverviewTab />
          </Tab>
          <Tab title="Investments">
            <Box align="center">
              <InvestmentsTab />
            </Box>
          </Tab>
          <Tab title="Properties">
            <Box margin={{ top: "medium" }} align="center">
              <Text textAlign="center">Properties cards to come soon</Text>
            </Box>
          </Tab>
        </Tabs>
      </Box>
    </Box>
  );
};

export const DashboardHeader = ({ flex }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      justify="between"
      direction="row"
      align="center"
      pad={{ horizontal: "medium", vertical: "small" }}
      flex={flex}
    >
      <Box>
        <Heading
          level={3}
          size={size !== "large" ? "xsmall" : undefined}
          margin="none"
        >
          $600k
        </Heading>
        <Heading
          level={5}
          size={size !== "large" ? "small" : undefined}
          margin="none"
          color="text-weak"
        >
          Current Net Worth
        </Heading>
        <Box direction="row" align="center" gap="xsmall">
          <PercentDisplay percent={1.35} timeframe="week" />
          <PercentDisplay percent={-0.4} timeframe="month" />
          <PercentDisplay percent={7} timeframe="year" splitter={false} />
        </Box>
      </Box>
      <Box align="end">
        <Heading
          level={3}
          size={size !== "large" ? "xsmall" : undefined}
          textAlign="end"
          margin="none"
        >
          $3M
        </Heading>
        <Heading
          level={5}
          size={size !== "large" ? "xsmall" : undefined}
          margin="none"
          color="text-weak"
        >
          Target Net Worth
        </Heading>
        <Box
          direction="row"
          justify="end"
          align="center"
          gap="xsmall"
          pad={{ vertical: "xsmall" }}
        >
          <Box
            background="status-ok"
            round="small"
            pad={{ horizontal: "xsmall" }}
          >
            <Text color="white" size="small" weight="bold">
              on track
            </Text>
          </Box>
          {size === "large" && (
            <Text color="text-xweak" size="small">
              for retirement in <b>10 years</b>
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const Dashboard = () => {
  const size = useContext(ResponsiveContext);
  const [showDashboard, setShowDashboard] = useState(true);
  const onShowDashboard = useCallback(() => setShowDashboard(true), [
    setShowDashboard,
  ]);
  const onHideDashboard = useCallback(() => setShowDashboard(false), [
    setShowDashboard,
  ]);

  const notificationEventHandlers = useMemo(
    () =>
      size !== "large"
        ? {
            onClose: onShowDashboard,
            onOpen: onHideDashboard,
          }
        : {},
    [size, onShowDashboard, onHideDashboard]
  );
  return (
    <Box direction="row" fill>
      <Header />
      <Box flex>
        <Box flex={false} direction="row" border="bottom" height="80px">
          <DashboardHeader flex />
          {size === "large" && (
            <Box
              border="left"
              align="center"
              justify="center"
              pad={{ horizontal: "medium", vertical: "small" }}
            >
              <Heading level={3} margin="none" weight="bold">
                Friday
              </Heading>
              <Text color="text-weak">Nov 15th, 2020</Text>
            </Box>
          )}
        </Box>
        <Box direction="row" fill>
          {showDashboard && <DashboardBody />}
          <Notifications {...notificationEventHandlers} />
        </Box>
      </Box>
    </Box>
  );
};
