export const ACCOUNT_TYPE_DISPLAY_NAME = {
  savings: "Savings",
  checking: "Checking",
  cd: "Certificate of Deposit",
  investment: "Investment",
  "credit-card": "Credit Card",
  "money-market": "Money Market",
  loan: "Loan",
};

export const ACCOUNT_TYPE_OPTIONS = Object.entries(
  ACCOUNT_TYPE_DISPLAY_NAME
).map(([value, label]) => ({ value, label }));
