import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Heading,
  Layer,
  ResponsiveContext,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  TextInput,
} from "grommet";
import { Close, Trash } from "grommet-icons";
import styled from "styled-components";
import md5 from "md5";
import { orderBy } from "lodash";

import { FeedbackContext } from "../feedback";
import { getFirebaseHost } from "../../utils";
import { TRTThemeContext, UserContext } from "../../index";

const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PlainTextInput = styled(TextInput)`
  &:focus {
    box-shadow: none;
  }
`;

export const ManageMembers = ({ onClose, investmentGroup }) => {
  const { currentTheme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const [newMembers, setNewMembers] = useState("");
  const [members, setMembers] = useState(investmentGroup.members);
  const {
    user: { data: user },
    updateUser,
  } = useContext(UserContext);

  const appBackground = currentTheme === "dark" ? "dark-1" : "light-1";

  useEffect(() => setMembers(investmentGroup.members), [
    investmentGroup.members,
  ]);
  const onUpdateNewMembers = useCallback(
    ({ target: { value } }) => setNewMembers(value),
    []
  );
  const onRemoveMember = useCallback(
    (memberToRemove) => () => {
      if (
        memberToRemove.role === "administrator" &&
        members.filter(({ role }) => role === "administrator").length === 1
      ) {
        sendFeedback({
          message: "You can't remove the last Administrator",
          type: "error",
        });
      } else {
        setMembers(members.filter(({ id }) => id !== memberToRemove.id));
      }
    },
    [members, sendFeedback]
  );
  const onUpdateMemberRole = useCallback(
    (memberToUpdate) => ({ option: { value: role } }) => {
      if (
        memberToUpdate.role === "administrator" &&
        members.filter(({ role }) => role === "administrator").length === 1 &&
        role !== "administrator"
      ) {
        sendFeedback({
          message: "You can't remove the last Administrator",
          type: "error",
        });
      } else {
        setMembers(
          members.map((member) => {
            if (memberToUpdate.id === member.id) {
              return {
                ...member,
                role,
              };
            }
            return member;
          })
        );
      }
    },
    [members, sendFeedback]
  );
  const onAddMembers = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const memberEmails = newMembers
        .split(",")
        .map((email) => email.trim())
        .filter(
          (email) =>
            emailRegexp.test(email) && !members.find(({ id }) => email === id)
        );

      if (!memberEmails.length) {
        sendFeedback({
          message: "No valid email to add",
          type: "error",
        });
      } else {
        const updatedMembers = [
          ...members,
          ...memberEmails.map((id) => ({
            id,
            name: "",
            pending: true,
            role: "viewer",
            temp: true,
          })),
        ];
        setMembers(updatedMembers);
      }
      setNewMembers("");
    },
    [newMembers, members, sendFeedback]
  );
  const onSaveMembers = useCallback(async () => {
    const updatedMembers = members.map((m) => {
      const newMember = { ...m };
      delete newMember.temp;
      return { ...newMember, pending: false };
    });

    const userResponse = await fetch(
      `//${getFirebaseHost()}/api/investment-groups/${user.id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          investmentGroup: {
            ...investmentGroup,
            members: updatedMembers,
          },
        }),
      }
    );

    const newUser = await userResponse.json();

    updateUser(newUser);

    sendFeedback({
      message: (
        <span>
          Successfully updated members for <b>{investmentGroup.name}</b>
        </span>
      ),
      type: "success",
    });
  }, [members, sendFeedback, investmentGroup, updateUser, user.id]);

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} margin="large">
      <Box
        width="xlarge"
        height={size === "small" ? "full" : "xlarge"}
        background={{ color: appBackground, dark: currentTheme === "dark" }}
        round="small"
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          pad="medium"
          border="bottom"
        >
          <Box gap="xsmall">
            <Heading level={3} responsive={false} margin="none">
              Manage Members
            </Heading>
            <Text color="text-xweak">
              Add or remove members for <b>{investmentGroup.name}</b>
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex overflow="auto" pad="medium">
          <form onSubmit={onAddMembers}>
            <Box
              direction="row"
              align="center"
              border="all"
              round="small"
              responsive={false}
              pad={{ right: "small", vertical: "4px" }}
            >
              <PlainTextInput
                placeholder="one@test.com, two@test.com"
                plain
                value={newMembers}
                onChange={onUpdateNewMembers}
              />
              <Button size="small" primary label="Add" type="submit" />
            </Box>
          </form>
          <Heading level={4}>Members</Heading>
          <Table>
            <TableBody>
              {orderBy(members, "pending", ["desc"]).map((member) => (
                <TableRow key={`table-${member.id}`}>
                  <TableCell>
                    <Box flex direction="row" align="center" justify="between">
                      <Box direction="row" align="center" gap="small">
                        <Avatar
                          size="32px"
                          src={`//s.gravatar.com/avatar/${md5(member.id)}?s=80`}
                        />
                        <Box>
                          <Text
                            truncate
                            style={{ maxWidth: "144px" }}
                            title={member.name || member.id}
                          >
                            {member.name || member.id}
                          </Text>
                          {member.temp ? (
                            <Text color="text-xweak" size="small">
                              Invitation pending save
                            </Text>
                          ) : (
                            <Text color="text-xweak" size="small">
                              {member.pending ? "Invited" : "Joined"} a few
                              moments ago
                            </Text>
                          )}
                        </Box>
                      </Box>
                      {member.pending && (
                        <Text
                          color="status-critical"
                          weight="bold"
                          size="small"
                        >
                          Pending
                        </Text>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell size="150px" align="center">
                    <Select
                      options={[
                        { label: "Administrator", value: "administrator" },
                        { label: "Player", value: "player" },
                        { label: "Viewer", value: "viewer" },
                      ]}
                      labelKey="label"
                      valueKey={{ key: "value", reduce: true }}
                      value={member.role}
                      size="small"
                      onChange={onUpdateMemberRole(member)}
                    />
                  </TableCell>
                  <TableCell size="40px" align="center">
                    <Button
                      icon={<Trash color="status-critical" size="small" />}
                      onClick={onRemoveMember(member)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box
          tag="footer"
          justify="end"
          direction="row"
          pad="medium"
          border="top"
          gap="small"
          flex={false}
          responsive={false}
        >
          <Button secondary label="Cancel" onClick={onClose} />
          <Button label="Save" primary onClick={onSaveMembers} />
        </Box>
      </Box>
    </Layer>
  );
};
