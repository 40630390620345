import React, { useCallback, useContext, useMemo, useState } from "react";
import { get, remove } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  AccountCard,
  AddAccountButton,
  FeedbackContext,
  Header,
  InstitutionLogo,
  PageHeader,
} from "../components";
import {
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
  TextInput,
} from "grommet";
import { getFirebaseHost } from "../utils";
import { useThrottledEffect, useFetch } from "../hooks";
import { deepMerge } from "grommet/utils";
import { PageChangeContext, QueryStringContext, UserContext } from "../";

const generatePlaceholderAccount = (i) => ({
  id: uuidv4(),
  balance: 0,
  name: `Account ${i}`,
  type: "checking",
});

const defaultInstitution = {
  name: undefined,
  type: "manual",
  accounts: [generatePlaceholderAccount(1)],
};

const validateInstitution = (institution) => {
  if (!institution.id) {
    return "You need to select a valid institution";
  }
  const hasMissingAccountName = institution.accounts.some(({ name }) => !name);
  if (hasMissingAccountName) {
    return "Accounts need to have a name";
  }
  return undefined;
};

export const AddInstitutionBody = () => {
  const [institutionName, setInstitutionName] = useState("");
  const [institution, setInstitution] = useState(defaultInstitution);
  const {
    user: { data: user },
    updateUser,
  } = useContext(UserContext);
  const { setQuery } = useContext(QueryStringContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const institutionQuery = institutionName.trim();
  const {
    data: rawInstitutionSuggestions,
    doFetch: fetchInstitutionSuggestions,
    reset: onResetInstitutionSuggestions,
  } = useFetch(
    `//${getFirebaseHost()}/api/institutions/search/${institutionQuery}`,
    { lazy: true }
  );
  const onInstitutionNameChange = useCallback(
    ({ target: { value } }) => {
      if (!value) {
        onResetInstitutionSuggestions();
      }
      setInstitution({ ...defaultInstitution, accounts: institution.accounts });
      setInstitutionName(value || "");
    },
    [setInstitutionName, onResetInstitutionSuggestions, institution.accounts]
  );

  useThrottledEffect(
    () => {
      if (institutionQuery.length > 1) {
        fetchInstitutionSuggestions();
      }
    },
    300,
    [institutionQuery]
  );

  const institutionSuggestions = useMemo(() => {
    return (rawInstitutionSuggestions || []).map((institution) => {
      const { id, name, url } = institution;
      let maxWidth = "240px";
      if (size === "large") {
        maxWidth = "400px";
      } else if (size === "small") {
        maxWidth = "210px";
      }
      return {
        value: id,
        label: (
          <Box
            direction="row"
            align="center"
            pad={size === "large" ? "medium" : "small"}
            gap="small"
          >
            <InstitutionLogo institution={institution} />
            <Box>
              <Text
                size={size === "large" ? "large" : undefined}
                truncate
                style={{ maxWidth }}
                title={name}
              >
                {name}
              </Text>
              <Text
                size="small"
                color="text-weak"
                truncate
                style={{ maxWidth }}
                title={url}
              >
                {url}
              </Text>
            </Box>
          </Box>
        ),
        institution,
      };
    });
  }, [rawInstitutionSuggestions, size]);

  const onInstitutionSelect = useCallback(
    ({ suggestion: { institution: i } }) => {
      setInstitution(
        deepMerge(i, { accounts: institution.accounts, type: institution.type })
      );
    },
    [setInstitution, institution]
  );

  const onAccountChange = useCallback(
    (id, key, value) => {
      const newInstitution = { ...institution };

      newInstitution.accounts = newInstitution.accounts.map((a) =>
        a.id === id ? { ...a, [key]: value } : a
      );

      setInstitution(newInstitution);
    },
    [institution]
  );

  const tempInstitution = useMemo(
    () => ({
      ...institution,
      name: institutionName,
    }),
    [institution, institutionName]
  );

  const onAddAccount = useCallback(() => {
    const newInstitution = { ...institution };
    newInstitution.accounts = [...institution.accounts];
    newInstitution.accounts.push(
      generatePlaceholderAccount(newInstitution.accounts.length + 1)
    );

    setInstitution(newInstitution);
  }, [setInstitution, institution]);

  const onRemoveAccount = useCallback(
    (idToBeRemoved) => {
      const newInstitution = { ...institution };
      if (newInstitution.accounts.length - 1 > 0) {
        remove(newInstitution.accounts, ({ id }) => id === idToBeRemoved);

        setInstitution(newInstitution);
      } else {
        sendFeedback({
          message: "You need at least one account per institution",
          type: "error",
        });
      }
    },
    [setInstitution, institution, sendFeedback]
  );

  const onInstitutionSave = useCallback(async () => {
    const error = validateInstitution(institution);

    if (error) {
      sendFeedback({ message: error, type: "error" });
      return;
    }

    if (institution.id) {
      institution.accounts = institution.accounts.map((a) => ({
        ...a,
        balance: +a.balance,
      }));
      const userResponse = await fetch(
        `//${getFirebaseHost()}/api/institutions/${user.id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ institution }),
        }
      );

      const newUser = await userResponse.json();

      updateUser(newUser);

      setQuery({ activePanel: 1, activeInstitution: institution.id });

      if (size !== "small") {
        onPageChange("addAssets");
      } else {
        onPageChange("institutions");
      }
    }
  }, [
    institution,
    setQuery,
    updateUser,
    user.id,
    onPageChange,
    sendFeedback,
    size,
  ]);

  return (
    <>
      <Box
        flex
        overflow="auto"
        align="start"
        pad={{ vertical: "medium", horizontal: size }}
        responsive={false}
      >
        <Box flex={false}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            width="large"
            pad={{ right: "medium" }}
          >
            <InstitutionLogo
              size={size !== "small" ? "large" : "72px"}
              institution={tempInstitution}
            />
            <TextInput
              placeholder="Institution name"
              size={size}
              suggestions={institutionSuggestions || []}
              value={get(institution, "name") || institutionName}
              onChange={onInstitutionNameChange}
              onSuggestionSelect={onInstitutionSelect}
              type="search"
            />
          </Box>
          <Box pad={{ horizontal: "medium" }}>
            <Heading level={3} margin={{ bottom: "none" }}>
              Accounts
            </Heading>
            <Box
              direction="row"
              align="start"
              gap="medium"
              wrap
              style={{ maxWidth: "1360px" }}
            >
              {get(institution, "accounts", []).map((account) => (
                <AccountCard
                  key={account.id}
                  account={account}
                  onChange={onAccountChange}
                  onRemove={onRemoveAccount}
                />
              ))}
              <Box
                width="medium"
                margin={{
                  top: size !== "small" ? "medium" : undefined,
                  left: size === "large" ? "small" : undefined,
                }}
                align={size === "small" ? "center" : "start"}
              >
                <AddAccountButton onClick={onAddAccount} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button
          secondary
          label="Cancel"
          onClick={() => onPageChange("addAssets")}
        />
        <Button label="Save" primary onClick={onInstitutionSave} />
      </Box>
    </>
  );
};

export const AddInstitution = () => {
  const onPageChange = useContext(PageChangeContext);

  const onPageClose = useCallback(() => onPageChange("addAssets"), [
    onPageChange,
  ]);

  return (
    <Box direction="row" fill>
      <Header />
      <Box flex>
        <PageHeader
          title="Add Institution"
          subTitle="Choose a supported institution and provide your
              account balances"
          onClose={onPageClose}
        />
        <AddInstitutionBody />
      </Box>
    </Box>
  );
};
