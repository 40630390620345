import React, { useCallback, useContext } from "react";
import { StepChangeContext } from "../../pages";

import { OnboardingHeading, OnboardingLayout } from "./";
import { PaymentChooser } from "../payment-chooser";

export const OnboardingAddPayment = () => {
  const { onStepChange } = useContext(StepChangeContext);
  const onPreviousStep = useCallback(() => onStepChange("choosePlan"), [
    onStepChange,
  ]);
  const onNextStep = useCallback(
    (planFrequency) => {
      localStorage.setItem("trt-plan-frequency", planFrequency);
      onStepChange("addInstitution");
    },
    [onStepChange]
  );
  return (
    <OnboardingLayout onPreviousStep={onPreviousStep}>
      <OnboardingHeading
        heading="Almost There"
        description="Thanks for choosing to go Pro. We need to get your payment information"
      />
      <PaymentChooser onPay={onNextStep} />
    </OnboardingLayout>
  );
};
