import React from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile, NotificationBody } from "../../components";

export const NotificationsMobile = () => {
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Notifications
          </Text>
        }
      />
      <Box
        flex
        overflow="auto"
        align="center"
        pad={{ horizontal: "medium", vertical: "small" }}
        responsive={false}
      >
        <NotificationBody />
      </Box>
      <FooterMobile />
    </Box>
  );
};
