import React, { useContext } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import numeral from "numeral";

export const ChartLabelPerformance = ({ value, percent, background }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box direction="row" align="center" gap="4px">
      <Box
        round="large"
        background={background}
        width="legend-circle"
        height="legend-circle"
      />
      <Text size={size !== "large" ? "small" : undefined}>
        <b>{numeral(value).format("-$0.[00]a")}</b> (
        {numeral(percent).format("+0,0.00")}
        %)
      </Text>
    </Box>
  );
};
