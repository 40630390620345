import React from "react";
import { Box, Text } from "grommet";

import { FooterMobile, HeaderMobile } from "../../components";
import { AddAssetsBody } from "../add-assets";

export const AddAssetsMobile = () => {
  return (
    <Box fill>
      <HeaderMobile
        center={
          <Text size="large" weight="bold">
            Assets & Liabilities
          </Text>
        }
      />
      <Box
        flex
        overflow="auto"
        pad={{ horizontal: "medium", vertical: "small" }}
        responsive={false}
      >
        <AddAssetsBody />
      </Box>
      <FooterMobile />
    </Box>
  );
};
