import { useEffect, useState } from "react";
import { getFirebaseHost } from "../utils";

export const usePlaidToken = (user = {}) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const tokenResponse = await fetch(
        `//${getFirebaseHost()}/api/link-token/${user.id}`,
        {
          method: "POST",
        }
      );
      const { error, token } = await tokenResponse.json();
      if (error) {
        console.log(error);
        // TODO: add generic error component that will pop in app
        throw new Error(error);
      }
      setToken(token);
    };
    if (user.id) {
      fetchToken();
    }
  }, [user.id, setToken]);

  return token;
};
