import React, { useCallback, useContext, useMemo, useState } from "react";
import { Box, Select, Text } from "grommet";
import { StatusCritical } from "grommet-icons";
import { get } from "lodash";

import { UserContext } from "../";

import { ConfirmationModal, InstitutionOption } from "./";

const renderInstitutionOption = (option, index, options, state) => (
  <InstitutionOption state={state} institution={option} />
);

export const DowngradeSubscription = ({ onCancel, onConfirm }) => {
  const { user } = useContext(UserContext);
  const automatedInstitutions = useMemo(
    () =>
      get(user, "data.institutions", []).filter(
        ({ type }) => type === "automated"
      ),
    [user]
  );
  const [
    selectedAutomatedInstitution,
    setSelectedAutomatedInstitution,
  ] = useState(automatedInstitutions[0]);
  const onAutomatedInstitutionSelected = useCallback(
    ({ option }) => setSelectedAutomatedInstitution(option),
    []
  );
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to downgrade?`}
      actionLabel="Confirm"
    >
      <Box gap="medium" responsive={false}>
        <Text color="text-weak">
          By downgrading to the basic plan all your institutions will be
          automatically converted to manual. All future updates will have to be
          manually provided, otherwise balances will not update.
        </Text>
        <Text color="text-weak">
          Choose an institution to keep as automated:
        </Text>
        <Select
          options={automatedInstitutions}
          value={selectedAutomatedInstitution}
          valueKey="id"
          valueLabel={
            <InstitutionOption institution={selectedAutomatedInstitution} />
          }
          onChange={onAutomatedInstitutionSelected}
        >
          {renderInstitutionOption}
        </Select>
      </Box>
    </ConfirmationModal>
  );
};
