import React, { useEffect, useCallback, useContext } from "react";
import { Button, ResponsiveContext } from "grommet";

import { StepChangeContext } from "../../pages";

import {
  FeedbackContext,
  CallToAction,
  OnboardingHeading,
  OnboardingLayout,
} from "../";

let shouldFailValidateEmail = true;

export const ValidateEmail = () => {
  const { onStepChange } = useContext(StepChangeContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const size = useContext(ResponsiveContext);
  const onCallToActionClick = useCallback(() => {
    sendFeedback({
      type: "success",
      message: <span>Sent, remember to check your spam folder</span>,
    });
  }, [sendFeedback]);

  useEffect(() => {
    localStorage.removeItem("trt-theme");
    localStorage.removeItem("trt-plan");
    localStorage.removeItem("trt-plan-frequency");
  }, []);
  return (
    <OnboardingLayout>
      <OnboardingHeading
        heading="Validate your Email"
        description="Welcome to The Retirement Tracker, we can't wait to start helping
            you with your retirement plans. But before we move forward, head
            over to your email inbox and click on the link we sent you so that
            we can validate your email address"
      />
      <Button
        size={size !== "small" ? "large" : undefined}
        label="Done"
        primary
        onClick={() => {
          if (shouldFailValidateEmail) {
            sendFeedback({
              message: <span>Email validation has not been received</span>,
              type: "error",
            });
            shouldFailValidateEmail = false;
          } else {
            onStepChange("completeProfile");
          }
        }}
      />
      <CallToAction
        text="Haven't received our email?"
        linkText="Send again"
        onClick={onCallToActionClick}
      />
    </OnboardingLayout>
  );
};
