import React from "react";
import { Box, Text } from "grommet";

export const InstitutionTypeBadge = ({ type, size = "xsmall" }) => (
  <Box
    flex={false}
    background={type === "manual" ? "neutral-5" : "neutral-1"}
    round="large"
    pad={{
      horizontal: "6px",
      vertical: size !== "xsmall" ? "2px" : undefined,
    }}
  >
    <Text size={size}>
      <b>{type.toUpperCase()}</b>
    </Text>
  </Box>
);
