import React, { useCallback, useContext, useState } from "react";
import { Anchor, Box, Heading, Layer, Paragraph, Text } from "grommet";
import { get } from "lodash";

import { UserContext } from "../";

import { DowngradeSubscription, FeedbackContext, PaymentChooser } from "./";

export const PaymentFailure = ({ onFix }) => {
  const { user } = useContext(UserContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const [showDowngrade, setShowDowngrade] = useState(false);
  const onDowngrade = useCallback(
    (e) => {
      e.preventDefault();
      const automatedInstitutions = get(user, "data.institutions", []).filter(
        ({ type }) => type === "automated"
      );
      if (automatedInstitutions.length <= 1) {
        localStorage.setItem("trt-plan", "basic");
        localStorage.removeItem("trt-plan-frequency");
        sendFeedback({
          message: "Successfully changed plans",
          type: "success",
        });
        onFix();
      } else {
        setShowDowngrade(true);
      }
    },
    [sendFeedback, user, onFix]
  );
  const onDowngradeCancel = useCallback(() => setShowDowngrade(false), []);
  const onDowngradeConfirm = useCallback(() => {
    localStorage.removeItem("trt-plan-frequency");
    localStorage.setItem("trt-plan", "basic");
    onFix();
    sendFeedback({
      message: "Successfully changed plans",
      type: "success",
    });
  }, [onFix, sendFeedback]);
  return showDowngrade ? (
    <DowngradeSubscription
      onCancel={onDowngradeCancel}
      onConfirm={onDowngradeConfirm}
    />
  ) : (
    <Layer>
      <Box direction="row" align="center" justify="between" pad="medium">
        <Heading level={3} margin="none" responsive={false}>
          Payment Failure
        </Heading>
      </Box>
      <Paragraph
        color="text-weak"
        margin={{ horizontal: "medium", vertical: "none" }}
      >
        We had issues processing your payment this time around. Please provide a
        new payment method, or downgrade your plan in order to continue using
        The Retirement Tracker.
      </Paragraph>
      <Box pad="large">
        <PaymentChooser
          frequency={localStorage.getItem("trt-plan-frequency") || "monthly"}
          onPay={onFix}
          showDisclaimers={false}
        />
      </Box>
      <Box direction="row" alignSelf="center">
        <Text>
          or{" "}
          <Anchor href="#" label="Downgrade to Basic" onClick={onDowngrade} />
        </Text>
      </Box>
      <Paragraph
        alignSelf="start"
        color="text-xweak"
        margin={{ vertical: "large", horizontal: "medium" }}
      >
        <i>
          Your account will be automatically converted to the Basic plan if a
          new payment method is not provided within 7 days.
        </i>
      </Paragraph>
    </Layer>
  );
};
