import React, { useCallback, useContext, useState } from "react";
import { Anchor, Box, ResponsiveContext, Text } from "grommet";
import { Car, Home, Organization } from "grommet-icons";

import { AssetCard, Header, PageHeader } from "../components";
import { PageChangeContext, UserContext } from "..";

const institutionOptions = {
  automated: {
    mode: "automated",
    description: (
      <Text>
        Accounts will connect and update automatically through <b>Plaid</b>
      </Text>
    ),
    remaining: 1,
  },
  manual: {
    mode: "manual",
    description: <Text>Manually enter and update your account data</Text>,
    remaining: undefined,
  },
};

export const AddAssetsBody = () => {
  const onPageChange = useContext(PageChangeContext);
  const size = useContext(ResponsiveContext);
  const {
    plaid: { open, ready },
  } = useContext(UserContext);
  const [institutionOption, setInstitutionOption] = useState(
    institutionOptions.automated
  );
  const onInstitutionModeChange = useCallback(
    (newMode) => {
      setInstitutionOption(institutionOptions[newMode]);
    },
    [setInstitutionOption]
  );
  const onInstitutionChoose = useCallback(() => {
    if (institutionOption.mode === "automated") {
      if (ready) {
        open();
      } else {
        console.error("Could not initialize plaid");
        // TODO: show error
      }
    } else {
      onPageChange("addInstitution");
    }
  }, [open, ready, institutionOption, onPageChange]);
  return (
    <Box flex={false} direction="row" align="center" justify="center" wrap>
      <AssetCard
        icon={
          <Organization
            size={size === "large" ? "xlarge" : "large"}
            color="status-ok"
          />
        }
        {...institutionOption}
        type="Institution"
        onModeChange={onInstitutionModeChange}
        onChoose={onInstitutionChoose}
      />
      <AssetCard
        icon={
          <Home size={size === "large" ? "xlarge" : "large"} color="accent-2" />
        }
        type="Real Estate"
        mode="automated"
        description={
          <Text>
            Property will connect and update automatically through <b>Zillow</b>
          </Text>
        }
        remaining={2}
      />
      <AssetCard
        icon={
          <Car
            size={size === "large" ? "xlarge" : "large"}
            color="liabilities"
          />
        }
        type="Auto & Vessel"
        mode="automated"
        description={
          <Text>
            Property will connect and update automatically through{" "}
            <Anchor href="#">KBB</Anchor>
          </Text>
        }
        remaining={0}
      />
    </Box>
  );
};

export const AddAssets = () => {
  const onPageChange = useContext(PageChangeContext);

  const onPageClose = useCallback(() => onPageChange("dashboard"), [
    onPageChange,
  ]);

  return (
    <Box direction="row" fill>
      <Header />
      <Box flex>
        <PageHeader
          title="Add Assets & Liabilities"
          subTitle="Choose to automatically or manually
              connect your assets"
          onClose={onPageClose}
        />
        <Box
          flex
          overflow="auto"
          pad={{ vertical: "medium", horizontal: "large" }}
        >
          <AddAssetsBody />
        </Box>
      </Box>
    </Box>
  );
};
