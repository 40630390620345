import React, { useContext } from "react";
import { Avatar, Box, ResponsiveContext } from "grommet";
import md5 from "md5";
import { get } from "lodash";

import { InstitutionLogo } from "../";

export const UserActivityLogo = ({ user, holding, background = "card" }) => {
  const size = useContext(ResponsiveContext);
  const imgSize = size === "small" ? "40px" : "48px";
  return (
    <Box direction="row" align="center" flex={false}>
      <Box
        width={imgSize}
        height={imgSize}
        round="full"
        border={{
          side: "all",
          size: "2px",
          color: background,
        }}
        style={{
          overflow: "hidden",
        }}
      >
        <Avatar
          size={imgSize}
          src={`//s.gravatar.com/avatar/${md5(get(user, "id"))}?s=80`}
        />
      </Box>
      <InstitutionLogo
        institution={holding}
        size={imgSize}
        margin={{ left: "-12px" }}
        border={{ side: "all", size: "2px", color: background }}
      />
    </Box>
  );
};
