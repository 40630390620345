import React from "react";
import { Box, Button, Text } from "grommet";

export const HeaderButton = ({ icon, label, ...rest }) => (
  <Button {...rest}>
    <Box align="center" justify="center" gap="small">
      {icon}
      <Text size="small">{label}</Text>
    </Box>
  </Button>
);
