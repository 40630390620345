import React, { useCallback, useContext } from "react";

import { StepChangeContext } from "../../pages";

import { OnboardingHeading, OnboardingLayout, Restimate } from "../";

export const OnboardingRestimate = () => {
  const { onStepChange } = useContext(StepChangeContext);
  const onPreviousStep = useCallback(() => onStepChange("completeProfile"), [
    onStepChange,
  ]);
  const onNextStep = useCallback(() => onStepChange("choosePlan"), [
    onStepChange,
  ]);
  return (
    <OnboardingLayout
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      step="2 of 3 steps"
    >
      <OnboardingHeading
        heading="Restimate®"
        description="Set up your retirement goals and know how much you need to save this
          week, month, and years to come"
      />
      <Restimate />
    </OnboardingLayout>
  );
};
