import React from "react";
import { Box, Text } from "grommet";

export const MenuItemLabel = ({ icon, label }) => (
  <Box
    direction="row"
    align="center"
    gap="small"
    responsive={false}
    pad="xsmall"
    width="200px"
  >
    {icon}
    <Text>{label}</Text>
  </Box>
);
